import React, { useState, useEffect, useContext } from 'react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import * as firebase from 'firebase';
import i18n from '../i18n';
import { SESSION } from '../entity/Consultation';

import User from '../entity/User';
import Doctor from '../entity/Doctor';
import Consultation from '../entity/Consultation';
import FireChat from '../entity/FireChat';

import { IonPicker, IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonTextarea, IonItem, IonSelect, IonLoading, IonToast, IonAlert, IonCardSubtitle, IonText, IonActionSheet, IonThumbnail } from '@ionic/react';
import { closeOutline, chevronBackOutline, addCircleOutline, flaskOutline, sendOutline, trashOutline, copyOutline, bookOutline, clipboardOutline, imageOutline } from 'ionicons/icons';

import './Photos.css';

const moment = require('moment');

interface ComponentProps {
  session: SESSION
}

const Photos: React.FunctionComponent<ComponentProps> = ({session}) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [actionItem, setActionItem] = useState<any>();
  const [photos, setPhotos] = useState<any>([]);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showAddToChatAlert, setShowAddToChatAlert] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const consultationObj = new Consultation();
  const fireChatObj = new FireChat(consultationObj);
  
  useEffect(() => {
    // ComponentDidMount
    // Retrieve previous photos for customer
    updatePhotos();

     // Set focus to photo textbox    
    setTimeout(() => {
      const inp: any = document.querySelector('#txtPhoto textarea');
      if (inp) inp.focus();
    }, 500);

  }, []);
  
  useEffect(() => {
    // ComponentDidUnmount
    return () => {

    };
  }, []);
  
  
  const updatePhotos = () => {
    setIsBusy(true);
    
    userObj
      .getImages(session.consultation.patientId)
      .then((photos: any) => {
        setIsBusy(false);

        const photoArray: any = [];
        photos.forEach((p: any) => {
          const photo = p.data();
          photoArray.push({_id: p.id, photo});
        });
        setPhotos(photoArray);
      })
      .catch((err: any) => {
        setIsBusy(false);
        console.log(err.message);
      });
  };

  const scrollToTop = () => {
    // Scroll to bottom when new messages arrive
    const cPanel = document.getElementsByClassName("component-panel")[0];
    if (cPanel) {
      cPanel.scrollTop = 0;
    }
  }

  return (
    <>
      <IonLoading
        isOpen={isBusy}
        message={(i18n as any).t('common.please_wait')}
      />
      <IonToast
        isOpen={toastMessage.length > 0}
        onDidDismiss={() => setToastMessage('')}
        message={toastMessage}
        position="top"
        color="tertiary"
        duration={1300}
        translucent
        keyboardClose
      />
    
      {photos && photos.map((item: any) => (
        <IonCard key={item._id} onClick={() => setActionItem(item)} style={{cursor: 'pointer'}}>
          <IonCardHeader>
            <IonCardSubtitle>
              {`${'\n'}${moment
                .utc(
                  item.photo.timestamp.toDate()
                )
                .local()
                .format('DD/MM/YYYY HH:mm')} (${moment(item.photo.timestamp.toDate()).fromNow()})`}
            </IonCardSubtitle>
            <IonCardTitle>
              {item.photo.postedByName && (
                <IonText>
                  Posted by {item.photo.postedByName}
                </IonText>
              )}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <img src={item.photo.image} className="photos-thumbnail" />
            </IonItem>
          </IonCardContent>
        </IonCard>
      ))}
      {photos && photos.length === 0 && (
        <IonCard>
          <IonCardContent>
            <IonText color="primary">
              {(i18n as any).t('patient_images_partial.title_no_images_found')}
            </IonText>
          </IonCardContent>
        </IonCard>
      )}
      
      <IonActionSheet
        isOpen={actionItem !== undefined}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.view'),
          icon: imageOutline,
          handler: () => {
            window.open(actionItem.photo.image, '_blank');
          }
        }, {
          text: (i18n as any).t('patient_images_partial.option_post'),
          icon: bookOutline,
          handler: () => {
            setShowAddToChatAlert(true);
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            //Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonAlert
        isOpen={showAddToChatAlert}
        onDidDismiss={() => {
          setShowAddToChatAlert(false);
        }}
        header={(i18n as any).t('patient_images_partial.title_add_to_chat')}
        message={(i18n as any).t('patient_images_partial.body_add_to_chat')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              const msg = {
                text: '',
                image: actionItem.photo.image,
                user: {
                  _id: state.userId
                },
                dontTrack: true,
                timestamp: consultationObj.timestamp
              };
  
              setIsBusy(true);
              fireChatObj.append(session.consultation._id, msg).then(() => {
                setIsBusy(false);
              });
            }
          }
        ]}
      />
    </>
  );
};

export default Photos;
