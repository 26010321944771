import * as firebase from 'firebase';

class CMS {
  private db: any;
  private funcs: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
  }

  // Retrieve latest exchange rate
  getCMSAsset = (assetName: string) => {
    return new Promise((resolve, reject) => {
      // Retrieve rate
      const getAsset = this.funcs.httpsCallable('getCMSAsset');
      getAsset({assetName})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

}

export default CMS;
