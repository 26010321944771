import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonItem, IonToast, IonLabel, IonText, IonGrid, IonRow, IonCol, IonInput, IonIcon, IonLoading, IonThumbnail, IonToolbar, IonButtons, IonFooter, IonSegment, IonSegmentButton, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../State';
import { useHistory } from "react-router-dom";
import i18n from '../i18n';

import User from '../entity/User';
import Doctor from '../entity/Doctor';
import Consultation from '../entity/Consultation';
import { SESSION, EmptySession } from '../entity/Consultation';

import FireChat from '../entity/FireChat';

import Header from '../components/Header';
import Prescriptions from '../components/Prescriptions';
import LabTests from '../components/LabTests';
import Notes from '../components/Notes';
import Photos from '../components/Photos';

import './Chat.css';
import { sendOutline, imageOutline, thermometerOutline, clipboardOutline, flaskOutline, chatboxOutline, imagesOutline } from 'ionicons/icons';
import Resizer from '../Resizer';
import firebase from 'firebase';

const moment = require('moment');

const OT = require('@opentok/client');

const Chat: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [session, setSession] = useState<SESSION>(EmptySession);
  const [unsubscribeMessages, setUnsubscribeMessages] = useState<any>();
  const [messages, setMessages] = useState<any>();
  const [message, setMessage] = useState<string>('');
  const [mousePos, setMousePos] = useState<number>(0);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [showFinishConsultation, setShowFinishConsultation] = useState<boolean>(false);
  const [showPatientSignOut, setShowPatientSignOut] = useState<boolean>(false);
  const [showNoNote, setShowNoNote] = useState<boolean>(false);
  const [showUnsupportedMime, setShowUnsupportedMime] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [section, setSection] = useState<string>('chat');
  
  const [OTActiveSession, setOTActiveSession] = useState<any>();
  const [OTPub, setOTPub] = useState<any>();
  const [OTSub, setOTSub] = useState<any>([]);  

  const consultationObj = new Consultation();
  const fireChatObj = new FireChat(consultationObj);
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    const session = location.state.session;
    setSession(session);
    
    //Bug in Ion router, we must manually remove invisible class
    setTimeout(() => {
      const page = document.querySelector('div.ion-page');
      if (page) page.classList.remove('ion-page-invisible');
    }, 500);

    /*
      Start listening for messages
    */
    console.log('Subscribing to chat');
    const messagesRef = fireChatObj.on(session.consultation._id, (newMessages: any) => {
      if (newMessages && newMessages.length > 0) {
        
        // Since the "messages" variable is not available from here
        // use the update callback method of setting state!
        setMessages((prevMessages: any) => {
          if(!prevMessages) prevMessages = [];
          return prevMessages.concat(newMessages);
        });
      }
    });
       
    setUnsubscribeMessages({ref: messagesRef});
    
    // Start video session
    initializeOTSession('46260342', session.consultation);

  }, []);
  
  useEffect(() => {
    return () => {
      /*********************************************
      * ComponentDidUnmount
      *********************************************/
      try {
        //@ts-ignore
        if (unsubscribeMessages && unsubscribeMessages.ref) {
          console.log('Unsubscribing from chat listener');
          unsubscribeMessages.ref();
        }
      } catch( ex ) {
        //Probably due to a log out event
      }
      
      // Disconnect from Video Session
      if (OTActiveSession) {
        console.log('Unsubscribing from video conversation');
        OTActiveSession.off();
        OTActiveSession.disconnect();
        OTActiveSession.unpublish(OTPub, handleError);
        OTPub.destroy();
        OTActiveSession.unsubscribe(OTSub);
      }
    }
  }, [unsubscribeMessages, OTActiveSession]);
  
  
  useEffect(() => {
    if (messages && messages.length > 0) {
      if (section === 'chat') {
        // Scroll to bottom when new messages arrive
        setTimeout(scrollToBottom, 100);
      } else {
        const lastMessage = messages[messages.length-1];
        setToastMessage(lastMessage.text);
      }
    }
  }, [messages]);


  const scrollToTop = () => {
    // Scroll to bottom when new messages arrive
    const cPanel = document.getElementsByClassName("component-panel")[0];
    if (cPanel) {
      cPanel.scrollTop = 0;
    }
  }
  
  const scrollToBottom = () => {
    // Scroll to bottom when new messages arrive
    const cPanel = document.getElementsByClassName("component-panel")[0];
    if (cPanel) {
      cPanel.scrollTop = cPanel.scrollHeight;
    }
  }
  
  const getPageHeight = () => {
    var body = document.body, html = document.documentElement;
    const pageHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                                 html.clientHeight, html.scrollHeight,
                                 html.offsetHeight );
    return pageHeight;
  }
  
  const clickImageUpload = () => {
    (document as any).getElementById('photoUpload').click();
  }

  const uploadImage = (file: Blob) => {
    if (file) {
      console.log('Uploading image');
      const reader = new FileReader();
  
      reader.onloadend = function () {
        let baseString = reader.result;

        const baseStringLower = (baseString as string).toLowerCase();
        if (
          baseStringLower.indexOf('image/png') > -1 ||
          baseStringLower.indexOf('image/apng') > -1 || 
          baseStringLower.indexOf('image/bmp') > -1 || 
          baseStringLower.indexOf('image/svg+xml') > -1 || 
          baseStringLower.indexOf('image/jpeg') > -1
        ) {
          //baseString = Data url
          var image = new Image();
          image.src = baseString as string;
          image.onload = function () {
            const resizedDataUrl: string = Resizer.resizeAndRotateImage(image, 1000, 1000, 'jpeg', 80, 0);
        
            //  Create a reference in firebase storage for the file
            const imageRef = firebase
              .storage()
              .ref('chat/' + session.consultation._id)
              .child(`chat_${state.userId}_${(new Date()).getTime()}`);
      
            setIsBusy(true);
      
            //  From here we can get the download url of the image
            //  to store a reference to in our database
            imageRef
              .putString(resizedDataUrl, 'data_url')
              .then(() => imageRef.getDownloadURL())
              .then((url: string) => {
                setIsBusy(false);
                
                // Update messages with link to image
                const msg = {
                  text: message, 
                  image: url, 
                  //type: undefined,
                  user: { _id: state.userId }
                } 
                
                const msgs = [];
                msgs.push(msg);
                
                fireChatObj.send(session.consultation._id, msgs);
                
              })
              .catch((error: any) => {
                console.log(error);
              });
          }
        } else {
          // Mime type unsupported
          setShowUnsupportedMime(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };  
  
  const onSendMessage = () => {
    if (message && message.length > 0) {
      const msg = {
        text: message, 
        //image: undefined, 
        //type: undefined,
        user: { _id: state.userId }
      } 
      
      const msgs = [];
      msgs.push(msg);
      
      fireChatObj.send(session.consultation._id, msgs);
      
      setMessage('');
    }
  };
  
  const handleError = (error: any) => {
    if (error && error.message) {
      setToastMessage(error.message);
    }
  }
  
  const initializeOTSession = (OTApiKey: string, consultation: any) => {
    
    const OTSession = OT.initSession(OTApiKey, consultation.OTSessionId);
    const token = (consultation.patientId === state.userId) ? 
                  consultation.OTPatientToken : 
                  consultation.OTDoctorToken;

    // Subscribe to a newly created stream
    OTSession.on('streamCreated', (event: any) => {
      const subscriber = OTSession.subscribe(event.stream, 'subscriber', {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      }, handleError);
      
      // May be multiple participants in the future
      setOTSub(OTSub.push(subscriber));
    });
  
    // Create a publisher
    const publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '100%',
      height: '100%'
    }, handleError);
    
    setOTPub(publisher);
    
    // Store reference to session
    setOTActiveSession(OTSession);
  
    // Connect to the session
    OTSession.connect(token, (error: any) => {
      // If the connection is successful, initialize a publisher and publish to the session
      if (error) {
        handleError(error);
      } else {
        OTSession.publish(publisher, handleError);
      }
    });
  }
  
  const onSignOut = () => {
    return new Promise((resolve, reject) => {
      // Disconnect from Video Session
      if (OTActiveSession) {
        OTActiveSession.disconnect();
      }
      resolve(true);
    });
  }
  
  const onPatientSignOut = () => {
    return new Promise((resolve, reject) => {
      setShowPatientSignOut(true);
    });
  }
  
  const onFinishConsultation = async () => {
    if (state.isDoctor) {
      // If doctor, make sure they have provided a consultation note
      const doctorNotes: any = await consultationObj.getDoctorNotes(session.consultation);
      
      if (doctorNotes.length == 0) {
        setShowNoNote(true);
      } else {
        setShowFinishConsultation(true);
      }
      
    } else {
      // If patient, just show finish consultation dialog
      setShowFinishConsultation(true);
    }
  }
  
  return (
    <IonPage 
      onMouseUp={(e: any) => {
        // Chat Panel Resizing via handle
        setMouseDown(false);
      }}
      
      onMouseMove={(e: any) => {
        // Chat Panel Resizing via handle
        const cPanel = document.getElementsByClassName("component-panel")[0];
        
        if (mouseDown) {
          const dy = mousePos - e.clientY;
          setMousePos(e.clientY);

          // Determine what the new height will be
          let newHeight = (cPanel as any).clientHeight + dy;

          // Ensure that the new height is within allowed boundaries
          // i.e. it must allow video to be seen, and it must not fully 
          if (newHeight > 200 && newHeight < getPageHeight() - 250) {
            //Resize video panel
            (cPanel as any).style.height = newHeight + 'px';
            
            //Scroll to bottom of messages
            scrollToBottom();
          }
        }
      }}>
      
      {state.userId === session.consultation.patientId && (
        <Header
          showBack={false} 
          onSignOut={onPatientSignOut}
        />
      )}
      {state.userId === session.consultation.doctorId && (
        <Header onBack={() => {
          // Disconnect from video feed
          if (OTActiveSession) {
            OTActiveSession.disconnect();
          }
          history.push('/doctor/home');
        }}
        onSignOut={onSignOut}/>
      )}
      
      <IonContent>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        <IonToast
          isOpen={toastMessage.length > 0}
          onDidDismiss={() => setToastMessage('')}
          message={toastMessage}
          position="top"
          color="tertiary"
          duration={1300}
          translucent
          keyboardClose
        />        
        
        <IonGrid className="video-grid">
          <IonRow className="video-panel">
            <IonCol>
              <div id="videos">
                <div id="publisher"></div>
                <div id="subscriber"></div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="hangup-panel">
          <img
            onClick={onFinishConsultation}
            src={require('../assets/hangup.png')}
            className="hangup-icon" />
        </div>
      </IonContent>
      
      
      <IonFooter>
        <IonGrid className="conversation-panel">
          <IonRow>
            <IonCol
              className="handle-panel"
              onMouseDown={(e: any) => {
                const vPanel = document.getElementsByClassName("video-panel")[0];
                setMousePos(e.clientY);
                setMouseDown(true);
              }}
              >
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
      <IonFooter className="component-panel">
        {section === 'chat' && (
          <IonGrid className="conversation-panel">
            <IonRow className="chat-panel">
              <IonCol>
                <div className="filler-panel"></div>
                <div className="messages-panel">
                  {messages && messages.map((msg:any) => (
                    <IonItem key={msg._id}>
                      {msg.user._id === session.consultation.patientId && !msg.image && (
                        <div className="patient-bubble">
                          <IonText>{msg.text}</IonText><br/>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                      {msg.user._id === session.consultation.patientId && msg.image && (
                        <div className="patient-bubble">
                          <IonThumbnail className="chat-thumbnail">
                            <img src={msg.image} className="chat-thumbnail-img" onClick={() => {
                              window.open(msg.image, '_blank');
                            }} />
                          </IonThumbnail>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                      
                      {msg.user._id === session.consultation.doctorId && !msg.image && (
                        <div className="doctor-bubble">
                          <IonText>{msg.text}</IonText><br/>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                      {msg.user._id === session.consultation.doctorId && msg.image && (
                        <div className="doctor-bubble">
                          <IonThumbnail className="chat-thumbnail">
                            <img src={msg.image} className="chat-thumbnail-img" onClick={() => {
                              window.open(msg.image, '_blank');
                            }} />
                          </IonThumbnail>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                    </IonItem>
                  ))}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {section === 'prescriptions' && state.isDoctor && (
          <Prescriptions session={session} />
        )}
        {section === 'labtests' && state.isDoctor && (
          <LabTests session={session} />
        )}
        {section === 'notes' && state.isDoctor && (
          <Notes session={session} />
        )}
        {section === 'photos' && state.isDoctor && (
          <Photos session={session} />
        )}
      </IonFooter>
      
      {section === 'chat' && (
        <IonFooter>
          <IonGrid className="input-panel">
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonInput
                    autofocus
                    value={message}
                    placeholder={(i18n as any).t('chat.enter_message')}
                    clearInput
                    onIonChange={e => setMessage(e.detail.value!)}
                    onKeyUp={(e) => {
                      if (e.which === 13) {
                        onSendMessage();
                      }
                    }}
                  >
                  </IonInput>
                  
                  <input
                    id="photoUpload"
                    type="file"
                    style={{display: 'none'}}
                    onChange={(e: any) => uploadImage(e.target.files[0])} />
                  
                  <IonIcon
                    slot="end"
                    size="large"
                    color="primary"
                    icon={imageOutline}
                    style={{cursor: 'pointer'}}
                    onClick={clickImageUpload} />
                  <IonIcon
                    slot="end"
                    size="large"
                    color="primary"
                    icon={sendOutline}
                    style={{cursor: 'pointer'}}
                    onClick={onSendMessage} />
  
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      )}
      
      {state.isDoctor && (
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <IonSegment scrollable value={section} onIonChange={(e) => {
              const sect: string = e.detail.value as string;
              setSection(sect);
              
              if (sect === 'chat') {
                setTimeout(() => {
                  scrollToBottom();  
                }, 200);
              }
            }}>
              <IonSegmentButton value="chat">
                <IonIcon icon={chatboxOutline} />
                <IonLabel className="hidden-sm-down">
                  {(i18n as any).t('chat.tab_chat')}
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="prescriptions">
                <IonIcon icon={flaskOutline} />
                <IonLabel className="hidden-sm-down">
                  {(i18n as any).t('chat.tab_prescriptions')}
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="labtests">
                <IonIcon icon={thermometerOutline} />
                <IonLabel className="hidden-sm-down">
                  {(i18n as any).t('chat.tab_lab_tests')}
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="notes">
                <IonIcon icon={clipboardOutline} />
                <IonLabel className="hidden-sm-down">
                  {(i18n as any).t('chat.tab_notes')}
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="photos">
                <IonIcon icon={imagesOutline} />
                <IonLabel className="hidden-sm-down">
                  {(i18n as any).t('chat.tab_photos')}  
                </IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonFooter>
      )}
      
      <IonAlert
        isOpen={showFinishConsultation}
        onDidDismiss={() => {
          setShowFinishConsultation(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('doctor_options.ask_finish_consultation')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              setIsBusy(true);
              consultationObj
                .closeConsultation(state.userId, session.consultation)
                .then(() => {
                  setIsBusy(false);
                  
                  if (state.isDoctor) {
                    history.push('/doctor/home');
                  } else {
                    history.push('/patient/home');
                  }
                })
                .catch((err) => {
                  setIsBusy(false);
                  console.log(err.message);
                });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showPatientSignOut}
        onDidDismiss={() => {
          setShowPatientSignOut(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('doctor_options.ask_finish_consultation')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Perform sign out
              firebase.auth().signOut();
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              setIsBusy(true);
              consultationObj
                .closeConsultation(state.userId, session.consultation)
                .then(() => {
                  setIsBusy(false);
                  
                  //if (OTActiveSession) {
                    //OTActiveSession.disconnect();
                  //}
                  
                  //Perform sign out
                  firebase.auth().signOut();
                })
                .catch((err) => {
                  setIsBusy(false);
                  console.log(err.message);
                });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showNoNote}
        onDidDismiss={() => {
          setShowNoNote(false);
          setSection('notes');
        }}
        header={(i18n as any).t('doctor_options.notes_required')}
        message={(i18n as any).t('doctor_options.please_enter_notes')}
        buttons={[
          {
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowNoNote(false);
              setSection('notes');
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showUnsupportedMime}
        onDidDismiss={() => {
          setShowUnsupportedMime(false);
        }}
        header={(i18n as any).t('chat.not_supported')}
        message={(i18n as any).t('chat.file_type_not_supported')}
        buttons={[
          {
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowUnsupportedMime(false);
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default Chat;
