import React, { createContext, useReducer } from "react";

const initialState = {
  loggedIn: false,
  isAdmin: false,
  isDoctor: false,
  userId: '',
  userName: '',
  doctorProfile: null
  //sessions: []
}

let AppContext = createContext();

let reducer = (state, action) => {
  switch(action.type) {
    case "setLoggedIn": {
      return { ...state, loggedIn: action.value };
    }
    case "setIsAdmin": {
      return { ...state, isAdmin: action.value };
    }
    case "setIsDoctor": {
      return { ...state, isDoctor: action.value };
    }
    case "setUserId": {
      return { ...state, userId: action.value };
    }
    case "setUserName": {
      return { ...state, userName: action.value }
    }
    case "setDoctorProfile": {
      return { ...state, doctorProfile: action.value }
    }
    /*
    // Active consultations are those that are currently
    // being used by the logged in user.
    // Structure of passed in value is:
    // { consultation: {}, doctorProfile: {}, patientProfile: {} }
    case "addSession": {
      const session = action.value;
      
      if (session.consultation &&
          session.doctorProfile &&
          session.patientProfile) {
            
        let workingSessions = state.sessions;
        
        //Check that consultation isn't already stored
        let foundIdx = -1;
        workingSessions.forEach((s, i) => {
          if (s.sessionid === session.sessionid) {
            foundIdx = i;
          }
        });
  
        // If found, remove it before adding it again
        // just in case anything has changed
        if (foundIdx > -1) {
          workingSessions.splice(foundIdx, 1);
        }
  
        workingSessions.push(session);
        return { ...state, sessions: workingSessions }
      }
    }
    case "removeSession": {
      let workingSessions = state.sessions;
      
      const sessionid = action.value;
      
      let foundIdx = -1;
      workingSessions.forEach((s, i) => {
        if (s.sessionid === sessionid) {
          foundIdx = i;
        }
      });

      if (foundIdx > -1) {
        workingSessions.splice(foundIdx, 1);
        return { ...state, sessions: workingSessions }
      }
    }
    */
  }
  return state;
};

function AppContextProvider(props) {

  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };