import React, { useContext, useState, useEffect } from 'react';
import * as firebase from 'firebase';
import imageFileResizer from '../Resizer';
import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonInput, IonToast, IonDatetime, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonAvatar, IonLoading } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../State';
import { useHistory } from "react-router-dom";
import i18n from '../i18n';
import { isNull, isNullThenElse, isNullOrEmpty } from '../Utils';

import Header from '../components/Header';
import User from '../entity/User';
import Doctor from '../entity/Doctor';
import ListData from '../entity/ListData';

import { fingerPrintOutline, happyOutline, calendarOutline, maleOutline, femaleOutline, globeOutline, languageOutline, walletOutline, checkmarkOutline, checkmarkCircle } from 'ionicons/icons';

import './Profile.css';
import Resizer from '../Resizer';

const moment = require('moment');

const Profile: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  const [nickName, setNickName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [MSISDN, setMSISDN] = useState<string>('+256');
  const [country, setCountry] = useState<string>('');
  const [countryName, setCountryName] = useState<string>('');
  const [language, setLanguage] = useState<string>('EN');
  const [languageName, setLanguageName] = useState<string>('');
  const [dob, setDOB] = useState<string>(moment("1900-01-01").toDate().toISOString());
  const [gender, setGender] = useState<string>('M');
  const [avatar, setAvatar] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [countryArray, setCountryArray] = useState([]);
  const [languageArray, setLanguageArray] = useState([]);
  
  const [nickNameUnique, setNickNameUnique] = useState<any>(null);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const listDataObj = new ListData();
  const userObj = new User();
  const doctorObj = new Doctor();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/

    //  Retrieve ledger items
    setIsBusy(true);

    //  Populate countries
    listDataObj.getCountries().then((data: any) => {
      setCountries(data.docs);
      
      let cArray: any = [];
      data.docs.forEach((c: any) => {
        const cn = c.data();
  
        cArray.push({key: c.id, label: cn.name});
      });
      setCountryArray(cArray);
    });

    //  Populate languages
    listDataObj.getLanguages().then((data: any) => {
      setLanguages(data.docs);
      
      let lArray: any = [];
      data.docs.forEach((l: any) => {
        const lang = l.data();
  
        lArray.push({key: l.id, label: lang.name});
      });
      setLanguageArray(lArray);
    });
    
    //  Retrieve current profile info
    userObj
      .getCurrentProfile()
      .then((profile: any) => {
        if (profile) {
          const prof = profile;
          if (profile.gender === '') prof.gender = 'M';
          if (profile.dob) prof.dob = prof.dob.toDate();
          
          setNickName( isNull(prof.nickName,'') );
          setFirstName( isNull(prof.firstName,'') );
          setLastName( isNull(prof.lastName,'') );
          setMSISDN( isNullOrEmpty(prof.MSISDN,'+256') );
          setCountry( isNull(prof.country,'') );
          setCountryName( isNull(prof.countryName,'') );
          setLanguage( isNull(prof.language,'') );
          setLanguageName( isNull(prof.languageName,'') );
          setDOB( isNullThenElse(prof.dob, moment().toDate().toISOString(), moment(prof.dob).toDate().toISOString()) );
          setGender( isNull(prof.gender,'M') );
          setAvatar( isNull(prof.avatar,'') );
        }
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        console.log(err.message);
      });
  
  },[]);
  
  const handleUpdate = () => {
    let modMSISDN = MSISDN;
    if (MSISDN.length <= 4) {
      modMSISDN = '';
    }

    if (nickName.trim().length > 0) {
      if (firstName.trim().length > 0 && lastName.trim().length > 0) {
        if (country !== '') {
          if (language !== '') {
            setIsBusy(true);

            userObj.isNicknameUnique(nickName).then((isUnique) => {
              if (isUnique) {
                userObj
                  .verifyMSISDN(modMSISDN)
                  .then(async (mobileInfo: any) => {
                    // console.warn(mobileInfo);

                    if (mobileInfo.status && mobileInfo.status === 404) {
                      setErrorMessage((i18n as any).t('profile.invalid_msisdn_supplied'));
                      setIsBusy(false);
                    } else {
                      //  Reset error message
                      setErrorMessage('');

                      const user: any = await userObj.getAuthenticatedUser();
                      
                      userObj
                        .setCurrentProfile({
                          nickName: nickName.trim(),
                          nickName_lower: nickName.trim().toLowerCase(),
                          firstName: firstName.trim(),
                          lastName: lastName.trim(),
                          MSISDN: modMSISDN.trim(),
                          country,
                          countryName,
                          language,
                          languageName,
                          gender,
                          dob: moment(dob).toDate(),
                          avatar,
                          ...mobileInfo,
                          keywords: [
                            nickName.trim().toUpperCase(),
                            firstName.trim().toUpperCase(),
                            lastName.trim().toUpperCase(),
                            user.email.toUpperCase(),
                            modMSISDN.trim(),
                            modMSISDN.trim().replace('+', ''),
                            country.toUpperCase(),
                            countryName.toUpperCase(),
                            language.toUpperCase(),
                            languageName.toUpperCase(),
                            gender.toUpperCase(),
                            moment(dob)
                              .local()
                              .format('YYYY-MM-DD')
                          ]
                        })
                        .then(() => {
                          setIsBusy(false);
                          
                          // Store preferred language
                          (window as any).localStorage.setItem("pref_language", language.toLowerCase());
                          
                          if (state.isDoctor) {
                           history.push('/doctor/home'); 
                          } else {
                            history.push('/patient/home');
                          }
                        })
                        .catch((error: any) => {
                          setIsBusy(false);
                          setErrorMessage(error.message);
                        });
                    }
                  })
                  .catch((err) => {
                    setIsBusy(false);
                    setErrorMessage((i18n as any).t('profile.invalid_msisdn_supplied'));
                  });
              } else {
                setIsBusy(false);
                setErrorMessage((i18n as any).t('profile.nickname_already_taken'));
              }
            });
          } else {
            setErrorMessage((i18n as any).t('profile.please_select_spoken_language'));
          }
        } else {
          setErrorMessage((i18n as any).t('profile.please_select_country'));
        }
      } else {
        setErrorMessage((i18n as any).t('profile.please_enter_name'));
      }
    } else {
      setErrorMessage((i18n as any).t('profile.please_enter_nickname'));
    }
  };
  
  const checkNickName = (nickName: string) => {
    if (nickName.length > 0) {
      userObj.isNicknameUnique(nickName).then((isUnique) => {
        setNickNameUnique(isUnique);
      });
    }
  }
  
  // Retrieve country based on ISO2 code supplied
  const getCountry = (iso2: string) => {
    let foundCountry = {};
    if (countries) {
      countries.forEach((c: any) => {
        if (c.id === iso2) {
          foundCountry = c.data();
        }
      });
    }
    return foundCountry;
  };
  
  // Retrieve language based on code supplied
  const getLanguage = (langCode: string) => {
    let foundLanguage = {};
    if (languages) {
      languages.forEach((l: any) => {
        if (l.id === langCode) {
          foundLanguage = l.data();
        }
      });
    }
    return foundLanguage;
  };
  
  // Returns whether currently selected country is mobile money enabled
  const isMobileMoneyCountrySelected = () => {
    const selectedCountry = getCountry(country);
    return (selectedCountry as any).mobileMoneyAvailable;
  };
  
  const clickImageUpload = () => {
    (document as any).getElementById('photoUpload').click();
  }
  
  const uploadImage = (file: Blob) => {
    if (file) {
      console.log('Uploading profile picture');
      const reader = new FileReader();
  
      reader.onloadend = function () {
        let baseString = reader.result;
        
        //baseString = Data url
        var image = new Image();
        image.src = baseString as string;
        image.onload = function () {
          const resizedDataUrl: string = Resizer.resizeAndRotateImage(image, 400, 400, 'jpeg', 100, 0);
      
          //  Create a reference in firebase storage for the file
          const imageRef = firebase
            .storage()
            .ref('profile')
            .child(`profile_${state.userId}_${(new Date()).getTime()}`);
    
          setIsBusy(true);
    
          //  From here we can get the download url of the image
          //  to store a reference to in our database
          imageRef
            .putString(resizedDataUrl, 'data_url')
            .then(() => imageRef.getDownloadURL())
            .then((url: string) => {
              setIsBusy(false);
              setAvatar(url);
              console.log(url);
              // Perform manual save (just in-case user updates photo, but forgets to save)
              userObj.setCurrentProfile({avatar: url});
            })
            .catch((error: any) => {
              console.log(error);
            });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  
  const allowDoctorNotifications = () => {
    if ( state.isDoctor &&
         process.env.NODE_ENV === 'production' &&
         !(window as any)._isSafari) {
      
      // Ask for doctor to accept notifications from GoGP+
      (window as any).fcm.requestPermission()	
        .then(async function() {	
          const token = await (window as any).fcm.getToken();	
          
          //Send to server to subscribe to doctors topic
          setIsBusy(true);
          doctorObj.registerForDoctorTopic(token).then((result: any) => {
            setIsBusy(false);
            
            if (result && result.data && result.data.status === 'OK') {
              setErrorMessage((i18n as any).t('common.success'));
            } else {
              setErrorMessage((i18n as any).t('common.failure'));
            }
          });
        })	
        .catch(function(err: any) {	
          setIsBusy(false);
          console.log("Unable to get permission to notify.", err);	
        });	
    }
  };
  
  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">
                <IonToast
                  isOpen={errorMessage.length > 0}
                  onDidDismiss={() => setErrorMessage('')}
                  message={errorMessage}
                  position="top"
                  color="tertiary"
                  duration={1300}
                  translucent
                  keyboardClose
                />
                <IonLoading
                  isOpen={isBusy}
                  message={(i18n as any).t('common.please_wait')}
                />
                
                {state.isDoctor && !(window as any)._isSafari && (
                  <IonCard>
                    <IonCardContent>
                      <IonItem>
                        <IonButton onClick={allowDoctorNotifications} size="default" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                          {(i18n as any).t('profile.allow_doctor_notifications')}
                        </IonButton>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                )}

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{(i18n as any).t('profile.title_my_profile')}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={fingerPrintOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.nick_name')}</IonLabel>
                        <IonInput
                          value={nickName}
                          placeholder={(i18n as any).t('profile.please_supply')}
                          clearInput
                          onIonChange={e => {
                            setNickName(e.detail.value!);
                          }}
                          onIonBlur={e => {
                            checkNickName(nickName);
                          }}>
                        </IonInput>
                        {nickNameUnique && (
                          <IonIcon slot="end" icon={checkmarkCircle} style={{color: '#16E424'}} />
                        )}
                      </IonItem>
                      
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={happyOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.first_name')}</IonLabel>
                        <IonInput
                          value={firstName}
                          placeholder={(i18n as any).t('profile.please_supply')}
                          clearInput
                          onIonChange={e => setFirstName(e.detail.value!)}>
                        </IonInput>
                      </IonItem>
                      
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={happyOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.last_name')}</IonLabel>
                        <IonInput
                          value={lastName}
                          placeholder={(i18n as any).t('profile.please_supply')}
                          clearInput
                          onIonChange={e => setLastName(e.detail.value!)}>
                        </IonInput>
                      </IonItem>
                      
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={calendarOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.my_date_of_birth')}</IonLabel>
                        <IonDatetime
                          displayFormat="MM / DD / YYYY"
                          placeholder={(i18n as any).t('profile.select_date')}
                          value={dob}
                          onIonChange={e => setDOB(e.detail.value!)}>
                        </IonDatetime>
                      </IonItem>
                      
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={globeOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.my_country')}</IonLabel>
                        
                        {countries && countries.length > 0 && (
                          <IonSelect
                            value={country}
                            okText={(i18n as any).t('common.OK')}
                            cancelText={(i18n as any).t('common.cancel')}
                            placeholder={(i18n as any).t('profile.please_select_country')}
                            onIonChange={e => {
                              const cntry = e.detail.value;
                              
                              if (cntry) {
                                setCountry(cntry);
                                
                                // Set country name
                                const c = getCountry(cntry);
                                setCountryName(isNull((c as any).name, ''));
                              } else {
                                setCountryName('');
                              }
                            }}>
                            
                            {countries.map((item:any) => (
                              <IonSelectOption key={item.id} value={item.id}>{item.data().name}</IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      </IonItem>
                      
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={languageOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.my_primary_language')}</IonLabel>
                        {languages && languages.length > 0 && (
                          <IonSelect
                            value={language}
                            okText={(i18n as any).t('common.OK')}
                            cancelText={(i18n as any).t('common.cancel')}
                            placeholder={(i18n as any).t('profile.please_select_spoken_language')}
                            onIonChange={e => {
                              const lang = e.detail.value;

                              if (lang) {
                                setLanguage(lang);
                                
                                // Set language name
                                const l = getLanguage(lang);
                                setLanguageName(isNull((l as any).name, ''));
                              } else {
                                setLanguageName('');
                              }
                            }}>
                            
                            {languages.map((item:any) => (
                              <IonSelectOption key={item.id} value={item.id}>{item.data().name}</IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
                
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{(i18n as any).t('profile.my_gender')}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonItem>
                      <IonSegment value={gender} onIonChange={e => setGender(e.detail.value as string)}>
                        <IonSegmentButton value="M">
                          <IonIcon icon={maleOutline} />
                          <IonLabel>{(i18n as any).t('common.male')}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="F">
                          <IonIcon icon={femaleOutline} />
                          <IonLabel>{(i18n as any).t('common.female')}</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
                
                {isMobileMoneyCountrySelected() && (
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>{(i18n as any).t('profile.title_mobile_money_wallet')}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={walletOutline} />
                        <IonLabel position="floating">{(i18n as any).t('profile.title_my_mobile_number')}</IonLabel>
                        
                        <IonInput
                          type="tel"
                          value={MSISDN}
                          placeholder={(i18n as any).t('profile.telephone_number')}
                          clearInput
                          onIonChange={e => setMSISDN(e.detail.value!)}>
                        </IonInput>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                )}
                
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{(i18n as any).t('profile.title_my_photo')}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonItem className="panel-avatar">
                            <div>
                              {avatar.length > 0 && (
                                <IonAvatar className="profile-avatar" onClick={clickImageUpload}>
                                  <img src={avatar} />
                                </IonAvatar>
                              )}
                              {avatar === '' && gender === 'M' && (
                                <IonAvatar className="profile-avatar" onClick={clickImageUpload}>
                                  <img src={require('../assets/avatar_M.png')} />
                                </IonAvatar>
                              )}
                              {avatar === '' && gender === 'F' && (
                                <IonAvatar className="profile-avatar" onClick={clickImageUpload}>
                                  <img src={require('../assets/avatar_F.png')} />
                                </IonAvatar>
                              )}

                              <div style={{marginTop: 15, textAlign: 'center'}}>
                                <input
                                  id="photoUpload"
                                  type="file"
                                  style={{display: 'none'}}
                                  onChange={(e: any) => uploadImage(e.target.files[0])} />
                                  
                                {avatar.length > 0 && (
                                  <div style={{marginBottom: 10}}>
                                    <a href="#" onClick={(e) => {
                                      e.preventDefault();
                                      setAvatar('');
                                    }}>
                                      {(i18n as any).t('profile.button_remove_photo')}
                                    </a>
                                  </div>
                                )}
                                
                                <IonButton onClick={clickImageUpload}>
                                  {(i18n as any).t('profile.button_take_photo')}
                                </IonButton>
                              </div>
                            </div>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
                
                <IonButton onClick={handleUpdate} size="large" expand="block">
                  {(i18n as any).t('common.update')}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
