import 'core-js/es/map';
import 'core-js/es/set';

import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';

// Register our caching / push messaging service worker
if ("serviceWorker" in navigator) {
    // Ensure this is a production build (uat / prod sites)
    if (process.env.NODE_ENV === 'production') {
      
      // Use combined worker for most browsers (caching + push messaging)
      let swName = './combinedWorker.js';
      if ((window as any)._isSafari) {
        // Safari does not support the Firebase messaging api
        // So just use the caching worker
        swName = './service-worker.js';
      }
      
      navigator.serviceWorker
        .register(swName)
        .then(function(registration) {
          console.log("GoGP+ Service Worker Registration Successful, scope is:", registration.scope);
          
          // Content updated events
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }

            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the updated precached content has been fetched,
                  // but the previous service worker will still serve the older
                  // content until all client tabs are closed.
                  console.log(
                    'New content is available and will be used when all ' +
                      'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                  );

                } else {
                  // At this point, everything has been precached.
                  // It's the perfect time to display a
                  // "Content is cached for offline use." message.
                  console.log('Content is cached for offline use.');
                }
              }
            };
          };

        })
        .catch(function(err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
