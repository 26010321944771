import React, { useContext, useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonText, IonCol, IonRow, IonGrid, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonLoading, IonToast, IonButtons, IonInput, IonDatetime, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';

import Header from '../../components/Header';
import User from '../../entity/User';
import Ledger from '../../entity/Ledger';
import Settings from '../../entity/Settings';

import { phonePortraitOutline, cardOutline, trashOutline, calendarOutline, bodyOutline, eyeOutline } from 'ionicons/icons';

import './DepositTanzania.css';

const moment = require('moment');

const DepositTanzania: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [patientDoctor, setPatientDoctor] = useState<string>('patient');
  
  const [depositAmounts, setDepositAmounts] = useState<any>();
  const [exchangeRate, setExchangeRate] = useState<any>();
  const [MSISDN, setMSISDN] = useState<string>('');
  const [carrierName, setCarrierName] = useState<string>('');
  const [section, setSection] = useState<string>('');
  
  const [credits, setCredits] = useState<number>(0);
  const [feeInfo, setFeeInfo] = useState<any>();
  
  const [showConfirmOnPhone, setShowConfirmOnPhone] = useState<boolean>(false);
  const [showDepositSuccessful, setShowDepositSuccessful] = useState<boolean>(false);
  const [showProblemEncountered, setShowProblemEncountered] = useState<boolean>(false);
  
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const ledgerObj = new Ledger();
  const settingsObj = new Settings();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setPatientDoctor(state.isDoctor ? 'doctor' : 'patient');

    setMSISDN(location.state.MSISDN);
    setCarrierName(location.state.carrierName);

    //  Retrieve available deposit amounts
    settingsObj.getSetting('deposit_amounts').then((amounts: any) => {
      setDepositAmounts(amounts.split('|'));
    });

    ledgerObj.getExchangeRate('GGP', 'TZS').then((pair: any) => {
      setExchangeRate(pair.rate);
    });
      
  },[location.state && location.state.MSISDN]);
  
  const depositFunds = (depositAmount: number) => {

    if (!isBusy) {
      setIsBusy(true);
      setCredits(depositAmount);

      const carrier = 'AIRTELTZ';
      const env = ((window as any).firebaseConfig.isLive) ? 'prod' : 'uat';
      
      let url = `https://us-central1-gogp-9aeb3.cloudfunctions.net/depositViaMM?userId=${state.userId}&` + 
                `credits=${depositAmount}&carrier=${carrier}&env=${env}`;
      
      if (!(window as any).firebaseConfig.isLive) {
        url = `https://us-central1-gogp-uat.cloudfunctions.net/depositViaMM?userId=${state.userId}&` + 
              `credits=${depositAmount}&carrier=${carrier}&env=${env}`;
      }

      fetch(
        url,
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          //timeout: (30 * 1000) as number //  30 Second Timeout (Yo times out before this) (due to 60sec limit on cloud functions - can be set 'per deploy' in cloud console though.)
        }
      )
        .then((result: any) => {
          return result.json();
        })
        .then((resp: any) => {
          setIsBusy(false);

          if (resp.status && resp.status === 'PENDING') {
            setShowConfirmOnPhone(true);
            
          } else if (resp.status && resp.status === 'OK') {
            setShowDepositSuccessful(true);
            
          } else {
            setShowProblemEncountered(true);
            
          }
        })
        .catch((err) => {
          console.log(err);
          setIsBusy(false);
          setShowProblemEncountered(true);
        });
    }
  };
  
  const buttonTitle = (amount: number) => {
    let title = '';

    if (isBusy && credits === amount) {
      title = (i18n as any).t('account_deposit_tanzania.confirm_on_phone');
    } else if (amount == 1) {
      title = (i18n as any).t('account_deposit_tanzania.add_x_credit', {
        credit: ledgerObj.formatNumber(amount)
      });
    } else {
      title = (i18n as any).t('account_deposit_tanzania.add_x_credits', {
        credits: ledgerObj.formatNumber(amount)
      });
    }

    return title;
  };

  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLoading
                isOpen={isBusy}
                message={(i18n as any).t('common.please_wait')}
              />
              <IonToast
                isOpen={toastMessage.length > 0}
                onDidDismiss={() => setToastMessage('')}
                message={toastMessage}
                position="top"
                color="tertiary"
                duration={1300}
                translucent
                keyboardClose
              />   
              
              <div style={{textAlign: 'center', marginBottom: 20}}>
                <h1>{(i18n as any).t('account_deposit_tanzania.title_account_topup')}</h1>
              </div>
                
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    {(i18n as any).t('account_deposit_tanzania.title_how_topping_up_works')}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    {(i18n as any).t(
                      'account_deposit_tanzania.body_how_topping_up_works_para1'
                    )}
                  </IonText>
                  <IonText>
                    {(i18n as any).t(
                      'account_deposit_tanzania.body_how_topping_up_works_para2'
                    )}
                  </IonText>
                  <IonText>
                    {(i18n as any).t(
                      'account_deposit_tanzania.body_how_topping_up_works_para3'
                    )}
                  </IonText>
                </IonCardContent>
              </IonCard>

              {MSISDN && (
                <>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        {(i18n as any).t('account_deposit_tanzania.title_my_mobile_money_wallet')}
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonText color="primary">
                        <h1>{MSISDN}</h1>
                      </IonText>
                      <IonText>
                        {carrierName}
                      </IonText>
                      <IonButton
                        expand="block"
                        fill="solid"
                        size="large"
                        color="primary"
                        onClick={() => {
                          history.replace(`/${patientDoctor}/profile`);
                        }}
                        style={{marginTop: 20}}
                      >
                        {(i18n as any).t('account_deposit_tanzania.link_change_wallet')}
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                  
                  {exchangeRate && (
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {(i18n as any).t('account_deposit_tanzania.exchange_rate', {
                            exchangeRate: exchangeRate.toFixed(0)
                          })}
                        </IonCardTitle>
                      </IonCardHeader>
                    </IonCard>
                  )}
                  
                  <IonCard>
                    <IonCardContent>
                      {depositAmounts && depositAmounts.map((amount: number) => (
                        <IonButton
                          key={amount}
                          expand="block"
                          fill="solid"
                          size="large"
                          color="primary"
                          onClick={() => depositFunds(amount)}
                          style={{marginTop: 20}}
                        >
                          {buttonTitle(amount)}
                        </IonButton>
                      ))}
                    </IonCardContent>
                  </IonCard>
                </>
              )}
              
              {MSISDN === null && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      {(i18n as any).t('account_deposit_tanzania.title_mobile_money_wallet')}
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonText>
                      {(i18n as any).t('account_deposit_tanzania.body_before_you_can_deposit')}
                    </IonText>
                    <IonButton
                        expand="block"
                        fill="solid"
                        size="large"
                        color="primary"
                        onClick={() => {
                          history.push(`/${patientDoctor}/profile`);
                        }}
                        style={{marginTop: 20}}
                      >
                        {(i18n as any).t('account_deposit_tanzania.button_update_profile')}
                      </IonButton>
                  </IonCardContent>
                </IonCard>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      
      <IonAlert
        isOpen={showConfirmOnPhone}
        onDidDismiss={() => {
          setShowConfirmOnPhone(false);
          history.push(`/${patientDoctor}/account`);
        }}
        header={(i18n as any).t('account_deposit_tanzania.dialog_confirm_on_phone')}
        message={(i18n as any).t('account_deposit_tanzania.dialog_confirm_on_phone_body')}
        buttons={[{
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowConfirmOnPhone(false);
              history.push(`/${patientDoctor}/account`);
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showDepositSuccessful}
        onDidDismiss={() => {
          setShowDepositSuccessful(false);
          history.push(`/${patientDoctor}/account`);
        }}
        header={(i18n as any).t('account_deposit_tanzania.dialog_deposit_successful')}
        message={(i18n as any).t('account_deposit_tanzania.dialog_deposit_successful_body')}
        buttons={[{
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowDepositSuccessful(false);
              history.push(`/${patientDoctor}/account`);
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showProblemEncountered}
        onDidDismiss={() => {
          setShowProblemEncountered(false);
        }}
        header={(i18n as any).t('account_deposit_tanzania.dialog_problem_encountered')}
        message={(i18n as any).t('account_deposit_tanzania.dialog_problem_encountered_body')}
        buttons={[{
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowProblemEncountered(false);
              
              //Do nothing
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default DepositTanzania;
