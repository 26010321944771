import * as firebase from 'firebase';
import User from './User';

class OpenTok {
  private db: any;
  private funcs: any;
  private UserObj: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
    this.UserObj = new User();
  }

  /*
    Example Usage
    openTok.OTCreateSession().then((sessionId) => {
      openTok.OTCreateToken(sessionId).then((tokenId) => {
        console.warn(sessionId + ' - ' + tokenId);
      });
    });
  */

  // Creates a new OpenTok Session
  OTCreateSession = () => {
    return new Promise((resolve, reject) => {
      // Generate a new OpenTok Session
      const otCreateSession = this.funcs.httpsCallable('OTCreateSession');

      otCreateSession()
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Creates a new OpenTok User Token
  OTCreateToken = (sessionId: string) => {
    return new Promise((resolve, reject) => {
      const userId = this.UserObj.getCurrentUID;

      // Generate a new OpenTok User Token
      const otCreateToken = this.funcs.httpsCallable('OTCreateToken');
      otCreateToken({sessionId, userId})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Creates a new OpenTok "publisher" Token for both the patient and doctor
  OTCreateTokenPair = (sessionId: string, patientId: string, doctorId: string) => {
    return new Promise((resolve, reject) => {
      // Generate a new OpenTok Token Pair
      const otCreateTokenPair = this.funcs.httpsCallable('OTCreateTokenPair');

      otCreateTokenPair({sessionId, patientId, doctorId})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

}

export default OpenTok;
