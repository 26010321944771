import { IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar, IonButton, IonIcon } from '@ionic/react';
import { closeOutline, chevronBackOutline, personOutline } from 'ionicons/icons';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import * as firebase from 'firebase';
import './Header.css';

interface ComponentProps {
  showBack?: boolean,
  showLogin?: boolean,
  onBack?: any,
  onSignOut?: any,  //Requires promise, on resolution will sign out
  onSignOutEx?: any //No promise, handles sign out itself
}

const Header: React.FunctionComponent<ComponentProps> = ({showBack, showLogin, onBack, onSignOut, onSignOutEx}) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  // A single variable can act like state (i.e. you could have [state, setState] below and it will work)
  const [count, setCount] = useState(0);
  const [dummy, setDummy] = useState({
    var1: 0,
    var2: 'test'
  });
  
  // Similar to componentWillMount, componentWillUnmount
  // Returning [] as the second argument, tells react there are no dependencies, so it doesn't
  // need to update when state changes
  useEffect(() => {
    // Perform componentWillMount tasks
    // Do nothing
    
    // Returned function will run on component unmount
    return () => {
        //console.log('******************* HEADER UNMOUNTED');
    };
  }, []);
  
  // Similar to componentDidMount, componentDidUpdate
  useEffect(() => {
    // Things to do AFTER any render  
    
  });
  
  const handleSignOut = () => {
    if (onSignOutEx) {
      onSignOutEx();
    } else if (onSignOut) {
     onSignOut().then(() => {
      //Sign user out
      firebase.auth().signOut();
     });
    } else {
      //Sign user out
      firebase.auth().signOut();
    }
  }
  
  const handleGotoLogin = () => {
    history.push('/login');
  }
  
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          {showBack && !onBack && (
            <IonBackButton defaultHref="/" icon={chevronBackOutline} />
          )}
          {onBack && (
            <IonButton onClick={onBack}>
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
          )}
          {!showBack && !onBack && (
            <IonIcon slot="icon-only"  />
          )}
        </IonButtons>
        <IonTitle class="ion-text-center">
          <img src={require('../assets/gogp-logo.png')} style={{width: 'auto', height: 30}} alt="GoGP+" />
        </IonTitle>
        <IonButtons slot="end">
          {state.loggedIn && (
            <IonButton onClick={handleSignOut}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          )}
          {showLogin && (
            <IonButton onClick={handleGotoLogin}>
              <IonIcon slot="icon-only" icon={personOutline} />
            </IonButton>
          )}
          {!state.loggedIn && !showLogin && (
            <IonIcon slot="icon-only"  />
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
