import React from 'react';
import * as firebase from 'firebase';
import FireChat from './FireChat';
import User from './User';
import Doctor from './Doctor';
import OpenTok from './OpenTok';
import i18n from '../i18n';

const moment = require('moment');

class Consultation {
  private db: any;
  private funcs: any;
  private OpenTokObj: any;
  private DoctorObj: any;
  private UserObj: any;
  private FireChatObj: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
    this.OpenTokObj = new OpenTok();
    this.DoctorObj = new Doctor();
    this.UserObj = new User();
    this.FireChatObj = new FireChat(this);
  }

  get ref() {
    return firebase.firestore().collection('consultations');
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  createConsultationFromRequest = (request: any) => {
    // TODO
    // **** HAVE TAKE CALL PAGE LISTEN FOR CHANGES TO REQUEST... IF REQUESTED BY DOCTOR, SHOW UNAVAILABLE MESSAGE
    // 1. Check that request is still present, and that requestingDoctorId field is not set
    // 2. Set doctor as requestingDoctorId
    // 3. Set up listener to check for request changes
    // 4. Wait for 3 seconds to make sure no other doctor has changed the requestingDoctorId field
    // 5. Convert to consultation
    // 6. DoctorMain should exclude any available consultations which have requestionDoctorId field set
    return new Promise((resolve, reject) => {
      
      
      this.DoctorObj
        .getProfile(this.UserObj.getCurrentUID)
        .then((doctor: any) => {
          // Create video conversation session/tokens for patient and doctor
          this.OpenTokObj.OTCreateSession().then((sessionId: string) => {
            this.OpenTokObj
              .OTCreateTokenPair(
                sessionId,
                request.patientId,
                this.UserObj.getCurrentUID
              )
              .then((tokens: any) => {
                const consultation = {
                  patientAccessKey: `active_${request.patientId}`,
                  doctorAccessKey: `active_${this.UserObj.getCurrentUID}`,
                  patientId: request.patientId,
                  patientName: request.patientName,
                  patientFirstName: request.patientFirstName,
                  patientLastName: request.patientLastName,
                  patientInitials: request.patientInitials,
                  patientAvatar: request.patientAvatar,
                  patientLanguage: request.patientLanguage,
                  patientCountry: request.patientCountry,
                  doctorId: this.UserObj.getCurrentUID,
                  doctorName: doctor.displayName,
                  doctorFirstName: doctor.firstName,
                  doctorLastName: doctor.lastName,
                  doctorInitials: this.UserObj.getInitials(doctor),
                  //doctorAvatar: doctor.avatar, **THIS IS NOT AVAILABLE IN DOCTOR PROFILE
                  //doctorCountry: doctor.country,
                  condition: request.condition,
                  rating: 0,
                  review: '',
                  requestedDoctorId: request.requestedDoctorId,
                  specialty: request.specialty,
                  consultationFee: request.consultationFee,
                  OTSessionId: sessionId,
                  OTPatientToken: tokens.patientToken,
                  OTDoctorToken: tokens.doctorToken,
                  requestId: request._id,
                  requestDate: request.requestDate,
                  consultationDate: this.timestamp
                };

                // const { key } = this.ref.push(consultation);
                // resolve({
                //   _id: key,
                //   ...consultation
                // });

                this.db
                  // .collection('patient')
                  // .where('id', '==', request.patientId)
                  .collection('consultations')
                  .add(consultation)
                  .then((c: any) => {
                    // Send session initiated message
                    const msgDoctor = {
                      text: (i18n as any).t(
                        'entity_consultation.you_are_now_in_session_with',
                        {doctorName: doctor.displayName}
                      ),
                      user: {
                        _id: this.UserObj.getCurrentUID
                      },
                      timestamp: this.timestamp
                    };

                    this.FireChatObj.append(c.id, msgDoctor);

                    // Send problem description to chat
                    const msgPatient = {
                      text: request.condition,
                      user: {
                        _id: request.patientId
                      },
                      timestamp: this.timestamp
                    };

                    this.FireChatObj.append(c.id, msgPatient);

                    resolve({
                      _id: c.id,
                      ...consultation
                    });
                  })
                  .catch((err: any) => reject(err));
              });
          });
        })
        .catch((err: any) => reject(err));
    });
  };

  getConsultation = (consultationId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('consultations')
        .doc(consultationId)
        .get()
        .then((consultation: any) => {
          if (!consultation.exists) {
            resolve({});
          } else {
            resolve(this.parseConsultation(consultation));
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  parseConsultation = (c: any) => {
    const consultation = c.data();
    const {id: _id} = c;

    const consultationDate = new Date(consultation.consultationDate);

    const duration = moment(consultation.consultationDate).fromNow(true);

    const consultationItem = {
      _id,
      ...consultation,
      duration,
      createdAt: consultationDate
    };

    return consultationItem;
  };

  /*
  getPatientAvatar = (c: any, width: number, containerStyle: any) => {
    let avatar = <View />;

    if (c.patientAvatar && c.patientAvatar.length > 0) {
      avatar = (
        <Avatar
          width={width}
          containerStyle={containerStyle}
          rounded
          source={{
            uri: c.patientAvatar
          }}
        />
      );
    } else {
      avatar = (
        <Avatar
          width={width}
          containerStyle={containerStyle}
          rounded
          title={c.patientInitials}
        />
      );
    }

    return avatar;
  };

  getDoctorAvatar = (c, width, containerStyle, showOnlyDR = false) => {
    let avatar = <View />;

    if (!showOnlyDR && c.doctorAvatar && c.doctorAvatar.length > 0) {
      avatar = (
        <Avatar
          width={width}
          containerStyle={containerStyle}
          rounded
          source={{
            uri: c.doctorAvatar
          }}
        />
      );
    } else {
      const doctorInitials =
        c.doctorInitials && !showOnlyDR ? c.doctorInitials : 'DR';

      avatar = (
        <Avatar
          width={width}
          containerStyle={containerStyle}
          rounded
          title={doctorInitials}
        />
      );
    }

    return avatar;
  };
  */

  // Retrieves a users initials
  getInitials = (profile: any) => {
    let initials = '';

    if (profile.firstName.length > 0)
      initials = profile.firstName.substring(0, 1);

    if (profile.lastName.length > 0)
      initials = `${initials}${profile.lastName.substring(0, 1)}`;

    if (initials.length === 0) initials = 'AN';

    return initials.toUpperCase();
  };

  closeConsultation = (userId: string, consultation: any) => {
    return new Promise((resolve, reject) => {
      // Perform billing call
      const completeConsultation = this.funcs.httpsCallable('completeConsultation');

      completeConsultation({consultationId: consultation._id})
        .then((result: any) => {
          
          if (result.data.status === 'OK') {
            // If patient closes consultation, do not close for doctor
            // If doctor closes consultation, close for both
            const payload = (userId === consultation.patientId) ?
              {
                patientAccessKey: `closed_${consultation.patientId}`,
                closedBy: this.UserObj.getCurrentUID,
                closedAt: this.timestamp
              } : {
                doctorAccessKey: `closed_${consultation.doctorId}`,
                patientAccessKey: `closed_${consultation.patientId}`,
                closedBy: this.UserObj.getCurrentUID,
                closedAt: this.timestamp
              };
            
            // Set consultation "closed" flags
            this.db
              .collection('consultations')
              .doc(consultation._id)
              .set(
                payload,
                {merge: true}
              )
              .then(() => {
                // Send closed message (if first call to this function)
                //if (!result.data.message || result.data.message !== 'Payment already received!') {
                  const msg = {
                    text: (i18n as any).t('entity_consultation.consultation_closed'),
                    user: {
                      _id: this.UserObj.getCurrentUID
                    },
                    timestamp: this.timestamp
                  };
  
                  this.FireChatObj.append(consultation._id, msg);
                //}

                resolve({
                  status: 'OK'
                });
              })
              .catch((err: any) => reject(err));
          } else {
            resolve({
              status: 'OK'
            });
          }
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  getPrescriptions = (consultation: any) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(consultation.patientId)
        .collection('prescriptions')
        .where('consultation', '==', consultation._id)
        .get()
        .then((snapshot: any) => {
          const prescriptions: any = [];
          snapshot.docs.forEach((p: any) => {
            prescriptions.push(this.UserObj.parsePrescription(p));
          });
          resolve(prescriptions);
        })
        .catch((err: any) => reject(err));
    });
  };

  getLabTests = (consultation: any) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(consultation.patientId)
        .collection('lab_tests')
        .where('consultation', '==', consultation._id)
        .get()
        .then((snapshot: any) => {
          const labTests: any = [];
          snapshot.docs.forEach((t: any) => {
            labTests.push(this.UserObj.parseLabTest(t));
          });
          resolve(labTests);
        })
        .catch((err: any) => reject(err));
    });
  };

  getDoctorNotes = (consultation: any) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(consultation.patientId)
        .collection('doctor_notes')
        .where('consultation', '==', consultation._id)
        .get()
        .then((snapshot: any) => {
          const notes: any = [];
          snapshot.docs.forEach((n: any) => {
            notes.push(this.UserObj.parseNote(n));
          });
          resolve(notes);
        })
        .catch((err: any) => reject(err));
    });
  };

}

export default Consultation;

export interface SESSION {
  sessionid:string, //This is the consultation id
  consultation:any,
  patientProfile:any,
  doctorProfile:any
};

export const EmptySession: SESSION = {
  sessionid: '',
  consultation: {},
  patientProfile: {},
  doctorProfile: {}
}
