import React, { useContext, useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonInput, IonToast, IonDatetime, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonAvatar, IonLoading, IonText, IonBadge, IonSkeletonText, IonTextarea, IonToggle, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';
import { isNull, isNullThenElse, isNullOrEmpty } from '../../Utils';

import Header from '../../components/Header';
import ConsultationRequest from '../../entity/ConsultationRequest';
import Consultation from '../../entity/Consultation';
import { SESSION } from '../../entity/Consultation';
import User from '../../entity/User';

import { fingerPrintOutline, happyOutline, calendarOutline, maleOutline, femaleOutline, globeOutline, languageOutline, walletOutline, checkmarkOutline, checkmarkCircle, medkitOutline, cashOutline, speedometerOutline } from 'ionicons/icons';

import './ConsultationRequestDetail.css';

const moment = require('moment');

const ConsultationRequestDetail: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [patientProfile, setPatientProfile] = useState<any>({});
  const [patientAge, setPatientAge] = useState<string>('');
  const [patientGender, setPatientGender] = useState<string>('');
  const [request, setRequest] = useState<any>({});
  const [alreadyTakenAlert, setAlreadyTakenAlert] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const consultationRequestObj = new ConsultationRequest();
  const consultationObj = new Consultation();
  const userObj = new User();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setRequest(location.state.consultationRequest);

    // Retrieve patient profile
    setIsBusy(true);
    userObj.getProfile(location.state.consultationRequest.patientId).then((patient: any) => {
      setIsBusy(false);
      if (patient) {
        const profile = patient;
        profile._id = location.state.consultationRequest.patientId;
        setPatientProfile(profile);
        
        let patientAge = (i18n as any).t('consultation_request_detail.not_available');
        if (profile && profile.dob) {
          const pAge = moment(profile.dob.toDate());
          if (pAge.isValid()) {
            patientAge = Math.floor(
              moment
                .duration(moment().diff(moment(profile.dob.toDate())))
                .asYears()
            );
          }
        }
        setPatientAge(patientAge);
    
        let patientGender = (i18n as any).t('consultation_request_detail.not_available');
        if (profile && profile.gender) {
          patientGender =
            profile.gender === 'M'
              ? (i18n as any).t('common.male')
              : (i18n as any).t('common.female');
        }
        setPatientGender(patientGender);
      }
    })
    .catch((err: any) => {
      console.log(err);
      setIsBusy(false);
    });
    
    
  },[]);
  
  const onPress = () => {

    if (!isBusy) {
      //  Convert Request into Consultation
      setIsBusy(true);

      consultationRequestObj
        .createLockOnRequest(request._id)
        .then((success) => {
          if (success) {
            consultationObj
              .createConsultationFromRequest(request)
              .then((consult: any) => {
                consultationRequestObj
                  .deleteRequest(request._id)
                  .then(() => {
                    setIsBusy(false);
                    
                    // Add consultation session to global state
                    const session: SESSION = {
                      sessionid: consult._id,
                      consultation: consult,
                      doctorProfile: state.doctorProfile,
                      patientProfile
                    };
                    //dispatch({type: 'addSession', value: session});
                    
                    history.push('/doctor/consultation/chat', {
                      session
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsBusy(false);
                  });
              })
              .catch((err) => {
                console.log(err);
                setIsBusy(false);
              });
          } else {
            // Failed - Back to main
            setIsBusy(false);
            setAlreadyTakenAlert(true);
          }
        })
        .catch((err) => {
          console.warn(err);
          setIsBusy(false);
          setAlreadyTakenAlert(true);
        });
    }
  };
  
  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">
                <IonLoading
                  isOpen={isBusy}
                  message={(i18n as any).t('common.please_wait')}
                />

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{(i18n as any).t('consultation_request_detail.consultation_request')}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>

                <IonCard>
                  <IonCardContent>
                    <IonItem>
                      <IonText className="consultation-request-column">
                        {(i18n as any).t('consultation_request_detail.patient_name')}
                      </IonText>
                      <IonText color="primary">
                        {request.patientName}
                      </IonText>
                    </IonItem>
                    
                    <IonItem>
                      <IonText className="consultation-request-column">
                        {(i18n as any).t('consultation_request_detail.age')}
                      </IonText>
                      <IonText color="primary">
                        {patientAge}
                      </IonText>
                    </IonItem>
                    
                    <IonItem>
                      <IonText className="consultation-request-column">
                        {(i18n as any).t('consultation_request_detail.gender')}
                      </IonText>
                      <IonText color="primary">
                        {patientGender}
                      </IonText>
                    </IonItem>
                    
                    <IonItem>
                      <IonText className="consultation-request-column">
                        {(i18n as any).t('consultation_request_detail.language')}
                      </IonText>
                      <IonText color="primary">
                        {
                          patientProfile.languageName
                            ? patientProfile.languageName
                            : (i18n as any).t('consultation_request_detail.not_available')
                        }
                      </IonText>
                    </IonItem>
                    
                    <IonItem>
                      <IonText className="consultation-request-column">
                        {(i18n as any).t('consultation_request_detail.doctor_requested')}
                      </IonText>
                      <IonText color="primary">
                        {request.specialty}
                      </IonText>
                    </IonItem>
                    
                    <IonItem>
                      <IonText className="consultation-request-column">
                        {(i18n as any).t('consultation_request_detail.waiting_for')}
                      </IonText>
                      <IonText color="primary">
                        {request.requestDate && moment(request.requestDate.toDate()).fromNow(true)}
                      </IonText>
                    </IonItem>
                    
                    <IonItem>
                      <IonText>
                        {(i18n as any).t('consultation_request_detail.problem_description')}
                      </IonText>
                    </IonItem>
                    <IonItem>                      
                      <IonText color="primary">
                        {request.condition}
                      </IonText>
                    </IonItem>
                    
                  </IonCardContent>
                </IonCard>

                <IonButton onClick={onPress} size="large" expand="block">
                  {(i18n as any).t('consultation_request_detail.take_call')}
                </IonButton>
    
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      
 
      <IonAlert
        isOpen={alreadyTakenAlert}
        onDidDismiss={() => setAlreadyTakenAlert(false)}
        header={(i18n as any).t('consultation_request_detail.not_available')}
        message={(i18n as any).t('consultation_request_detail.request_already_taken')}
        buttons={[
          {
            text: (i18n as any).t('common.OK'),
            handler: () => {
              history.push('/doctor/home');
            }
          }
        ]}
      />
      
    </IonPage>
  );
};

export default ConsultationRequestDetail;
