import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonLoading, IonToast, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonBadge, IonAvatar, IonGrid, IonRow, IonCol, IonText, IonRippleEffect } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import i18n from '../i18n';

import Consultation, { SESSION } from '../entity/Consultation';
import User from '../entity/User';
import Doctor from '../entity/Doctor';

import Header from '../components/Header';

import './History.css';

const moment = require('moment');

const History: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const consultationObj = new Consultation();
  
  const [consultations, setConsultations] = useState<any>();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  useEffect(() => {
    const consultationArray: any = [];
    
    /*
      Retrieve historic consultations for
      the supplied user
    */
    setIsBusy(true);
    
    if (!state.isDoctor) {
      consultationObj.ref
        .where('patientAccessKey', '==', `closed_${state.userId}`)
        .orderBy('consultationDate', 'desc')
        .limit(100)
        .get()
        .then((snapshot) => {
          setIsBusy(false);
          
          if (snapshot && snapshot.docs.length > 0) {
            snapshot.docs.forEach((con) => {
              //  console.log(con);
              const consultation = consultationObj.parseConsultation(con);
              consultationArray.push(consultation);
            });
          }
          
          setConsultations(consultationArray);
        })
        .catch((err) => {
          setIsBusy(false);
        });
    }
      
    if (state.isDoctor) {
      consultationObj.ref
        .where('doctorAccessKey', '==', `closed_${state.userId}`)
        .orderBy('consultationDate', 'desc')
        .limit(100)
        .get()
        .then((doctorSnap) => {
          setIsBusy(false);

          if (doctorSnap && doctorSnap.docs.length > 0) {
            doctorSnap.docs.forEach((con) => {
              // console.log(con);
              const consultation = consultationObj.parseConsultation(con);
              consultationArray.push(consultation);
            });
          }
          
          setConsultations(consultationArray);
        })
        .catch((err) => {
          setIsBusy(false);
        });
    }
        
  }, []);
  
  const goToConsultation = (summaryConsultation: any) => {
    let patientProfile: any = {};
    let doctorProfile: any = {};

    // Retrieve patient profile
    userObj.getProfile(summaryConsultation.patientId).then((patient) => {
      if (patient) {
        patientProfile = patient;
        patientProfile._id = summaryConsultation.patientId;
      }

      // Retrieve doctor profile
      doctorObj.getProfile(summaryConsultation.doctorId).then((doctor) => {
        if (doctor) {
          doctorProfile = doctor;
          doctorProfile._id = summaryConsultation.doctorId;
        }
        
        const session: SESSION = {
          sessionid: summaryConsultation._id,
          consultation: summaryConsultation,
          doctorProfile,
          patientProfile
        };

        if (summaryConsultation.doctorId === state.userId) {
          history.push('/doctor/consultation/summary', { session });
        } else {
          history.push('/patient/consultation/summary', { session });
        }
      });
    });
  };
  
  return (
    <IonPage>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        <IonToast
          isOpen={toastMessage.length > 0}
          onDidDismiss={() => setToastMessage('')}
          message={toastMessage}
          position="top"
          color="tertiary"
          duration={1300}
          translucent
          keyboardClose
        />   
        
      <Header showBack={true} />
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              {(i18n as any).t('history.title_history')}
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
        
        {consultations && consultations.map((item:any) => (
          <IonCard
            key={item._id}
            onClick={() => goToConsultation(item)}
            style={{cursor: 'pointer'}}>

            <IonCardContent>
              <IonItem text-wrap className="ion-activatable ripple-parent">
                {state.isDoctor && item.patientAvatar && item.patientAvatar.length > 0 && (
                  <div className="doctor-home-avatar-panel">
                    <IonAvatar slot="start" className="doctor-home-avatar">
                      {item.msgCount && item.msgCount > 0 && (
                        <IonBadge style={{position: 'absolute'}}>{item.msgCount}</IonBadge>
                      )}
                      <img src={item.patientAvatar} />
                    </IonAvatar>
                  </div>
                )}
                {(!state.isDoctor || !item.patientAvatar || item.patientAvatar.length === 0) && (
                  <div className="doctor-home-avatar-panel">
                    <IonAvatar slot="start" className="doctor-home-avatar">
                      <img src={require('../assets/avatar_M.png')} />
                    </IonAvatar>
                  </div>
                )}
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText color="primary">
                        {state.isDoctor && (
                          <h1>{item.patientName}</h1>
                        )}
                        {!state.isDoctor && (
                          <h1>{item.doctorName}</h1>
                        )}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText color="medium">
                        {`${'\n'}${moment
                            .utc(item.consultationDate.toDate())
                            .local()
                            .format('DD/MM/YYYY HH:mm')}`}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText color="primary">
                        {item.condition}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRippleEffect></IonRippleEffect>
                </IonGrid>
              </IonItem>
            </IonCardContent>
          </IonCard>
        ))}
        
        {consultations && consultations.length === 0 && (
          <IonCard>
            <IonCardContent>
              <IonText color="primary">
                {(i18n as any).t('history.title_no_history_found')}
              </IonText>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default History;
