import * as firebase from 'firebase';
import Consultation from './Consultation';

class FireChat {
  private db: any;
  private funcs: any;
  private ConsultationObj: any;

  constructor(consultationObj: any) {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
    this.ConsultationObj = consultationObj;
  }

  // Return Firebase reference to a consultation
  ref = (consultationId: string) =>
    this.db
      .collection('consultations')
      .doc(consultationId)
      .collection('messages');

  on = (consultationId: string, callback: any) => {
    return this.ref(consultationId)
      .orderBy('timestamp', 'asc')
      .limit(200)
      .onSnapshot((snapshot: any) => {
        if (snapshot && snapshot.docChanges().length > 0) {
          const msgs: any = [];

          snapshot.docChanges().forEach((change: any) => {
            const msg = change.doc;
            if (change.type === 'added') {
              msgs.push(this.parse(msg));
            }
          });
          callback(msgs);
        }
      });
  };

  off = (consultationRef: any) => {
    consultationRef();
  };

  get uid() {
    return (firebase.auth().currentUser || {}).uid;
  }

  get user() {
    return firebase.auth().currentUser;
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  send = (consultationId: string, messages: any) => {
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (message.image) {
        const {image, user} = message;

        const msg = {
          text: '',
          image,
          user,
          type: 'i',
          timestamp: this.timestamp
        };

        this.append(consultationId, msg);
      } else {
        const {text, user, type} = message;

        const msg = {
          text,
          user,
          type: type !== undefined ? type : '',
          timestamp: this.timestamp
        };

        this.append(consultationId, msg);
      }
    }
  };

  append = (consultationId: string, message: any) => {
    // Update message count
    this.ConsultationObj.getConsultation(consultationId).then((c: any) => {
      const msgCount = c.msgCount ? c.msgCount + 1 : 1;
      this.updateConsultationFields(consultationId, {msgCount});

      if (message.text.length > 0) {
        this.updateConsultationFields(consultationId, {lastMsg: message.text});
      }
    });

    return this.ref(consultationId).add(message);
  };

  updateConsultationFields = (consultationId: string, fields: any) => {
    return new Promise((resolve, reject) => {
      // Update consultation
      this.db
        .collection('consultations')
        .doc(consultationId)
        .update(fields)
        .then(() => {
          resolve(true);
        })
        .catch((err: any) => reject(err));
    });
  };

  parse = (snapshot: any) => {
    const {timestamp: numberStamp, text, image, user, type} = snapshot.data(); // NOT SUPPORTED YET { serverTimestamps: 'estimate' });
    const {id: _id} = snapshot;

    // Local message timestamp will be null because it has not been commited by firestore yet (see fix above which is not supported yet)
    const timestamp =
      numberStamp !== null
        ? new Date(numberStamp.toDate())
        : new Date().getTime();

    const message = {
      _id,
      text,
      user,
      type,
      image,
      createdAt: timestamp
    };

    return message;
  };

}

export default FireChat;
