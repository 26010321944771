import React, { useContext, useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonText, IonCol, IonRow, IonGrid, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonLoading, IonToast, IonButtons, IonInput, IonDatetime, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';

import Header from '../../components/Header';
import User from '../../entity/User';
import Ledger from '../../entity/Ledger';
import Settings from '../../entity/Settings';

import { phonePortraitOutline, cardOutline, trashOutline, calendarOutline, bodyOutline, eyeOutline } from 'ionicons/icons';

import './DepositCreditCard.css';

const moment = require('moment');

const DepositCreditCard: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  const [patientDoctor, setPatientDoctor] = useState<string>('patient');
  const [creditCards, setCreditCards] = useState<any>();

  const [depositAmounts, setDepositAmounts] = useState<any>();
  const [exchangeRate, setExchangeRate] = useState<any>();
  const [selectedCard, setSelectedCard] = useState<any>();
  const [section, setSection] = useState<string>('');
  
  const [nameOnCard, setNameOnCard] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardExpiry, setCardExpiry] = useState<string>(moment().format('YYYY-MM-DD'));
  const [cardCVV, setCardCVV] = useState<string>('');
  
  const [nameOnCardError, setNameOnCardError] = useState<boolean>(false);
  const [cardNumberError, setCardNumberError] = useState<boolean>(false);
  const [cardExpiryError, setCardExpiryError] = useState<boolean>(false);
  const [cardCVVError, setCardCVVError] = useState<boolean>(false);
  
  const [showDeleteCard, setShowDeleteCard] = useState<boolean>(false);
  const [showChargeCard, setShowChargeCard] = useState<boolean>(false);
  
  const [credits, setCredits] = useState<number>(0);
  const [feeInfo, setFeeInfo] = useState<any>();
  
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const ledgerObj = new Ledger();
  const settingsObj = new Settings();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setPatientDoctor(state.isDoctor ? 'doctor' : 'patient');

    refreshCards();

    //  Retrieve available deposit amounts
    settingsObj.getSetting('deposit_amounts').then((amounts: any) => {
      setDepositAmounts(amounts.split('|'));
    });

    ledgerObj.getExchangeRate('GGP', 'USD').then((pair: any) => {
      setExchangeRate(pair.rate);
    });
      
  },[]);
  
  const refreshCards = () => {
    setIsBusy(true);
    
    userObj
      .getCreditCards()
      .then((cards: any) => {
        setIsBusy(false);

        const cardsArray: any = [];
        cards.forEach((c: any) => {
          cardsArray.push(userObj.parseCreditCard(c));
        });
        setCreditCards(cardsArray);
      })
      .catch((err: any) => {
        setIsBusy(false);
        console.log(err.message);
      });
  };

  const backToAccountDeposit = () => {
    history.push(`/${patientDoctor}/deposit`);
  };

  const addCard = () => {
    setSection('addCard');
  };

  const showCards = () => {
    setSection('');
  };

  const showCharge = (card: any) => {
    setSelectedCard(card);
    setSection('chargeCard');
  };

  const deleteCard = (card: any) => {
    setSelectedCard(card);
    setShowDeleteCard(true);
  };

  const saveCard = () => {
    if (!isBusy) {
      let isValid = true;
      setNameOnCardError(false);
      setCardNumberError(false);
      setCardExpiryError(false);
      setCardCVVError(false);
      
      //if (!nameOnCard || nameOnCard.length === 0) {
      //  setNameOnCardError(true);
      //  isValid = false;
      //}
      
      if (!cardNumber || cardNumber.length === 0) {
        setCardNumberError(true);
        isValid = false;
      }
      
      if (!cardCVV || cardCVV.length < 3 || !Number(cardCVV)) {
        setCardCVVError(true);
        isValid = false;
      }
      
      if (isValid) {
        setIsBusy(true);
  
        // Generate Card Token
        const expiryMM = moment(cardExpiry).format('MM');
        const expiryYYYY = moment(cardExpiry).format('YYYY');
        
        userObj
          .generateCardToken(
            cardNumber,
            parseInt(expiryMM),
            parseInt(expiryYYYY),
            parseInt(cardCVV)
          )
          .then((result) => {
            const token = result.id;
            userObj
              .registerCard(token)
              .then((result) => {
                refreshCards();
                setNameOnCard('');
                setCardNumber('');
                setCardExpiry(moment().format('YYYY-MM-DD'));
                setCardCVV('');
                showCards();
                setIsBusy(false);
              })
              .catch((err) => {
                // console.warn(`E2 ${err.message} ${token}`);
                setToastMessage(err.message);
                setIsBusy(false);
              });
          })
          .catch((err) => {
            // console.warn(`E1 ${err.message}`);
            setToastMessage(err.message);
            setIsBusy(false);
          });
      } else {
        setToastMessage((i18n as any).t('account_deposit_cc.check_card_details'));
      }
    }
  };

  const depositFunds = async (credits: any) => {
    setIsBusy(true);
    
    const dollarAmount = credits * exchangeRate;
    const fee = await ledgerObj.calculateFeeOnAmount(
      'CREDIT_CARD_DEPOSIT',
      dollarAmount
    );
    
    setIsBusy(false);

    if (exchangeRate && exchangeRate !== 0) {
      setFeeInfo(fee);
      setCredits(credits);
      setShowChargeCard(true);
    }
  };

  const buttonTitle = (amount: number) => {
    let title = '';

    if (amount == 1) {
      title = (i18n as any).t('account_deposit_cc.add_x_credit', {
        credit: ledgerObj.formatNumber(amount)
      });
    } else {
      title = (i18n as any).t('account_deposit_cc.add_x_credits', {
        credits: ledgerObj.formatNumber(amount)
      });
    }

    return title;
  };

  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLoading
                isOpen={isBusy}
                message={(i18n as any).t('common.please_wait')}
              />
              <IonToast
                isOpen={toastMessage.length > 0}
                onDidDismiss={() => setToastMessage('')}
                message={toastMessage}
                position="top"
                color="tertiary"
                duration={1300}
                translucent
                keyboardClose
              />   
              
              {section === '' && (
                <>
                  <div style={{textAlign: 'center', marginBottom: 20}}>
                    <h1>{(i18n as any).t('account_deposit_cc.title')}</h1>
                  </div>
                    
                  {creditCards && creditCards.length === 0 && (
                    <IonCard>
                      <IonCardContent>
                        <IonText color="primary">
                          {(i18n as any).t('account_deposit_cc.no_cards_registered')}
                        </IonText>
                      </IonCardContent>
                    </IonCard>
                  )}
                  
                  {creditCards && creditCards.length > 0 && (
                    <>
                      <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>
                            {(i18n as any).t('account_deposit_cc.tap_to_charge_card')}
                          </IonCardTitle>
                        </IonCardHeader>                      
                      </IonCard>
                      
                      {creditCards.map((card: any) => (
                        <IonCard key={card._id}>
                          <IonCardContent>
                            <IonItem>
                              <IonText>
                                <IonText color="primary">
                                  <h1 onClick={() => showCharge(card)} style={{cursor: 'pointer'}}>
                                    {card.cardDesc}
                                  </h1>
                                </IonText>
                                {card.cardExpiryMM} / {card.cardExpiryYYYY}
                              </IonText>
                              <IonButtons slot="end">
                              <IonButton onClick={() => deleteCard(card)}>
                                <IonIcon
                                  slot="icon-only"
                                  size="large"
                                  color="primary"
                                  icon={trashOutline}
                                  style={{cursor: 'pointer'}} />
                              </IonButton>
                            </IonButtons>
                            </IonItem>
                          </IonCardContent>
                        </IonCard>
                      ))}
                    </>
                  )}
                  
                  <IonCard>
                    <IonButton
                      expand="block"
                      fill="solid"
                      size="large"
                      color="primary"
                      onClick={addCard}
                    >
                      {(i18n as any).t('account_deposit_cc.button_add_card')}
                    </IonButton>
                  </IonCard>
                </>
              )}
              
              {section === 'addCard' && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      {(i18n as any).t('account_deposit_cc.title_add_card')}
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {false && (
                      <IonItem>
                        {!nameOnCardError && (
                          <IonIcon slot="start" size="large" icon={bodyOutline} />
                        )}
                        {nameOnCardError && (
                          <IonIcon slot="start" size="large" icon={bodyOutline} color="danger" />
                        )}
                        <IonLabel position="floating">{(i18n as any).t('account_deposit_cc.name_on_card')}</IonLabel>
                        <IonInput
                          value={nameOnCard}
                          placeholder={(i18n as any).t('profile.please_supply')}
                          autofocus
                          clearInput
                          onIonChange={e => {
                            setNameOnCard(e.detail.value!);
                          }} />
                      </IonItem>
                    )}
                    <IonItem>
                      {!cardNumberError && (
                        <IonIcon slot="start" size="large" icon={cardOutline} />
                      )}
                      {cardNumberError && (
                        <IonIcon slot="start" size="large" icon={cardOutline} color="danger" />
                      )}
                      <IonLabel position="floating">{(i18n as any).t('account_deposit_cc.card_number')}</IonLabel>
                      <IonInput
                        value={cardNumber}
                        placeholder={(i18n as any).t('profile.please_supply')}
                        autofocus
                        clearInput
                        inputMode="numeric"
                        onIonChange={e => {
                          setCardNumber(e.detail.value!);
                        }} />
                    </IonItem>
                    <IonItem>
                      {!cardExpiryError && (
                        <IonIcon slot="start" size="large" icon={calendarOutline} />
                      )}
                      {cardExpiryError && (
                        <IonIcon slot="start" size="large" icon={calendarOutline} color="danger" />
                      )}
                      <IonLabel position="floating">{(i18n as any).t('account_deposit_cc.card_expiry')}</IonLabel>
                      <IonDatetime
                          displayFormat="MM / YYYY"
                          placeholder={(i18n as any).t('profile.select_date')}
                          value={moment(cardExpiry).format('YYYY-MM-DD')}
                          min={moment().format('YYYY-MM-DD')}
                          max={moment().add(6, 'years').format('YYYY-MM-DD')}
                          onIonChange={e => setCardExpiry(e.detail.value!)}>
                        </IonDatetime>
                    </IonItem>
                    <IonItem>
                      {!cardCVVError && (
                        <IonIcon slot="start" size="large" icon={eyeOutline} />
                      )}
                      {cardCVVError && (
                        <IonIcon slot="start" size="large" icon={eyeOutline} color="danger" />
                      )}
                      <IonLabel position="floating">{(i18n as any).t('account_deposit_cc.card_cvv')}</IonLabel>
                      <IonInput
                        value={cardCVV}
                        placeholder={(i18n as any).t('profile.please_supply')}
                        clearInput
                        inputMode="numeric"
                        maxlength={3}
                        minlength={3}
                        onIonChange={e => {
                          setCardCVV(e.detail.value!);
                        }} />
                    </IonItem>
                    
                    <IonButton
                      expand="block"
                      fill="solid"
                      size="large"
                      color="primary"
                      onClick={saveCard}
                      style={{marginTop: 20}}
                    >
                      {(i18n as any).t('account_deposit_cc.button_add_card')}
                    </IonButton>
                    
                    <div style={{textAlign: 'center', marginTop: 20}}>
                      <IonText color="primary" onClick={() => setSection('')} style={{cursor: 'pointer'}}>
                        {(i18n as any).t('common.cancel')}
                      </IonText>
                    </div>
                    
                  </IonCardContent>
                </IonCard>
              )}
              {section === 'chargeCard' && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      {(i18n as any).t('account_deposit_cc.title_charge_card')}
                    </IonCardTitle>
                    <IonCardSubtitle>
                      {exchangeRate && (
                        <>
                          {(i18n as any).t('account_deposit_cc.exchange_rate', {
                            exchangeRate
                          })}
                        </>
                      )}
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {depositAmounts && depositAmounts.map((amount: number) => (
                      <IonButton
                        key={amount}
                        expand="block"
                        fill="solid"
                        size="large"
                        color="primary"
                        onClick={() => depositFunds(amount)}
                        style={{marginTop: 20}}
                      >
                        {buttonTitle(amount)}
                      </IonButton>
                    ))}
                    
                    <div style={{textAlign: 'center', marginTop: 20}}>
                      <IonText color="primary" onClick={() => setSection('')} style={{cursor: 'pointer'}}>
                        {(i18n as any).t('common.cancel')}
                      </IonText>
                    </div>
                  </IonCardContent>
                </IonCard>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      
      <IonAlert
        isOpen={showDeleteCard}
        onDidDismiss={() => {
          setSelectedCard(undefined);
          setShowDeleteCard(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('account_deposit_cc.remove_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              setIsBusy(true);
              userObj.removeCreditCard(selectedCard._id).then(() => {
                setIsBusy(false);
                setSelectedCard(undefined);
                setShowDeleteCard(false);
                refreshCards();
              });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showChargeCard}
        onDidDismiss={() => {
          setFeeInfo(undefined);
          setShowChargeCard(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('account_deposit_cc.fee_are_you_sure', {
          feeAmount: `${Number(feeInfo ? feeInfo.feeAmount : 0).toFixed(2)}`
        })}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              setIsBusy(true);
              userObj
                .chargeCard(selectedCard._id, credits)
                .then((charge) => {
                  setIsBusy(false);
                  setFeeInfo(undefined);
                  setShowChargeCard(false);
                  history.push(`/${patientDoctor}/account`);
                })
                .catch((err) => {
                  setIsBusy(false);
                  setFeeInfo(undefined);
                  setShowChargeCard(false);

                  setToastMessage(err.message);
                });
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default DepositCreditCard;
