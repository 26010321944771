import React, { useState, useEffect, useContext } from 'react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import * as firebase from 'firebase';
import i18n from '../i18n';
import { SESSION } from '../entity/Consultation';

import User from '../entity/User';
import Doctor from '../entity/Doctor';
import Consultation from '../entity/Consultation';
import FireChat from '../entity/FireChat';
import ListData from '../entity/ListData';

import { IonPicker, IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonTextarea, IonItem, IonSelect, IonLoading, IonToast, IonAlert, IonCardSubtitle, IonText, IonActionSheet } from '@ionic/react';
import { closeOutline, chevronBackOutline, addCircleOutline, flaskOutline, sendOutline, trashOutline, copyOutline, bookOutline } from 'ionicons/icons';

import './Prescriptions.css';

const moment = require('moment');

interface ComponentProps {
  session: SESSION
}

const Prescriptions: React.FunctionComponent<ComponentProps> = ({session}) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [deletePrescriptionId, setDeletePrescriptionId] = useState<string>('');
  const [actionItem, setActionItem] = useState<any>();
  const [drugOptions, setDrugOptions] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [newPrescription, setNewPrescription] = useState<string>('');
  const [amountPickerVisible, setAmountPickerVisible] = useState<boolean>(false);
  const [measurePickerVisible, setMeasurePickerVisible] = useState<boolean>(false);
  const [drugPickerVisible, setDrugPickerVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showAddPrescriptionAlert, setShowAddPrescriptionAlert] = useState<boolean>(false);
  const [showAddToChatAlert, setShowAddToChatAlert] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const listDataObj = new ListData();
  const consultationObj = new Consultation();
  const fireChatObj = new FireChat(consultationObj);
  
  useEffect(() => {
    // ComponentDidMount
    // Check if languages are provided
    // Retrieve previous prescriptions for customer
    updatePrescriptions();

    //  Populate drug list
    listDataObj.getDrugs().then((data: any) => {
      const drugOptions: any = {
        name: 'Drugs',
        options: []
      };
      data.docs.forEach((d: any) => {
        const drug = d.data();
        drugOptions.options.push({text: drug.name, value: drug.name});
      });
      setDrugOptions(drugOptions);
    });

    // Set focus to prescription textbox    
    setTimeout(() => {
      const inp: any = document.querySelector('#txtPrescription textarea');
      if (inp) inp.focus();
    }, 500);

  }, []);
  
  useEffect(() => {
    // ComponentDidUnmount
    return () => {

    };
  }, []);
  
  
  const updatePrescriptions = () => {
    setIsBusy(true);
    
    userObj
      .getPrescriptions(session.consultation.patientId)
      .then((prescriptions: any) => {
        setIsBusy(false);

        const prescriptionArray: any = [];
        prescriptions.forEach((p: any) => {
          const prescription = p.data();
          prescriptionArray.push({_id: p.id, prescription});
        });
        setPrescriptions(prescriptionArray);
      })
      .catch((err: any) => {
        setIsBusy(false);
        console.log(err.message);
      });
  };

  const addPrescription = () => {
    if (newPrescription.length > 0) {
      setShowAddPrescriptionAlert(true);
    }
  };

  const onDrugSelect = (picked: any) => {
    setNewPrescription(`${newPrescription}${picked} `);
    setDrugPickerVisible(false);
  };

  const scrollToTop = () => {
    // Scroll to bottom when new messages arrive
    const cPanel = document.getElementsByClassName("component-panel")[0];
    if (cPanel) {
      cPanel.scrollTop = 0;
    }
  }

  return (
    <>
      <IonLoading
        isOpen={isBusy}
        message={(i18n as any).t('common.please_wait')}
      />
      <IonToast
        isOpen={errorMessage.length > 0}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        position="top"
        color="tertiary"
        duration={1300}
        translucent
        keyboardClose
      />
    
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            <IonIcon icon={flaskOutline} style={{marginRight: 20}} size="large" color="primary" />
            {(i18n as any).t('prescriptions_partial.title_new_prescription')}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonTextarea
              id="txtPrescription"
              value={newPrescription}
              placeholder={(i18n as any).t('prescriptions_partial.prescribed_items')}
              onIonChange={e => setNewPrescription(e.detail.value!)}
            >
            </IonTextarea>
            
            <IonButtons slot="end">
              <IonButton onClick={() => setDrugPickerVisible(true)}>
                <IonIcon
                  slot="icon-only"
                  size="large"
                  color="primary"
                  icon={addCircleOutline}
                  style={{cursor: 'pointer'}} />
              </IonButton>
              
              <IonButton onClick={addPrescription}>
                <IonIcon
                  slot="icon-only"
                  size="large"
                  color="primary"
                  title={(i18n as any).t('prescriptions_partial.button_finish')}
                  icon={sendOutline}
                  style={{cursor: 'pointer'}} />
              </IonButton>
            </IonButtons>
          </IonItem>
        </IonCardContent>
      </IonCard>
      
       <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {(i18n as any).t('prescriptions_partial.title_previously_prescribed')}
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
      
      {prescriptions && prescriptions.map((item: any) => (
        <IonCard key={item._id} onClick={() => setActionItem(item)} style={{cursor: 'pointer'}}>
          <IonCardHeader>
            <IonCardSubtitle>
              {`${'\n'}${moment
                .utc(
                  item.prescription.datePrescribed.toDate()
                )
                .local()
                .format('DD/MM/YYYY HH:mm')} (${moment(item.prescription.datePrescribed.toDate()).fromNow()})`}
            </IonCardSubtitle>
            <IonCardTitle>
              {(i18n as any).t(
                'prescriptions_partial.prescribed_by',
                {doctorName: item.prescription.doctorName}
              )}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonText color="primary">
                {item.prescription.prescription}
              </IonText>
            </IonItem>
          </IonCardContent>
        </IonCard>
      ))}
      {prescriptions && prescriptions.length === 0 && (
        <IonCard>
          <IonCardContent>
            <IonText color="primary">
              {(i18n as any).t('prescriptions_partial.title_no_prescriptions_found')}
            </IonText>
          </IonCardContent>
        </IonCard>
      )}
      
      <IonActionSheet
        isOpen={actionItem && session.consultation._id === actionItem.prescription.consultation}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.delete'),
          role: 'destructive',
          icon: trashOutline,
          handler: () => {
            setDeletePrescriptionId(actionItem._id);
          }
        }, {
          text: (i18n as any).t('common.duplicate'),
          icon: copyOutline,
          handler: () => {
            setNewPrescription(actionItem.prescription.prescription);
            scrollToTop();
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            // Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonActionSheet
        isOpen={actionItem && session.consultation._id !== actionItem.prescription.consultation}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.duplicate'),
          icon: copyOutline,
          handler: () => {
            setNewPrescription(actionItem.prescription.prescription);
            scrollToTop();
          }
        }, {
          text: (i18n as any).t('prescriptions_partial.option_consultation_summary'),
          icon: bookOutline,
          handler: () => {
            // Transfer to consultation summary
            history.push('/doctor/consultation/summary', {
              session
            });
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            //Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonAlert
        isOpen={showAddPrescriptionAlert}
        onDidDismiss={() => {
          setShowAddPrescriptionAlert(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('prescriptions_partial.prescribe_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              const prescription = {
                consultation: session.consultation._id,
                doctorName: session.consultation.doctorName,
                prescription: newPrescription
              };

              doctorObj
                .addPrescription(session.consultation.patientId, prescription)
                .then(() => {
                  // Update prescriptions
                  updatePrescriptions();

                  // Ask if prescription should be appended to chat
                  setShowAddToChatAlert(true);
                });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showAddToChatAlert}
        onDidDismiss={() => {
          setShowAddToChatAlert(false);
        }}
        header={(i18n as any).t('prescriptions_partial.title_add_to_chat')}
        message={(i18n as any).t('prescriptions_partial.body_add_to_chat')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              const msg = {
                text: (i18n as any).t('prescriptions_partial.prescription', {
                  prescription: newPrescription
                }),
                type: 'p',
                user: {
                  _id: state.userId
                },
                timestamp: consultationObj.timestamp
              };
  
              fireChatObj.append(session.consultation._id, msg);
              
              setNewPrescription('');
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={deletePrescriptionId.length > 0}
        onDidDismiss={() => {
          setDeletePrescriptionId('');
        }}
        header={(i18n as any).t('prescriptions_partial.title_delete_prescription')}
        message={(i18n as any).t('prescriptions_partial.delete_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              // Delete prescription
              userObj
                .deletePrescription(session.consultation.patientId, deletePrescriptionId)
                .then(() => {
                  // Refresh prescription list
                  updatePrescriptions();
                });
              setDeletePrescriptionId('');
              scrollToTop();
            }
          }
        ]}
      />
      
      <IonPicker
        isOpen={drugPickerVisible}
        columns={[drugOptions]}
        buttons={[
         {
          text: "Cancel",
          role: "cancel",
          handler: option => {
            setDrugPickerVisible(false);
          }
         },
         {
          text: "OK",
          handler: option => {
            onDrugSelect(option.Drugs.value);
            setDrugPickerVisible(false);
          }
         }
        ]}
      />
      
    </>
  );
};

export default Prescriptions;
