import { IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import * as firebase from 'firebase';
import { AppContextProvider, AppContext } from '../State';
import User from '../entity/User';
import Doctor from '../entity/Doctor';
import { Redirect, useHistory } from 'react-router-dom';

const FirebaseState: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const db = (window as any).db;
  
  // A single variable can act like state (i.e. you could have [state, setState] below and it will work)
  //const [loggedIn, setLoggedIn] = useState(false);
  //const [isAdmin, setIsAdmin] = useState(false);
  //const [userId, setUserId] = useState('');
  //const [userName, setUserName] = useState('');
  
  // Similar to componentWillMount
  // Returning [] as the second argument, tells react there are no dependencies, so it doesn't
  // need to update when state changes
  useEffect(() => {
    
    // Will run on component mount

    //Listen for firestore authentication change
    firebase.auth().onAuthStateChanged(function(user) {
     if (user) {
       // User is signed in.
       const displayName = user.displayName;
       const email: string = (user.email as string);
       const emailVerified = user.emailVerified;
       const photoURL = user.photoURL;
       const isAnonymous = user.isAnonymous;
       const uid = user.uid;
       const providerData = user.providerData;
       
       // Determine if user is a doctor
       (new Doctor()).getProfile((new User()).getCurrentUID).then((profile: any) => {
         const isApprovedDoctor = (profile && profile.approved);
         
         if (isApprovedDoctor) {
            dispatch({type: 'setIsDoctor', value: true});
            dispatch({type: 'setDoctorProfile', value: profile});
         } else {
            dispatch({type: 'setIsDoctor', value: false});
            dispatch({type: 'setDoctorProfile', value: null});
         }
         
         // Store logged in user
         dispatch({type: 'setUserId', value: uid});
         dispatch({type: 'setUserName', value: email.substring(0, email.indexOf('@'))});
         dispatch({type: 'setLoggedIn', value: true});
         
         //Check if user is an admin
         //db.collection("private").doc("perms").collection("is_admin").doc(uid).get().then((snapshot:any) => {
         //   const admin = snapshot.data();
         //   if( admin && admin.enabled ){
         //     dispatch({type: 'setIsAdmin', value: true});
         //    }
         //});
         
        // Retrieve users preferred language
        (new User()).getProfile((new User()).getCurrentUID).then((profile: any) => {
          if (profile && profile.language && profile.language.length > 0) {
            // Store preferred language
            (window as any).localStorage.setItem("pref_language", profile.language.toLowerCase());
            console.log('Language set to ' + profile.language.toLowerCase());
          }
        });

        // Redirect to main page
        if (isApprovedDoctor) {
            history.push('/doctor/home');
        } else {
            history.push('/patient/home');
        }
       });
     } else {
       // User is signed out.
       dispatch({type: 'setUserId', value: ''});
       dispatch({type: 'setUserName', value: ''});
       dispatch({type: 'setLoggedIn', value: false});
       dispatch({type: 'setIsAdmin', value: false});
       dispatch({type: 'setIsDoctor', value: false});
       dispatch({type: 'setDoctorProfile', value: null});
       
       history.push('/');
     }
    });
  }, []);
  
  return null;
};

export default FirebaseState;
