import React from 'react';
import * as firebase from 'firebase';
import User from './User';

class ConsultationRequest {
  private db: any;
  private funcs: any;
  private UserObj: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
    this.UserObj = new User();
  }

  get ref() {
    return this.db.collection('consultation_requests');
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  createRequest = (condition: any, specialty: any, consultationFee: any) => {
    return new Promise((resolve, reject) => {
      this.UserObj
        .getCurrentProfile()
        .then((profile: any) => {
          let patientName = 'NA';
          if (
            profile.firstName &&
            profile.firstName.length > 0 &&
            profile.lastName &&
            profile.lastName.length > 0
          ) {
            patientName = `${profile.firstName} ${profile.lastName}`;
          } else if (profile.nickName) {
            patientName = profile.nickName;
          }

          const request = {
            patientId: this.UserObj.getCurrentUID,
            patientName,
            patientNickName: profile.nickName,
            patientFirstName: profile.firstName,
            patientLastName: profile.lastName,
            patientInitials: this.UserObj.getInitials(profile),
            patientAvatar: profile.avatar,
            patientLanguage: profile.language,
            patientCountry: profile.country,
            requestedDoctorId: '',
            condition,
            specialty,
            consultationFee,
            requestDate: this.timestamp
          };

          this.db
            .collection('consultation_requests')
            .add(request)
            .then((r: any) => {
              resolve(r.id);
            });
        })
        .catch((err: any) => reject(err));
    });
  };

  updateRequest = (request: any) => {
    return new Promise((resolve, reject) => {
      // Update condition
      this.db
        .collection('consultation_requests')
        .doc(request._id)
        .set({condition: request.condition}, {merge: true})
        .then(() => {
          resolve(true);
        })
        .catch((err: any) => reject(err));
    });
  };

  updateRequestFields = (requestId: string, fields: any) => {
    return new Promise((resolve, reject) => {
      // Update condition
      this.db
        .collection('consultation_requests')
        .doc(requestId)
        .update(fields)
        .then(() => {
          resolve(true);
        })
        .catch((err: any) => reject(err));
    });
  };

  deleteRequest = (requestId: string) => {
    return new Promise((resolve, reject) => {
      return this.db
        .collection('consultation_requests')
        .doc(requestId)
        .delete()
        .then(() => {
          resolve();
        })
        .catch((err: any) => reject(err));
    });
  };

  getRequest = (requestId: string) => {
    return new Promise((resolve, reject) => {
      return this.ref
        .doc(requestId)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const request = doc.data();
            resolve({
              _id: doc.id,
              ...request
            });
          } else {
            resolve();
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  // Tries to detect a run condition when converting a request to a consultation
  createLockOnRequest = (requestId: string) => {
    return new Promise((resolve, reject) => {
      // Check that request is still present, and that lockedById field is not set
      this.getRequest(requestId).then((request: any) => {
        if (request && !request.lockedById) {
          // Set doctor lock
          // The lockedById field will be checked later to make sure it hasn't changed
          this.updateRequestFields(requestId, {
            lockedById: this.UserObj.getCurrentUID
          })
            .then((result) => {
              if (result) {
                setTimeout(() => {
                  // After a second, retrieve the request again and check the lock hasn't changed
                  this.getRequest(requestId)
                    .then((req: any) => {
                      if (req && req.lockedById === this.UserObj.getCurrentUID) {
                        resolve(true);
                      } else {
                        resolve(false);
                      }
                    })
                    .catch((err) => reject(err));
                }, 1000);
              } else {
                resolve(false);
              }
            })
            .catch((err) => reject(err));
        } else {
          resolve(false);
        }
      });
    });
  };

  /*
  getPatientAvatar = (request: any, width: number, containerStyle: any) => {
    let avatar = <View />;

    if (request.patientAvatar && request.patientAvatar.length > 0) {
      avatar = (
        <Avatar
          width={width}
          containerStyle={containerStyle}
          rounded
          source={{
            uri: request.patientAvatar
          }}
        />
      );
    } else {
      avatar = (
        <Avatar
          width={width}
          containerStyle={containerStyle}
          rounded
          title={request.patientInitials}
        />
      );
    }

    return avatar;
  };
  */

  // IMPORTANT:
  // This function returns a reference which MUST be freed on
  // ComponentWillUnmount!
  requestUpdateChecker = (requestId: string, callback: any) => {
    return this.ref.doc(requestId).onSnapshot((doc: any) => {
      const updatedRequest = doc.data();

      callback(updatedRequest);
    });
  };

}

export default ConsultationRequest;
