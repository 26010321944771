import React, { useState, useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge, IonToast } from '@ionic/react';
import { calendar, personCircle, informationCircle, map } from 'ionicons/icons';
import { IonReactRouter } from '@ionic/react-router';

import { AppContextProvider } from './State';

// Initialize Firebase
import * as firebase from 'firebase';
import auth from 'firebase/auth';
import database from 'firebase/database';
import firestore from 'firebase/firestore';
import messaging from 'firebase/messaging';
//import functions from 'firebase/functions';

import LoginRoute from './components/LoginRoute';
import FirebaseState from './components/FirebaseState';
import Welcome from './pages/Welcome';
import Login from './pages/Login';
import Chat from './pages/Chat';
import DoctorMainTabs from './pages/doctor/DoctorMainTabs';
import PatientMainTabs from './pages/patient/PatientMainTabs';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './site.css';

/* Theme variables */
import './theme/variables.css';
import CMSPage from './pages/CMSPage';

const isLIVE = false;

if (isLIVE || window.location.hostname.toLowerCase().indexOf('gogpplus.com') > -1) {
  // LIVE CREDENTIALS
  (window as any).firebaseConfig = {
    apiKey: "AIzaSyCaP80HO3aIbwacaxH9K5sBjnGhmCTDGno",
    authDomain: "gogp-9aeb3.firebaseapp.com",
    databaseURL: "https://gogp-9aeb3.firebaseio.com",
    projectId: "gogp-9aeb3",
    storageBucket: "gogp-9aeb3.appspot.com",
    messagingSenderId: "174221567631",
    appId: "1:174221567631:web:e9120f54a6cccc8260b2fa",
    isLive: true
  };
  firebase.initializeApp((window as any).firebaseConfig);
  
  // Ensure this is a production build (uat / prod sites)
  if (process.env.NODE_ENV === 'production' && !(window as any)._isSafari) {
    // Set up cloud messaging
    const fcm = firebase.messaging();	
    fcm.usePublicVapidKey(	
      // Project Settings => Cloud Messaging => Web Push certificates	
      "BKdSMsolO8EH3McBB1N2cY87S56watxfKyTOrBF6NJReJUVPawEq3cWar0gQvwdEmQ1VTjcesWQpDUBYti-eb3s"	
    );	
    (window as any).fcm = fcm;
  }

} else {
  // UAT CREDENTIALS
  (window as any).firebaseConfig = {
    apiKey: "AIzaSyBoEjLquyrrflkPT_DXBOpzti9n-FHgK1k",
    authDomain: "gogp-uat.firebaseapp.com",
    databaseURL: "https://gogp-uat.firebaseio.com",
    projectId: "gogp-uat",
    storageBucket: "gogp-uat.appspot.com",
    messagingSenderId: "239447951919",
    appId: "1:239447951919:web:e38e56ac965089ab1854da",
    isLive: false
  };
  firebase.initializeApp((window as any).firebaseConfig);
  
  // Ensure this is a production build (uat / prod sites)
  if (process.env.NODE_ENV === 'production' && !(window as any)._isSafari) {
    // Set up cloud messaging
    const fcm = firebase.messaging();	
    fcm.usePublicVapidKey(	
      // Project Settings => Cloud Messaging => Web Push certificates	
      "BP5KP5E-e9s9Ly4WwFjfU7Q-j0Q_TwLi1rkcyf4v2SkCG7bNNFFEmGeCxwG-Y7Itf8rOu3Jm71Swz9_xsLRV6Kc"	
    );	
    (window as any).fcm = fcm;
  }
}

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();
const funcs = firebase.functions();

(window as any).db = db;
(window as any).funcs = funcs;

const App: React.FC = () => {
  
  const [toastMessage, setToastMessage] = useState<string>('');
  
  useEffect(() => {
    // Ensure this is a production build (uat / prod sites)
    if (process.env.NODE_ENV === 'production' && !(window as any)._isSafari) {
      // Handle FCM message received
      (window as any).fcm.onMessage((payload: any) => {
        //console.log('Message received. ', payload);
        setToastMessage(payload.notification.body);
      });
    }
  }, []);

  return (
    <AppContextProvider>
      <IonApp>
        <IonReactRouter>
          <FirebaseState />
          <IonToast
            isOpen={toastMessage.length > 0}
            onDidDismiss={() => setToastMessage('')}
            message={toastMessage}
            position="top"
            color="tertiary"
            duration={1300}
            translucent
          />  
          <Switch>
            <Route path="/" component={Welcome} exact={true} />
            <Route path="/login" component={Login} />
            <Route path="/terms" component={CMSPage} exact={true} />
            <Route path="/privacy" component={CMSPage} exact={true} />
            <LoginRoute path="/patient/consultation/summary" component={PatientMainTabs} />
            <LoginRoute path="/patient/consultation/chat" component={Chat} />
            <LoginRoute path="/patient" component={PatientMainTabs} />
            <LoginRoute path="/doctor/consultation/summary" component={DoctorMainTabs} doctorOnly={true} />
            <LoginRoute path="/doctor/consultation/chat" component={Chat} doctorOnly={true} />
            <LoginRoute path="/doctor" component={DoctorMainTabs} doctorOnly={true} />
            <Redirect to="/" />
          </Switch>
        </IonReactRouter>
      </IonApp>
    </AppContextProvider>
  );
};

export default App;
