import React, { useState, useEffect, useContext } from 'react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import * as firebase from 'firebase';
import i18n from '../i18n';
import { SESSION } from '../entity/Consultation';

import User from '../entity/User';
import Doctor from '../entity/Doctor';
import Consultation from '../entity/Consultation';

import { IonPicker, IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonTextarea, IonItem, IonSelect, IonLoading, IonToast, IonAlert, IonCardSubtitle, IonText, IonActionSheet } from '@ionic/react';
import { closeOutline, chevronBackOutline, addCircleOutline, flaskOutline, sendOutline, trashOutline, copyOutline, bookOutline, clipboardOutline } from 'ionicons/icons';

import './Notes.css';

const moment = require('moment');

interface ComponentProps {
  session: SESSION
}

const Notes: React.FunctionComponent<ComponentProps> = ({session}) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [deleteNoteId, setDeleteNoteId] = useState<string>('');
  const [actionItem, setActionItem] = useState<any>();
  const [notes, setNotes] = useState<any>([]);
  const [newNote, setNewNote] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showAddNoteAlert, setShowAddNoteAlert] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const consultationObj = new Consultation();

  useEffect(() => {
    // ComponentDidMount
    // Retrieve previous notes for customer
    updateNotes();

     // Set focus to note textbox    
    setTimeout(() => {
      const inp: any = document.querySelector('#txtNote textarea');
      if (inp) inp.focus();
    }, 500);

  }, []);
  
  useEffect(() => {
    // ComponentDidUnmount
    return () => {

    };
  }, []);
  
  
  const updateNotes = () => {
    setIsBusy(true);
    
    userObj
      .getNotes(session.consultation.patientId)
      .then((notes: any) => {
        setIsBusy(false);

        const noteArray: any = [];
        notes.forEach((p: any) => {
          const note = p.data();
          noteArray.push({_id: p.id, note});
        });
        setNotes(noteArray);
      })
      .catch((err: any) => {
        setIsBusy(false);
        console.log(err.message);
      });
  };

  const addNote = () => {
    if (newNote.length > 0) {
      setShowAddNoteAlert(true);
    }
  };

  const scrollToTop = () => {
    // Scroll to bottom when new messages arrive
    const cPanel = document.getElementsByClassName("component-panel")[0];
    if (cPanel) {
      cPanel.scrollTop = 0;
    }
  }

  return (
    <>
      <IonLoading
        isOpen={isBusy}
        message={(i18n as any).t('common.please_wait')}
      />
      <IonToast
        isOpen={errorMessage.length > 0}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        position="top"
        color="tertiary"
        duration={1300}
        translucent
        keyboardClose
      />
    
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            <IonIcon icon={clipboardOutline} style={{marginRight: 20}} size="large" color="primary" />
            {(i18n as any).t('doctor_notes_partial.title_new_note')}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonTextarea
              id="txtNote"
              value={newNote}
              placeholder={(i18n as any).t('doctor_notes_partial.notes')}
              onIonChange={e => setNewNote(e.detail.value!)}
            >
            </IonTextarea>
            
            <IonButtons slot="end">
              <IonButton onClick={addNote}>
                <IonIcon
                  slot="icon-only"
                  size="large"
                  color="primary"
                  title={(i18n as any).t('doctor_notes_partial.title_add_note')}
                  icon={sendOutline}
                  style={{cursor: 'pointer'}} />
              </IonButton>
            </IonButtons>
          </IonItem>
        </IonCardContent>
      </IonCard>
      
       <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {(i18n as any).t('doctor_notes_partial.title_physician_notes')}
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
      
      {notes && notes.map((item: any) => (
        <IonCard key={item._id} onClick={() => setActionItem(item)} style={{cursor: 'pointer'}}>
          <IonCardHeader>
            <IonCardSubtitle>
              {`${'\n'}${moment
                .utc(
                  item.note.dateAdded.toDate()
                )
                .local()
                .format('DD/MM/YYYY HH:mm')} (${moment(item.note.dateAdded.toDate()).fromNow()})`}
            </IonCardSubtitle>
            <IonCardTitle>
              {(i18n as any).t(
                'doctor_notes_partial.title_doctor_name',
                {doctorName: item.note.doctorName}
              )}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonText color="primary">
                {item.note.note}
              </IonText>
            </IonItem>
          </IonCardContent>
        </IonCard>
      ))}
      {notes && notes.length === 0 && (
        <IonCard>
          <IonCardContent>
            <IonText color="primary">
              {(i18n as any).t('doctor_notes_partial.title_no_notes_found')}
            </IonText>
          </IonCardContent>
        </IonCard>
      )}
      
      <IonActionSheet
        isOpen={actionItem && session.consultation._id === actionItem.note.consultation}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.delete'),
          role: 'destructive',
          icon: trashOutline,
          handler: () => {
            setDeleteNoteId(actionItem._id);
          }
        }, {
          text: (i18n as any).t('common.duplicate'),
          icon: copyOutline,
          handler: () => {
            setNewNote(actionItem.note.note);
            scrollToTop();
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            // Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonActionSheet
        isOpen={actionItem && session.consultation._id !== actionItem.note.consultation}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.duplicate'),
          icon: copyOutline,
          handler: () => {
            setNewNote(actionItem.note.note);
            scrollToTop();
          }
        }, {
          text: (i18n as any).t('doctor_notes_partial.option_consultation_summary'),
          icon: bookOutline,
          handler: () => {
            // Transfer to consultation summary
            history.push('/doctor/consultation/summary', {
              session
            });
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            //Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonAlert
        isOpen={showAddNoteAlert}
        onDidDismiss={() => {
          setShowAddNoteAlert(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('doctor_notes_partial.add_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              const note = {
                consultation: session.consultation._id,
                doctorName: session.consultation.doctorName,
                note: newNote
              };

              doctorObj
                .addNote(session.consultation.patientId, note)
                .then(() => {
                  // Update notes
                  updateNotes();

                  setNewNote('');
                });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={deleteNoteId.length > 0}
        onDidDismiss={() => {
          setDeleteNoteId('');
        }}
        header={(i18n as any).t('doctor_notes_partial.title_delete_note')}
        message={(i18n as any).t('doctor_notes_partial.delete_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              // Delete note
              userObj
                .deleteNote(session.consultation.patientId, deleteNoteId)
                .then(() => {
                  // Refresh note list
                  updateNotes();
                });
              setDeleteNoteId('');
              scrollToTop();
            }
          }
        ]}
      />
      
    </>
  );
};

export default Notes;
