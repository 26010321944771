import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge, IonPage, IonRouterOutlet } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { homeOutline, fingerPrintOutline, medkitOutline, walletOutline } from 'ionicons/icons';
import { useHistory, Route, Redirect } from "react-router-dom";
import { AppContext } from '../../State';
import i18n from '../../i18n';

import Home from './Home';
import FirstStep from '../FirstStep';
import Profile from '../Profile';
import History from '../History';
import Account from '../Account';
import CMSPage from '../CMSPage';
import Summary from '../Summary';
import Chat from '../Chat';
import ConsultationRequestDetail from './ConsultationRequestDetail';
import Deposit from '../deposit/Deposit';
import DepositCreditCard from '../deposit/DepositCreditCard';
import DepositUganda from '../deposit/DepositUganda';
import DepositCongo from '../deposit/DepositCongo';
import DepositTanzania from '../deposit/DepositTanzania';
import Withdrawal from '../withdrawal/Withdrawal';
import WithdrawalUganda from '../withdrawal/WithdrawalUganda';
import WithdrawalCongo from '../withdrawal/WithdrawalCongo';
import WithdrawalTanzania from '../withdrawal/WithdrawalTanzania';
import WithdrawalPayPal from '../withdrawal/WithdrawalPayPal';

const DoctorMainTabs: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
 
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/doctor/home" component={Home} exact={true} />
        <Route path="/doctor/firststep" component={FirstStep} exact={true} />
        <Route path="/doctor/profile" component={Profile} exact={true} />
        <Route path="/doctor/history" component={History} exact={true} />
        <Route path="/doctor/account" component={Account} exact={true} />
        <Route path="/doctor/deposit" component={Deposit} exact={true} />
        <Route path="/doctor/deposit/creditcard" component={DepositCreditCard} exact={true} />
        <Route path="/doctor/deposit/uganda" component={DepositUganda} exact={true} />
        <Route path="/doctor/deposit/congo" component={DepositCongo} exact={true} />
        <Route path="/doctor/deposit/tanzania" component={DepositTanzania} exact={true} />
        <Route path="/doctor/withdrawal" component={Withdrawal} exact={true} />
        <Route path="/doctor/withdrawal/uganda" component={WithdrawalUganda} exact={true} />
        <Route path="/doctor/withdrawal/congo" component={WithdrawalCongo} exact={true} />
        <Route path="/doctor/withdrawal/tanzania" component={WithdrawalTanzania} exact={true} />
        <Route path="/doctor/withdrawal/paypal" component={WithdrawalPayPal} exact={true} />
        <Route path="/doctor/code" component={CMSPage} exact={true} />
        <Route path="/doctor/terms" component={CMSPage} exact={true} />
        <Route path="/doctor/privacy" component={CMSPage} exact={true} />
        <Route path="/doctor/request/detail" component={ConsultationRequestDetail} exact={true} />
        <Route path="/doctor/consultation/summary" component={Summary} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/doctor/home">
          <IonIcon icon={homeOutline} />
          <IonLabel>{(i18n as any).t('navigation.home')}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="profile" href="/doctor/profile">
          <IonIcon icon={fingerPrintOutline} />
          <IonLabel>{(i18n as any).t('navigation.profile')}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="history" href="/doctor/history">
          <IonIcon icon={medkitOutline} />
          <IonLabel>{(i18n as any).t('navigation.history')}</IonLabel>
        </IonTabButton>

        <IonTabButton tab="account" href="/doctor/account">
          <IonIcon icon={walletOutline} />
          <IonLabel>{(i18n as any).t('navigation.account')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default DoctorMainTabs;
