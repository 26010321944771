import React, { useContext, useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonText, IonCol, IonRow, IonGrid, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonLoading } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../State';
import { useHistory } from "react-router-dom";
import i18n from '../i18n';

import Header from '../components/Header';
import Ledger from '../entity/Ledger';

import './Account.css';

const moment = require('moment');

const Account: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  const [patientDoctor, setPatientDoctor] = useState<string>('patient');
  const [ledger, setLedger] = useState(new Ledger());
  const [ledgerUnsubscribe, setLedgerUnsubscribe] = useState<any>();
  const [isBusy, setIsBusy] = useState(false);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setPatientDoctor(state.isDoctor ? 'doctor' : 'patient');

    //  Retrieve ledger items
    setIsBusy(true);
    
    // Take a copy of existing transactions
    const trans: any = Array.from(transactions);
    
    const unsubscribe = ledger.ref
      .orderBy('timestamp', 'desc')
      .limit(30)
      .onSnapshot((snapshot: any) => {
        // Ensure the collection is not empty
        if (snapshot && snapshot.docChanges().length > 0) {
          snapshot.docChanges().forEach((change: any) => {
            const entry = change.doc;
            const ledgerItem = parse(entry);

            if (change.type === 'added') {
              /*
                Request added
              */
              trans.push(ledgerItem);
            } else if (change.type === 'modified') {
              /*
                Request changed
              */
              trans.forEach((t: any, i: number) => {
                if (t._id === ledgerItem._id) {
                  trans[i] = ledgerItem;
                }
              });
            } else if (change.type === 'removed') {
              /*
                Request removed
              */
              let foundIdx = -1;
              trans.forEach((t: any, i: number) => {
                if (t._id === ledgerItem._id) {
                  foundIdx = i;
                }
              });

              if (foundIdx > -1) {
                trans.splice(foundIdx, 1);
              }
            }

            setTransactions(trans);
          });
        }
        
        setIsBusy(false);
        
        /*
        snapshot.docs.forEach((entry) => {
          const ledgerItem = this.parse(entry);
          const { transactions: t } = this.state;
          t.push(ledgerItem);
          this.setState({ transactions: t });
        });
        */
      }, (err: any) => {
        setIsBusy(false);
        console.log(err);
      });

    // Store listener subscription
    // NOTE: Setter side effect
    // If we store the unsubscribe function directly
    // it will be invoked by the setter and will automatically
    // unsubscribe and onSnapshot will not be called!!!
    setLedgerUnsubscribe({ref: unsubscribe});
    
  },[]);
  
  useEffect(() => {
    /*********************************************
    * ComponentDidUnMount
    *********************************************/
    return () => {
      console.log("Unsubscribing from transaction listener");
      //@ts-ignore
      if (ledgerUnsubscribe && ledgerUnsubscribe.ref) {
        ledgerUnsubscribe.ref();
      }
    }
  },[ledgerUnsubscribe]);

  const parse = (snapshot: any) => {
    const {
      timestamp: numberStamp,
      narrative,
      amount,
      balance
    } = snapshot.data();
    const {id: _id} = snapshot;

    const timestamp = new Date(numberStamp.toDate());

    const ledgerItem = {
      _id,
      narrative,
      //  reference,
      amount: ledger.formatNumber(amount),
      balance: ledger.formatNumber(balance),
      timestamp,
      createdAt: moment(timestamp).format('YYYY-MM-DD HH:mm')
    };

    return ledgerItem;
  };
  
  const gotoAccountDeposit = () => {
    history.push(`/${patientDoctor}/deposit`);
  }
  
  const gotoAccountWithdrawal = () => {
    history.push(`/${patientDoctor}/withdrawal`);
  }
  
  // Order transactions by timestamp
  const transactionsSorted : any = transactions.sort(
    (a: any, b: any) => b.timestamp - a.timestamp
  );

  // Retrieve account balance
  const accountBalance : number = (transactionsSorted.length > 0) ? (transactionsSorted[0] as any).balance : 0;

  return (
    <IonPage>
      <Header showBack={false} />
      <IonContent>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">
                <div style={{textAlign: 'center', marginBottom: 20}}>
                  <h1>{(i18n as any).t('account.my_account')}</h1>
                  <IonButton onClick={gotoAccountDeposit}>
                    {(i18n as any).t('account.button_top_up_account')}
                  </IonButton>
                  <IonButton onClick={gotoAccountWithdrawal}>
                    {(i18n as any).t('account.button_withdraw_funds')}
                  </IonButton>
                </div>
                
                 {transactionsSorted.length > 0 && (
                    <div>
                      <IonList>
                        <IonListHeader lines="full" color="light">
                          <IonLabel>Detail</IonLabel>
                          <IonLabel style={{textAlign:'right'}}>Amount</IonLabel>
                          <IonLabel style={{textAlign:'right', marginRight: 20}}>Balance</IonLabel>
                        </IonListHeader>
                        {transactionsSorted.map((item:any) => (
                          <IonItem key={item._id}>
                            <IonLabel>
                              {item.narrative}
                              <IonText color="primary"><h5 className="">{item.createdAt}</h5></IonText>
                            </IonLabel>
                            <IonLabel slot="end" style={{textAlign:'right'}}>{item.amount}</IonLabel>
                            <IonLabel slot="end" style={{textAlign:'right'}}>{item.balance}</IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    </div>
                )}
                
                {!isBusy && transactions.length === 0 && (
                  <IonCard>
                    <IonCardHeader>
                      <IonCardSubtitle>No Transactions Found</IonCardSubtitle>
                    </IonCardHeader>
                  </IonCard>
                )}
                
                          
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Account;
