import * as firebase from 'firebase';
import Doctor from './Doctor';

const moment = require('moment');

// const stripeKey = 'pk_test_EVDCygbxqeqqrROLtjScaAU900GadFREB7';
const stripeKey = 'pk_live_lfIM72OHkU5REIYmuRvFF2zf00Ypc8UUNo';

class User {
  private db: any;
  private funcs: any;
  private DoctorObj: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
    this.DoctorObj = new Doctor();
  }

  getCurrent = () => {
    const {currentUser} = firebase.auth();
    return currentUser;
  };

  get getCurrentUID() : string {
    const current = this.getCurrent();
    return (current && current.uid) ? current.uid : '';
  }

  get getCurrentEmail() : string {
    const current = this.getCurrent();
    return (current && current.email) ? current.email : '';
  }

  // Retrieves the profile of a user
  // Returns a promise
  getProfile = (userId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .get()
        .then((profile: any) => {
          if (!profile.exists) {
            resolve({});
          } else {
            resolve(profile.data());
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  // Retrieves the profile of the currently logged in user
  // Returns a promise
  getCurrentProfile = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(this.getCurrentUID)
        .get()
        .then((profile: any) => {
          if (!profile.exists) {
            resolve(null);
          } else {
            resolve(profile.data());
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  getAge = (profile: any) => {
    return moment().diff(profile.dob, 'years');
  };

  // Retrieves a users initials
  getInitials = (profile: any) => {
    let initials = '';

    if (profile.firstName && profile.firstName.length > 0)
      initials = profile.firstName.substring(0, 1);

    if (profile.lastName && profile.lastName.length > 0)
      initials = `${initials}${profile.lastName.substring(0, 1)}`;

    if (profile.nickName && profile.nickName.length > 0)
      initials = profile.nickName.substring(0, 2);

    if (initials.length === 0) initials = 'AN';

    return initials.toUpperCase();
  };

  getPrescriptions = (userId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('prescriptions')
        .orderBy('datePrescribed', 'desc')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot.docs);
        })
        .catch((err: any) => reject(err));
    });
  };

  parsePrescription = (p: any) => {
    const prescription = p.data();
    const {id: _id} = p;

    const prescriptionItem = {
      _id,
      ...prescription
    };

    return prescriptionItem;
  };

  deletePrescription = (userId: string, prescriptionId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('prescriptions')
        .doc(prescriptionId)
        .delete()
        .then(() => {
          resolve({});
        })
        .catch((err: any) => reject(err));
    });
  };

  getLabTests = (userId: any) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('lab_tests')
        .orderBy('dateCreated', 'desc')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot.docs);
        })
        .catch((err: any) => reject(err));
    });
  };

  parseLabTest = (p: any) => {
    const labTest = p.data();
    const {id: _id} = p;

    const labTestItem = {
      _id,
      ...labTest
    };

    return labTestItem;
  };

  deleteLabTest = (userId: string, labTestId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('lab_tests')
        .doc(labTestId)
        .delete()
        .then(() => {
          resolve({});
        })
        .catch((err: any) => reject(err));
    });
  };

  getNotes = (userId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('doctor_notes')
        .orderBy('dateAdded', 'desc')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot.docs);
        })
        .catch((err: any) => reject(err));
    });
  };

  parseNote = (p: any) => {
    const note = p.data();
    const {id: _id} = p;

    const noteItem = {
      _id,
      ...note
    };

    return noteItem;
  };

  deleteNote = (userId: string, noteId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('doctor_notes')
        .doc(noteId)
        .delete()
        .then(() => {
          resolve({});
        })
        .catch((err: any) => reject(err));
    });
  };

  getImages = (userId: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(userId)
        .collection('images')
        .orderBy('timestamp', 'desc')
        .limit(100)
        .get()
        .then((snapshot: any) => {
          resolve(snapshot.docs);
        })
        .catch((err: any) => reject(err));
    });
  };

  parseImage = (i: any) => {
    const image = i.data();
    const {id: _id} = i;

    const imageItem = {
      _id,
      ...image
    };

    return imageItem;
  };

  getCreditCards = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('patients')
        .doc(this.getCurrentUID)
        .collection('cards')
        .orderBy('dateCreated', 'desc')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot.docs);
        })
        .catch((err: any) => reject(err));
    });
  };

  parseCreditCard = (c: any) => {
    const card = c.data();
    const {id: _id} = c;

    const cardItem = {
      _id,
      ...card
    };

    return cardItem;
  };

  removeCreditCard = (cardId: string) => {
    return new Promise((resolve, reject) => {
      // Removes registered card
      const removeCard = this.funcs.httpsCallable('removeCard');
      removeCard({cardId})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  generateCardToken = (cardNumber: string, expMonth: number, expYear: number, cvc: number) => {
    const cardDetails:any = {
      'card[number]': cardNumber.replace(/ /g, ''), // '4242424242424242',
      'card[exp_month]': expMonth, // 12,
      'card[exp_year]': expYear, // 2020,
      'card[cvc]': cvc // '123'
    };

    let formBody: any = [];
    Object.keys(cardDetails).forEach((property) => {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(cardDetails[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join('&');

    return fetch('https://api.stripe.com/v1/tokens', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${stripeKey}`
      },
      body: formBody
    }).then((response: any) => {
      return response.json();
    });
  };

  registerCard = (token: string) => {
    return new Promise((resolve, reject) => {
      // Register a new card against customer
      const registerCard = this.funcs.httpsCallable('registerCard');
      registerCard({token})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  chargeCard = (cardId: string, credits: number) => {
    return new Promise((resolve, reject) => {
      // Charge an already registered card
      const chargeCard = this.funcs.httpsCallable('chargeCard');
      chargeCard({cardId, credits})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Sets current profile
  setCurrentProfile = (profile: any) => {
    // return firebase.database().ref(`users/${this.getCurrentUID}`).update(profile);
    return this.db
      .collection('patients')
      .doc(this.getCurrentUID)
      .set(profile, {merge: true});
  };

  // Sets current profile field
  setCurrentProfileField = (fieldName: string, fieldValue: string) => {
    const field: any = {};
    field[fieldName] = fieldValue;

    return this.db
      .collection('patients')
      .doc(this.getCurrentUID)
      .set(field, {merge: true});
  };

  // Retrieve authenticated user details (email etc)
  getAuthenticatedUser = () => {
    return new Promise((resolve, reject) => {
      // Retrieve authenticated user details
      const getUser = this.funcs.httpsCallable('getCurrentUser');
      getUser()
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Sets a users last activity
  setLastSeenDate = () => {
    return this.db
      .collection('patients')
      .doc(this.getCurrentUID)
      .set(
        {
          lastSeenDate: firebase.firestore.FieldValue.serverTimestamp()
        },
        {merge: true}
      );
  };

  setLocaleInfo = () => {
    // Retrieve current language from browser
    const language = (window as any).navigator.userLanguage || (window as any).navigator.language;
    const languageCode = language.split('-')[0];

    return this.db
      .collection('patients')
      .doc(this.getCurrentUID)
      .set(
        {
          localeLanguage: languageCode
        },
        {merge: true}
      );
  };

  checkIfApprovedDoctor = () => {
    return new Promise((resolve, reject) => {
      this.DoctorObj.getProfile(this.getCurrentUID).then((profile: any) => {
        let isDoctor = false;

        if (profile) {
          isDoctor = profile.approved;
        }

        resolve(isDoctor);
      })
      .catch((err: any) => {
        reject(err);
      });
    });
  };

  sendCMSEmailToLoggedInUser = (subject: string, assetName: string, substitutions: any) => {
    return this.sendCMSEmail(
      this.getCurrentEmail,
      subject,
      assetName,
      substitutions
    );
  };

  sendCMSEmail = (toEmail: string, subject: string, assetName: string, substitutions: any) => {
    return new Promise((resolve, reject) => {
      const sendEmail = this.funcs.httpsCallable('sendCMSEmail');
      sendEmail({
        toEmail,
        subject,
        assetName,
        substitutions
      })
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  sendDeskfinityEmail = (subject: string, assetName: string, substitutions: any) => {
    return new Promise((resolve, reject) => {
      this.getCurrentProfile().then((profile: any) => {
        // Send email to Desfinity on behalf of user
        const sendEmail = this.funcs.httpsCallable('sendDeskfinityEmail');

        sendEmail({
          fromName: `${profile.firstName} ${profile.lastName}`,
          fromEmail: this.getCurrentEmail,
          subject,
          assetName,
          substitutions
        })
          .then((result: any) => {
            resolve(result.data);
          })
          .catch((httpsError: any) => {
            reject(httpsError);
          });
      });
    });
  };

  // Verifies whether a MSISDN is valid
  verifyMSISDN = (MSISDN: string) => {
    return new Promise((resolve, reject) => {
      // Verify MSISDN
      if (MSISDN.length > 0) {
        const verifyMSISDN = this.funcs.httpsCallable('verifyMSISDN');
        verifyMSISDN({MSISDN})
          .then((result: any) => {
            resolve(result.data);
          })
          .catch((httpsError: any) => {
            reject(httpsError);
          });
      } else {
        resolve({status: 0});
      }
    });
  };

  // Determines if the supplied nickname is unique
  isNicknameUnique = (nickName: string) => {
    return new Promise((resolve, reject) => {
      // Check nick name
      const isNicknameUnique = this.funcs.httpsCallable('isNicknameUnique');

      isNicknameUnique({nickName})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  charityRank = (totalCreditsDonated: number) => {
    let rank = 0;
    let creditsDonated = totalCreditsDonated;
    if (!creditsDonated) creditsDonated = 0;

    if (creditsDonated > 0) {
      rank = 1;
    }
    if (creditsDonated >= 10) {
      rank = 2;
    }
    if (creditsDonated >= 20) {
      rank = 3;
    }
    if (creditsDonated >= 40) {
      rank = 4;
    }
    if (creditsDonated >= 60) {
      rank = 5;
    }
    if (creditsDonated >= 80) {
      rank = 6;
    }
    if (creditsDonated >= 100) {
      rank = 7;
    }
    if (creditsDonated >= 150) {
      rank = 8;
    }
    if (creditsDonated >= 200) {
      rank = 9;
    }
    if (creditsDonated >= 300) {
      rank = 10;
    }
    if (creditsDonated >= 500) {
      rank = 11;
    }

    return rank;
  };

  donationsUntilNextRank = (totalCreditsDonated: number) => {
    let creditsToGo = 0;
    let creditsDonated = totalCreditsDonated;
    if (!creditsDonated) creditsDonated = 0;

    if (creditsDonated >= 500) {
      creditsToGo = 0;
    } else if (creditsDonated >= 300) {
      creditsToGo = 500 - creditsDonated;
    } else if (creditsDonated >= 200) {
      creditsToGo = 300 - creditsDonated;
    } else if (creditsDonated >= 150) {
      creditsToGo = 200 - creditsDonated;
    } else if (creditsDonated >= 100) {
      creditsToGo = 150 - creditsDonated;
    } else if (creditsDonated >= 80) {
      creditsToGo = 100 - creditsDonated;
    } else if (creditsDonated >= 60) {
      creditsToGo = 80 - creditsDonated;
    } else if (creditsDonated >= 40) {
      creditsToGo = 60 - creditsDonated;
    } else if (creditsDonated >= 20) {
      creditsToGo = 40 - creditsDonated;
    } else if (creditsDonated >= 10) {
      creditsToGo = 20 - creditsDonated;
    } else if (creditsDonated > 0) {
      creditsToGo = 10 - creditsDonated;
    } else {
      creditsToGo = 1;
    }

    return creditsToGo;
  };

  validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
}

export default User;
