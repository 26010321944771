import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonLoading, IonToast, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonBadge, IonAvatar, IonGrid, IonRow, IonCol, IonText, IonRippleEffect, IonFooter, IonToolbar, IonSegment, IonSegmentButton, IonIcon, IonLabel, IonCardSubtitle, IonThumbnail, IonHeader } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import i18n from '../i18n';

import Consultation, { SESSION } from '../entity/Consultation';
import User from '../entity/User';
import Doctor from '../entity/Doctor';
import FireChat from '../entity/FireChat';

import Header from '../components/Header';

import './Summary.css';
import { chatboxOutline, flaskOutline, thermometerOutline, clipboardOutline, imagesOutline } from 'ionicons/icons';

const moment = require('moment');

const Summary: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const consultationObj = new Consultation();
  const fireChatObj = new FireChat(consultationObj);
  
  const [session, setSession] = useState<any>();

  const [messages, setMessages] = useState<any>();
  const [prescriptions, setPrescriptions] = useState<any>();
  const [labTests, setLabTests] = useState<any>();
  const [notes, setNotes] = useState<any>();
  const [photos, setPhotos] = useState<any>();
  const [section, setSection] = useState<string>('chat');
  
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  useEffect(() => {
    const sess = location.state.session;
    
    if (sess) {
      setSession(sess);
  
      setIsBusy(true);
      
      /*
        Retrieve consultation messages
      */
      (window as any).db
        .collection('consultations')
        .doc(sess.consultation._id)
        .collection('messages')
        .orderBy('timestamp')
        .get()
        .then((snapshot: any) => {
          const msgs: any = [];
          const pho: any = [];
          snapshot.docs.forEach((m: any) => {
            const msg = fireChatObj.parse(m); 
            msgs.push(msg);
  
            if (msg.type === 'i') {
              pho.push(msg.image);
            }
          });
          setMessages(msgs);
          setPhotos(pho);
        })
        .catch((err: any) => {
          setIsBusy(false);
          console.log(err.message);
        });
  
      /*
        Retrieve consultation prescriptions
      */
      (window as any).db
        .collection('patients')
        .doc(sess.consultation.patientId)
        .collection('prescriptions')
        .where('consultation', '==', sess.consultation._id)
        .orderBy('datePrescribed', 'desc')
        .get()
        .then((snapshot: any) => {
          const prescriptions: any = [];
          snapshot.docs.forEach((p: any) => {
            prescriptions.push(userObj.parsePrescription(p));
          });
          setPrescriptions(prescriptions);
        })
        .catch((err: any) => {
          setIsBusy(false);
          console.log(err.message);
        });
  
      /*
        Retrieve consultation lab tests
      */
      (window as any).db
        .collection('patients')
        .doc(sess.consultation.patientId)
        .collection('lab_tests')
        .where('consultation', '==', sess.consultation._id)
        .orderBy('dateCreated', 'desc')
        .get()
        .then((snapshot: any) => {
          setIsBusy(false);
  
          const labTests: any = [];
          snapshot.docs.forEach((t: any) => {
            labTests.push(userObj.parseLabTest(t));
          });
          setLabTests(labTests);
          
        })
        .catch((err: any) => {
          setIsBusy(false);
          console.log(err.message);
        });
        
      /*
        Retrieve physician notes
      */
      if (state.isDoctor) {
        (window as any).db
          .collection('patients')
          .doc(sess.consultation.patientId)
          .collection('doctor_notes')
          .where('consultation', '==', sess.consultation._id)
          .orderBy('dateCreated', 'desc')
          .get()
          .then((snapshot: any) => {
            setIsBusy(false);
            
            const noteItems: any = [];
            snapshot.docs.forEach((n: any) => {
              noteItems.push(userObj.parseNote(n));
            });
            setNotes(noteItems);
          })
          .catch((err: any) => {
            setIsBusy(false);
            console.log(err.message);
          });
      }
    } 
  }, [location.state]);
  
  if (!session) {
    return null;
  }
  
  return (
    <IonPage>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        <IonToast
          isOpen={toastMessage.length > 0}
          onDidDismiss={() => setToastMessage('')}
          message={toastMessage}
          position="top"
          color="tertiary"
          duration={1300}
          translucent
          keyboardClose
        />   
        
      <Header showBack={true} />
      <IonHeader>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
              {(i18n as any).t('patient_consultation_summary.title_consultation_summary')}
            </IonCardSubtitle>
            <IonCardSubtitle>
              {`${moment
                .utc(session.consultation.consultationDate.toDate())
                .local()
                .format('DD/MM/YYYY HH:mm')}`}
            </IonCardSubtitle>
            <IonCardTitle>
              {session.consultation.doctorName}              
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>      
      </IonHeader>
      <IonContent>
        {section === 'chat' && (
          <IonGrid className="conversation-panel">
            <IonRow className="chat-panel">
              <IonCol>
                <div className="filler-panel"></div>
                <div className="messages-panel">
                  {messages && messages.map((msg:any) => (
                    <IonItem key={msg._id}>
                      {msg.user._id === session.consultation.patientId && !msg.image && (
                        <div className="patient-bubble">
                          <IonText>{msg.text}</IonText><br/>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                      {msg.user._id === session.consultation.patientId && msg.image && (
                        <div className="patient-bubble">
                          <IonThumbnail className="chat-thumbnail">
                            <img src={msg.image} className="chat-thumbnail-img" onClick={() => {
                              window.open(msg.image, '_blank');
                            }} />
                          </IonThumbnail>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                      
                      {msg.user._id === session.consultation.doctorId && !msg.image && (
                        <div className="doctor-bubble">
                          <IonText>{msg.text}</IonText><br/>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                      {msg.user._id === session.consultation.doctorId && msg.image && (
                        <div className="doctor-bubble">
                          <IonThumbnail className="chat-thumbnail">
                            <img src={msg.image} className="chat-thumbnail-img" onClick={() => {
                              window.open(msg.image, '_blank');
                            }} />
                          </IonThumbnail>
                          <IonText color="primary" className="chat-date">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}</IonText>
                        </div>
                      )}
                    </IonItem>
                  ))}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        
        {section === 'prescriptions' && (
          <>
            {prescriptions && prescriptions.map((item: any) => (
              <IonCard key={item._id}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    {`${'\n'}${moment
                      .utc(
                        item.datePrescribed.toDate()
                      )
                      .local()
                      .format('DD/MM/YYYY HH:mm')} (${moment(item.datePrescribed.toDate()).fromNow()})`}
                  </IonCardSubtitle>
                  <IonCardTitle>
                    {(i18n as any).t(
                      'prescriptions_partial.prescribed_by',
                      {doctorName: item.doctorName}
                    )}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonText color="primary">
                      {item.prescription}
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
            {prescriptions && prescriptions.length === 0 && (
              <IonCard>
                <IonCardContent>
                  <IonText color="primary">
                    {(i18n as any).t('prescriptions_partial.title_no_prescriptions_found')}
                  </IonText>
                </IonCardContent>
              </IonCard>
            )}
          </>
        )}
        
        {section === 'labtests' && (
          <>
            {labTests && labTests.map((item: any) => (
              <IonCard key={item._id}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    {`${'\n'}${moment
                      .utc(
                        item.dateCreated.toDate()
                      )
                      .local()
                      .format('DD/MM/YYYY HH:mm')} (${moment(item.dateCreated.toDate()).fromNow()})`}
                  </IonCardSubtitle>
                  <IonCardTitle>
                    {(i18n as any).t(
                      'lab_tests_partial.requested_by',
                      {doctorName: item.doctorName}
                    )}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonText color="primary">
                      {item.tests}
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
            {labTests && labTests.length === 0 && (
              <IonCard>
                <IonCardContent>
                  <IonText color="primary">
                    {(i18n as any).t('lab_tests_partial.title_no_lab_tests_found')}
                  </IonText>
                </IonCardContent>
              </IonCard>
            )}
          </>
        )}
        
        {section === 'notes' && (
          <>
            {notes && notes.map((item: any) => (
              <IonCard key={item._id}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    {`${'\n'}${moment
                      .utc(
                        item.note.dateAdded.toDate()
                      )
                      .local()
                      .format('DD/MM/YYYY HH:mm')} (${moment(item.note.dateAdded.toDate()).fromNow()})`}
                  </IonCardSubtitle>
                  <IonCardTitle>
                    {(i18n as any).t(
                      'doctor_notes_partial.title_doctor_name',
                      {doctorName: item.note.doctorName}
                    )}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonText color="primary">
                      {item.note.note}
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
            {notes && notes.length === 0 && (
              <IonCard>
                <IonCardContent>
                  <IonText color="primary">
                    {(i18n as any).t('doctor_notes_partial.title_no_notes_found')}
                  </IonText>
                </IonCardContent>
              </IonCard>
            )}
          </>    
        )}
        
        {section === 'photos' && (
          <>
            {photos && photos.map((item: any) => (
              <IonCard key={item}>
                <IonCardContent>
                  <IonItem>
                    <img
                      src={item}
                      className="photos-thumbnail"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        window.open(item, '_blank');
                      }} />
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
            {photos && photos.length === 0 && (
              <IonCard>
                <IonCardContent>
                  <IonText color="primary">
                    {(i18n as any).t('patient_images_partial.title_no_images_found')}
                  </IonText>
                </IonCardContent>
              </IonCard>
            )}
          </>
        )}
      </IonContent>
      
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonSegment value={section} onIonChange={(e) => {
            const sect: string = e.detail.value as string;
            setSection(sect);
          }}>
            <IonSegmentButton value="chat">
              <IonIcon icon={chatboxOutline} />
              <IonLabel className="hidden-sm-down">
                {(i18n as any).t('chat.tab_chat')}
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="prescriptions">
              <IonIcon icon={flaskOutline} />
              <IonLabel className="hidden-sm-down">
                {(i18n as any).t('chat.tab_prescriptions')}
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="labtests">
              <IonIcon icon={thermometerOutline} />
              <IonLabel className="hidden-sm-down">
                {(i18n as any).t('chat.tab_lab_tests')}
              </IonLabel>
            </IonSegmentButton>
            {state.isDoctor && (
              <IonSegmentButton value="notes">
                <IonIcon icon={clipboardOutline} />
                <IonLabel className="hidden-sm-down">
                  {(i18n as any).t('chat.tab_notes')}
                </IonLabel>
              </IonSegmentButton>
            )}
            <IonSegmentButton value="photos">
              <IonIcon icon={imagesOutline} />
              <IonLabel className="hidden-sm-down">
                {(i18n as any).t('chat.tab_photos')}
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Summary;
