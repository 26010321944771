import * as firebase from 'firebase';
import User from './User';

const moment = require('moment');

class Doctor {
  private db: any;
  private funcs: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
  }

  getProfile = (doctorId: string) => {
    return new Promise((resolve, reject) => {
      // Retrieve doctor profile (if it exists)
      /*
      firebase.database().ref(`doctors/${doctorId}`)
        .once('value', (profile) => {
          resolve(profile.val());
        })
        .catch((error) => {
          reject(error);
        });
      */
      this.db
        .collection('doctors')
        .doc(doctorId)
        .get()
        .then((profile: any) => {
          if (!profile.exists) {
            resolve({});
          } else {
            resolve({_id: profile.id, ...profile.data()});
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  // Sets a doctors last activity
  setLastSeenDate = () => {
    const setDoctorLastSeenDate = this.funcs.httpsCallable('setDoctorLastSeenDate');
    setDoctorLastSeenDate();
  };

  // Adds a prescription for a patient
  addPrescription = (patientId: string, prescription: any) => {
    const p = prescription;
    p.datePrescribed = firebase.firestore.FieldValue.serverTimestamp();

    return this.db
      .collection('patients')
      .doc(patientId)
      .collection('prescriptions')
      .add(p);
  };

  // Adds a lab test for a patient
  addLabTest = (patientId: string, tests: any) => {
    const t = tests;
    t.dateCreated = firebase.firestore.FieldValue.serverTimestamp();

    return this.db
      .collection('patients')
      .doc(patientId)
      .collection('lab_tests')
      .add(t);
  };

  // Adds a physician note to a patient
  addNote = (patientId: string, note: any) => {
    const n = note;
    n.dateAdded = firebase.firestore.FieldValue.serverTimestamp();

    return this.db
      .collection('patients')
      .doc(patientId)
      .collection('doctor_notes')
      .add(n);
  };

  getAvailableDoctorsForSpecialty = (specialty: any) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('doctors')
        .where('specialities', 'array-contains', specialty)
        .get()
        .then((snapshot: any) => {
          let count = 0;
          if (snapshot) {
            snapshot.docs.forEach((doc: any) => {
              const doctor = doc.data();
              // Ensure we've seen the doctor in the last 30 minutes
              if (doctor.lastSeenDate) {
                const duration = moment.duration(
                  moment().diff(doctor.lastSeenDate)
                );
                const minutes = duration.asMinutes();

                if (doctor.approved && doctor.available && minutes <= 30) {
                  count += 1;
                }
              }
            });
          }
          resolve(count);
        })
        .catch((err: any) => reject(err));
    });
  };

  setAvailability = (available: boolean) => {
    return new Promise((resolve, reject) => {
      const setDoctorAvailability = this.funcs.httpsCallable('setDoctorAvailability');

      setDoctorAvailability({available})
        .then(() => resolve())
        .catch((err: any) => reject(err));
    });
  };
  
  registerForDoctorTopic = (fcmToken: string) => {
    return new Promise((resolve, reject) => {
      const regDoctorTopic = this.funcs.httpsCallable('registerForDoctorTopic');
      
      regDoctorTopic({token: fcmToken})
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err));
    });
  };
}

export default Doctor;
