import i18n from 'i18n-js';
//import * as RNLocalize from 'react-native-localize';

import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import sw from './locales/sw.json';

class i18nEx{
  i18nEx = () => {
    // Retrieve current language from browser
    const language = window.navigator.userLanguage || window.navigator.language;
    let languageCode = language.split('-')[0];

    i18n.defaultLocale = 'en';
    i18n.fallbacks = true;
    i18n.translations = {en, es, fr, sw};

    // Retrieve stored preferred language (set from profile + updating profile)
    i18n.locale = (window.localStorage.getItem("pref_language")) ? window.localStorage.getItem("pref_language") : languageCode;
    
    // Check for new preferred language
    setInterval(() => {
      if (window.localStorage.getItem("pref_language")) {
        i18n.locale = window.localStorage.getItem("pref_language").toLowerCase();
      } 
    }, 1000);
    
    return i18n;
  }
}

const i18nExObj = (new i18nEx).i18nEx();
export default i18nExObj;