import React, { useState, useEffect, useContext } from 'react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AppContext } from '../State';
import * as firebase from 'firebase';
import i18n from '../i18n';
import { SESSION } from '../entity/Consultation';

import User from '../entity/User';
import Doctor from '../entity/Doctor';
import Consultation from '../entity/Consultation';
import FireChat from '../entity/FireChat';
import ListData from '../entity/ListData';

import { IonPicker, IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonTextarea, IonItem, IonSelect, IonLoading, IonToast, IonAlert, IonCardSubtitle, IonText, IonActionSheet } from '@ionic/react';
import { closeOutline, chevronBackOutline, addCircleOutline, flaskOutline, sendOutline, trashOutline, copyOutline, bookOutline, thermometerOutline } from 'ionicons/icons';

import './LabTests.css';

const moment = require('moment');

interface ComponentProps {
  session: SESSION
}

const LabTests: React.FunctionComponent<ComponentProps> = ({session}) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [deleteLabTestId, setDeleteLabTestId] = useState<string>('');
  const [actionItem, setActionItem] = useState<any>();
  const [testOptions, setTestOptions] = useState<any>([]);
  const [labTests, setLabTests] = useState<any>([]);
  const [newLabTest, setNewLabTest] = useState<string>('');
  const [testPickerVisible, setTestPickerVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showAddLabTestAlert, setShowAddLabTestAlert] = useState<boolean>(false);
  const [showAddToChatAlert, setShowAddToChatAlert] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const listDataObj = new ListData();
  const consultationObj = new Consultation();
  const fireChatObj = new FireChat(consultationObj);
  
  useEffect(() => {
    // ComponentDidMount
    // Check if languages are provided
    // Retrieve previous labTests for customer
    updateLabTests();

    //  Populate test list
    listDataObj.getTests().then((data: any) => {
      const testOptions: any = {
        name: 'Tests',
        options: []
      };
      data.docs.forEach((d: any) => {
        const test = d.data();
        testOptions.options.push({text: test.name, value: test.name});
      });
      setTestOptions(testOptions);
    });

    // Set focus to labTest textbox    
    setTimeout(() => {
      const inp: any = document.querySelector('#txtLabTest textarea');
      if (inp) inp.focus();
    }, 500);

  }, []);
  
  useEffect(() => {
    // ComponentDidUnmount
    return () => {

    };
  }, []);
  
  
  const updateLabTests = () => {
    setIsBusy(true);
    
    userObj
      .getLabTests(session.consultation.patientId)
      .then((labTests: any) => {
        setIsBusy(false);

        const labTestArray: any = [];
        labTests.forEach((p: any) => {
          const labTest = p.data();
          labTestArray.push({_id: p.id, labTest});
        });
        setLabTests(labTestArray);
      })
      .catch((err: any) => {
        setIsBusy(false);
        console.log(err.message);
      });
  };

  const addLabTest = () => {
    if (newLabTest.length > 0) {
      setShowAddLabTestAlert(true);
    }
  };

  const onTestSelect = (picked: any) => {
    setNewLabTest(`${newLabTest}${picked} `);
    setTestPickerVisible(false);
  };

  const scrollToTop = () => {
    // Scroll to bottom when new messages arrive
    const cPanel = document.getElementsByClassName("component-panel")[0];
    if (cPanel) {
      cPanel.scrollTop = 0;
    }
  }

  return (
    <>
      <IonLoading
        isOpen={isBusy}
        message={(i18n as any).t('common.please_wait')}
      />
      <IonToast
        isOpen={errorMessage.length > 0}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        position="top"
        color="tertiary"
        duration={1300}
        translucent
        keyboardClose
      />
    
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            <IonIcon icon={thermometerOutline} style={{marginRight: 20}} size="large" color="primary" />
            {(i18n as any).t('lab_tests_partial.title_new_lab_test')}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonTextarea
              id="txtLabTest"
              value={newLabTest}
              placeholder={(i18n as any).t('lab_tests_partial.tests_detail')}
              onIonChange={e => setNewLabTest(e.detail.value!)}
            >
            </IonTextarea>
            
            <IonButtons slot="end">
              <IonButton onClick={() => setTestPickerVisible(true)}>
                <IonIcon
                  slot="icon-only"
                  size="large"
                  color="primary"
                  icon={addCircleOutline}
                  style={{cursor: 'pointer'}} />
              </IonButton>
              
              <IonButton onClick={addLabTest}>
                <IonIcon
                  slot="icon-only"
                  size="large"
                  color="primary"
                  title={(i18n as any).t('lab_tests_partial.button_finish')}
                  icon={sendOutline}
                  style={{cursor: 'pointer'}} />
              </IonButton>
            </IonButtons>
          </IonItem>
        </IonCardContent>
      </IonCard>
      
       <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {(i18n as any).t('lab_tests_partial.title_previous_lab_tests')}
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
      
      {labTests && labTests.map((item: any) => (
        <IonCard key={item._id} onClick={() => setActionItem(item)} style={{cursor: 'pointer'}}>
          <IonCardHeader>
            <IonCardSubtitle>
              {`${'\n'}${moment
                .utc(
                  item.labTest.dateCreated.toDate()
                )
                .local()
                .format('DD/MM/YYYY HH:mm')} (${moment(item.labTest.dateCreated.toDate()).fromNow()})`}
            </IonCardSubtitle>
            <IonCardTitle>
              {(i18n as any).t(
                'lab_tests_partial.requested_by',
                {doctorName: item.labTest.doctorName}
              )}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonText color="primary">
                {item.labTest.tests}
              </IonText>
            </IonItem>
          </IonCardContent>
        </IonCard>
      ))}
      {labTests && labTests.length === 0 && (
        <IonCard>
          <IonCardContent>
            <IonText color="primary">
              {(i18n as any).t('lab_tests_partial.title_no_lab_tests_found')}
            </IonText>
          </IonCardContent>
        </IonCard>
      )}
      
      <IonActionSheet
        isOpen={actionItem && session.consultation._id === actionItem.labTest.consultation}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.delete'),
          role: 'destructive',
          icon: trashOutline,
          handler: () => {
            setDeleteLabTestId(actionItem._id);
          }
        }, {
          text: (i18n as any).t('common.duplicate'),
          icon: copyOutline,
          handler: () => {
            setNewLabTest(actionItem.labTest.tests);
            scrollToTop();
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            // Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonActionSheet
        isOpen={actionItem && session.consultation._id !== actionItem.labTest.consultation}
        onDidDismiss={() => setActionItem(undefined)}
        buttons={[{
          text: (i18n as any).t('common.duplicate'),
          icon: copyOutline,
          handler: () => {
            setNewLabTest(actionItem.labTest.tests);
            scrollToTop();
          }
        }, {
          text: (i18n as any).t('lab_tests_partial.option_consultation_summary'),
          icon: bookOutline,
          handler: () => {
            // Transfer to consultation summary
            history.push('/doctor/consultation/summary', {
              session
            });
          }
        }, {
          text: (i18n as any).t('common.cancel'),
          icon: closeOutline,
          role: 'cancel',
          handler: () => {
            //Do nothing
          }
        }]}
      >
      </IonActionSheet>

      <IonAlert
        isOpen={showAddLabTestAlert}
        onDidDismiss={() => {
          setShowAddLabTestAlert(false);
        }}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('lab_tests_partial.add_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              const labTest = {
                consultation: session.consultation._id,
                doctorName: session.consultation.doctorName,
                tests: newLabTest
              };

              doctorObj
                .addLabTest(session.consultation.patientId, labTest)
                .then(() => {
                  // Update labTests
                  updateLabTests();

                  // Ask if labTest should be appended to chat
                  setShowAddToChatAlert(true);
                });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showAddToChatAlert}
        onDidDismiss={() => {
          setShowAddToChatAlert(false);
        }}
        header={(i18n as any).t('lab_tests_partial.title_add_to_chat')}
        message={(i18n as any).t('lab_tests_partial.body_add_to_chat')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              const msg = {
                text: (i18n as any).t('lab_tests_partial.lab_tests', {
                  tests: newLabTest
                }),
                type: 't',
                user: {
                  _id: state.userId
                },
                timestamp: consultationObj.timestamp
              };
  
              fireChatObj.append(session.consultation._id, msg);
              
              setNewLabTest('');
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={deleteLabTestId.length > 0}
        onDidDismiss={() => {
          setDeleteLabTestId('');
        }}
        header={(i18n as any).t('lab_tests_partial.title_delete_lab_test')}
        message={(i18n as any).t('lab_tests_partial.delete_are_you_sure')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              // Delete labTest
              userObj
                .deleteLabTest(session.consultation.patientId, deleteLabTestId)
                .then(() => {
                  // Refresh labTest list
                  updateLabTests();
                });
              setDeleteLabTestId('');
              scrollToTop();
            }
          }
        ]}
      />
      
      <IonPicker
        isOpen={testPickerVisible}
        columns={[testOptions]}
        buttons={[
         {
          text: "Cancel",
          role: "cancel",
          handler: option => {
            setTestPickerVisible(false);
          }
         },
         {
          text: "OK",
          handler: option => {
            onTestSelect(option.Tests.value);
            setTestPickerVisible(false);
          }
         }
        ]}
      />
      
    </>
  );
};

export default LabTests;
