import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge, IonPage, IonRouterOutlet } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { homeOutline, fingerPrintOutline, medkitOutline, walletOutline } from 'ionicons/icons';
import { useHistory, Route, Redirect } from "react-router-dom";
import { AppContext } from '../../State';
import i18n from '../../i18n';

import Home from './Home';
import History from '../History';
import IssueDetail from './IssueDetail';
import IssueDetailEdit from './IssueDetailEdit';
import Account from '../Account';
import Profile from '../Profile';
import Summary from '../Summary';
import FirstStep from '../FirstStep';
import CMSPage from '../CMSPage';
import LoginRoute from '../../components/LoginRoute';
import Deposit from '../deposit/Deposit';
import DepositCreditCard from '../deposit/DepositCreditCard';
import DepositUganda from '../deposit/DepositUganda';
import DepositCongo from '../deposit/DepositCongo';
import DepositTanzania from '../deposit/DepositTanzania';
import Withdrawal from '../withdrawal/Withdrawal';
import WithdrawalUganda from '../withdrawal/WithdrawalUganda';
import WithdrawalCongo from '../withdrawal/WithdrawalCongo';
import WithdrawalTanzania from '../withdrawal/WithdrawalTanzania';
import WithdrawalPayPal from '../withdrawal/WithdrawalPayPal';

const PatientMainTabs: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
 
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/patient/home" component={Home} exact={true} />
        <Route path="/patient/firststep" component={FirstStep} exact={true} />
        <Route path="/patient/profile" component={Profile} exact={true} />
        <Route path="/patient/history" component={History} exact={true} />
        <Route path="/patient/account" component={Account} exact={true} />
        <Route path="/patient/deposit" component={Deposit} exact={true} />
        <Route path="/patient/deposit/creditcard" component={DepositCreditCard} exact={true} />
        <Route path="/patient/deposit/uganda" component={DepositUganda} exact={true} />
        <Route path="/patient/deposit/congo" component={DepositCongo} exact={true} />
        <Route path="/patient/deposit/tanzania" component={DepositTanzania} exact={true} />
        <Route path="/patient/withdrawal" component={Withdrawal} exact={true} />
        <Route path="/patient/withdrawal/uganda" component={WithdrawalUganda} exact={true} />
        <Route path="/patient/withdrawal/congo" component={WithdrawalCongo} exact={true} />
        <Route path="/patient/withdrawal/tanzania" component={WithdrawalTanzania} exact={true} />
        <Route path="/patient/withdrawal/paypal" component={WithdrawalPayPal} exact={true} />
        <Route path="/patient/terms" component={CMSPage} exact={true} />
        <Route path="/patient/privacy" component={CMSPage} exact={true} />
        <Route path="/patient/consultation/summary" component={Summary} exact={true} />
        <Route path="/patient/consultation/request" component={IssueDetail} exact={true} />
        <Route path="/patient/consultation/request/edit" component={IssueDetailEdit} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/patient/home">
          <IonIcon icon={homeOutline} />
          <IonLabel>{(i18n as any).t('navigation.home')}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="profile" href="/patient/profile">
          <IonIcon icon={fingerPrintOutline} />
          <IonLabel>{(i18n as any).t('navigation.profile')}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="history" href="/patient/history">
          <IonIcon icon={medkitOutline} />
          <IonLabel>{(i18n as any).t('navigation.history')}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="account" href="/patient/account">
          <IonIcon icon={walletOutline} />
          <IonLabel>{(i18n as any).t('navigation.account')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default PatientMainTabs;
