import React, { useContext, useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonInput, IonToast, IonDatetime, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonAvatar, IonLoading, IonText, IonBadge, IonSkeletonText, IonTextarea, IonToggle, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';
import { isNull, isNullThenElse, isNullOrEmpty } from '../../Utils';

import Header from '../../components/Header';
import ConsultationRequest from '../../entity/ConsultationRequest';

import { fingerPrintOutline, happyOutline, calendarOutline, maleOutline, femaleOutline, globeOutline, languageOutline, walletOutline, checkmarkOutline, checkmarkCircle, medkitOutline, cashOutline, speedometerOutline } from 'ionicons/icons';

import './IssueDetailEdit.css';

const IssueDetailEdit: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [issue, setIssue] = useState<string>('');
  const [specialty, setSpecialty] = useState<string>('General Practitioner');
  const [consultationRequest, setConsultationRequest] = useState<any>({});
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [showCancelAlert, setShowCancelAlert] = useState<boolean>(false);
  const [alertMessageTitle, setAlertMessageTitle] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertMessageUrl, setAlertMessageUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const consultationRequestObj = new ConsultationRequest();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/

    const {currentUser} = firebase.auth();
    setCurrentUser({currentUser});

    setConsultationRequest(location.state.request);
    setSpecialty(location.state.request.specialty);
    setIssue(location.state.request.condition);
  },[]);
  
  const onPress = () => {
    if (issue.trim().length > 10) {
      const cr = consultationRequest;
      cr.condition = issue;

      setIsBusy(true);
      
      consultationRequestObj
        .updateRequest(cr)
        .then((success) => {
          setIsBusy(false);
          history.push('/patient/home');
        })
        .catch((err) => {
          setIsBusy(false);
          console.warn(err.message);
        });
    } else {
      setAlertMessageTitle((i18n as any).t('issue_detail_edit.title_description_required'));
      setAlertMessage((i18n as any).t('issue_detail_edit.supply_description_of_problem'));
    }
  };
  
  const cancelRequest = () => {
    setShowCancelAlert(true);
  };
  
  
  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">
                <IonToast
                  isOpen={errorMessage.length > 0}
                  onDidDismiss={() => setErrorMessage('')}
                  message={errorMessage}
                  position="top"
                  color="tertiary"
                  duration={1300}
                  translucent
                  keyboardClose
                />
                <IonLoading
                  isOpen={isBusy}
                  message={(i18n as any).t('common.please_wait')}
                />

                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>{(i18n as any).t('issue_detail_edit.title_doctor_requested')}</IonCardSubtitle>
                    <IonCardTitle>{specialty}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>

                <IonCard>
                  <IonCardHeader>
                    <IonText><h4>{(i18n as any).t('issue_detail_edit.enter_description_of_problem')}</h4></IonText>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonItem>
                      <IonTextarea
                        value={issue}
                        placeholder={(i18n as any).t('profile.please_supply')}
                        onIonChange={e => setIssue(e.detail.value!)}>
                      </IonTextarea>
                    </IonItem>
                  </IonCardContent>
                </IonCard>

                <IonButton onClick={onPress} size="large" expand="block">
                  {(i18n as any).t('issue_detail_edit.button_update')}
                </IonButton>
                
                <IonButton onClick={cancelRequest} size="large" expand="block">
                  {(i18n as any).t('issue_detail_edit.button_cancel_request')}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      
 
      <IonAlert
        isOpen={showCancelAlert}
        onDidDismiss={() => setShowCancelAlert(false)}
        header={(i18n as any).t('common.confirm')}
        message={(i18n as any).t('issue_detail_edit.ask_cancel_request')}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              consultationRequestObj
              .deleteRequest(consultationRequest._id)
              .then(() => {
                history.push('/patient/home');
              })
              .catch((err) => {
                console.warn(err.message);
              });
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={alertMessage.length > 0}
        onDidDismiss={() => {
          setAlertMessageTitle('');
          setAlertMessage('');
          setAlertMessageUrl('');
        }}
        header={alertMessageTitle}
        message={alertMessage}
        buttons={[
          {
            text: (i18n as any).t('common.OK'),
            handler: () => {
              if (alertMessageUrl.length > 0) {
                history.push(alertMessageUrl);
              }
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default IssueDetailEdit;
