import * as firebase from 'firebase';
import User from './User';
import i18n from '../i18n';

class Ledger {
  private db: any;
  private funcs: any;
  private UserObj: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
    this.UserObj = new User();
  }

  get ref() {
    return this.db
      .collection('patients')
      .doc(this.UserObj.getCurrentUID)
      .collection('ledger');
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  // Retrieves the last ledger entry of the logged in user
  getLastLedgerEntry = () => {
    return new Promise((resolve, reject) => {
      /*
      this.ref
        .limitToLast(1)
        .once()
        .then((ledgerItem) => {
          resolve(ledgerItem);
        })
        .catch((err) => {
          reject(err);
        });
      */
      this.db
        .collection('patients')
        .doc(this.UserObj.getCurrentUID)
        .collection('ledger')
        .orderBy('timestamp', 'desc')
        .limit(1)
        .get()
        .then((entry: any) => {
          if (entry.docs.length === 0) {
            resolve({});
          } else {
            resolve(entry.docs[0].data());
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  // Withdraw funds from account via MM
  withdrawViaMM = (amount: number) => {
    return new Promise((resolve, reject) => {
      // Request withdrawal
      const withdraw = this.funcs.httpsCallable('withdrawViaMM');
      withdraw({amount})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Withdraw funds from account via PayPal
  withdrawViaPayPal = (amount: number) => {
    return new Promise((resolve, reject) => {
      // Request withdrawal
      const withdraw = this.funcs.httpsCallable('withdrawViaPayPal');
      withdraw({amount})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Withdraw funds from account via Charity Donation
  withdrawViaCharity = (amount: number) => {
    return new Promise((resolve, reject) => {
      // Request withdrawal
      const withdraw = this.funcs.httpsCallable('withdrawViaCharity');
      withdraw({amount})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Retrieve latest exchange rate
  getExchangeRate = (srcCurrencyISO3: string, destCurrencyISO3: string) => {
    return new Promise((resolve, reject) => {
      // Retrieve rate
      const getRate = this.funcs.httpsCallable('getExchangeRate');
      getRate({srcCurrencyISO3, destCurrencyISO3})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Get fees
  getFees = (feeID: string) => {
    return new Promise((resolve, reject) => {
      // Retrieve fees
      const getFeesFunc = this.funcs.httpsCallable('getFees');
      getFeesFunc({feeID})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Get fee amount which will be charged
  calculateFeeOnAmount = (feeID: string, amount: number) => {
    return new Promise((resolve, reject) => {
      // Retrieve fee amount
      const calcFeeOnAmount = this.funcs.httpsCallable('calculateFeeOnAmount');

      calcFeeOnAmount({feeID, amount})
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((httpsError: any) => {
          reject(httpsError);
        });
    });
  };

  // Convert supplied credits amount to a destination currency
  convertCreditsTo = (credits: number, destCurrencyISO3: string) => {
    return new Promise((resolve, reject) => {
      this.getExchangeRate('GGP', destCurrencyISO3)
        .then((pair: any) => {
          if (pair && pair.rate) {
            resolve(credits * pair.rate);
          } else {
            resolve();
          }
        })
        .catch((err) => reject(err));
    });
  };

  // Convert the supplied currency amount to credits
  convertCreditsFrom = (amount: number, srcCurrencyISO3: string) => {
    return new Promise((resolve, reject) => {
      this.getExchangeRate(srcCurrencyISO3, 'GGP')
        .then((pair: any) => {
          if (pair && pair.rate) {
            resolve(amount * pair.rate);
          } else {
            resolve();
          }
        })
        .catch((err) => reject(err));
    });
  };

  formatCreditText = (credits: number) => {
    let text = (i18n as any).t('common.credits');
    if (credits === 1) {
      text = (i18n as any).t('common.credit');
    }
    return text;
  };

  formatNumber = (num: any) => {
    let formatted = parseFloat(num)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    formatted = formatted.replace('.00', '');
    return formatted;
  };

}

export default Ledger;
