import * as firebase from 'firebase';

class Settings {
  private db: any;
  private funcs: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
  }

  getSetting = (settingName: string) => {
    return new Promise((resolve, reject) => {
      /*
      firebase
        .database()
        .ref(`public/settings/${settingName}`)
        .once('value', (setting) => {
          resolve(setting.val());
        }).catch((error) => {
          reject(error);
        });
      */
      this.db
        .collection('public')
        .doc('settings')
        .get()
        .then((settings: any) => {
          if (!settings.exists) {
            resolve({});
          } else {
            resolve(settings.data()[settingName]);
          }
        })
        .catch((err: any) => reject(err));
    });
  };

}

export default Settings;
