let isNull = (val1, val2) => {
  if (val1)
    return val1;
  else
    return val2;
};

let isNullOrEmpty = (val1, val2) => {
  if (val1 || (val1 && typeof(val1) === 'string' && val1.length > 0))
    return val1;
  else
    return val2;
};

let isNullThenElse = (val1, val2, val3) => {
  if (val1)
    return val3;
  else
    return val2;
};

export {isNull, isNullOrEmpty, isNullThenElse};