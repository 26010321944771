import React, { useContext, useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonText, IonCol, IonRow, IonGrid, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonLoading, IonToast, IonButtons, IonInput, IonDatetime, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';

import Header from '../../components/Header';
import User from '../../entity/User';
import Ledger from '../../entity/Ledger';
import Settings from '../../entity/Settings';

import { phonePortraitOutline, cardOutline, trashOutline, calendarOutline, bodyOutline, eyeOutline } from 'ionicons/icons';

import './WithdrawalPayPal.css';

const moment = require('moment');

const WithdrawalPayPal: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [patientDoctor, setPatientDoctor] = useState<string>('patient');
  
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('');
  const [balance, setBalance] = useState<any>();
  const [exchangeRate, setExchangeRate] = useState<any>();
  
  const [paypalAddress, setPaypalAddress] = useState<string>('');

  const [showInvalidAddress, setShowInvalidAddress] = useState<boolean>(false);
  const [showWithdrawalReceived, setShowWithdrawalReceived] = useState<boolean>(false);
  const [showProblemEncountered, setShowProblemEncountered] = useState<boolean>(false);
  
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  const ledgerObj = new Ledger();
  const settingsObj = new Settings();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setPatientDoctor(state.isDoctor ? 'doctor' : 'patient');

    userObj.getCurrentProfile().then((profile: any) => {
      if (
        profile &&
        profile.paypalAddress &&
        profile.paypalAddress.length > 0
      ) {
        setPaypalAddress(profile.paypalAddress);
      }
    });

    // Retrieve current user balance
    ledgerObj.getLastLedgerEntry().then((entry: any) => {
      setBalance(entry.balance);
    });

    ledgerObj.getExchangeRate('GGP', 'USD').then((pair: any) => {
      setExchangeRate(pair.rate);
    });
      
  },[]);
  
  const withdrawFunds = () => {
    if (!isBusy) {
      if (
        paypalAddress &&
        paypalAddress.length > 0 &&
        userObj.validateEmail(paypalAddress)
      ) {
        if (Number(withdrawalAmount) && Number(withdrawalAmount) > 0) {
          setIsBusy(true);
          
          userObj
          .setCurrentProfileField('paypalAddress', paypalAddress)
          .then(() => {
            ledgerObj
              .withdrawViaPayPal(Number(withdrawalAmount))
              .then((result: any) => {
                setIsBusy(false);

                if (result.status === 'OK') {
                  setShowWithdrawalReceived(true);
                  
                } else {
                  setShowProblemEncountered(true);
                  
                }
              })
              .catch((err) => {
                setIsBusy(false);
                setShowProblemEncountered(true);
              });
          })
          .catch((err: any) => {
            setIsBusy(false);
            setShowProblemEncountered(true);
          });
        }
      } else {
        setShowInvalidAddress(true);
      }
    }
  };

  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLoading
                isOpen={isBusy}
                message={(i18n as any).t('common.please_wait')}
              />
              <IonToast
                isOpen={toastMessage.length > 0}
                onDidDismiss={() => setToastMessage('')}
                message={toastMessage}
                position="top"
                color="tertiary"
                duration={1300}
                translucent
                keyboardClose
              />   
              
              <div style={{textAlign: 'center', marginBottom: 20}}>
                <h1>{(i18n as any).t('account_withdrawal_paypal.title_account_withdrawal')}</h1>
              </div>
                
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    {(i18n as any).t('account_withdrawal_paypal.how_it_works')}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    {(i18n as any).t(
                      'account_withdrawal_paypal.how_it_works_para1'
                    )}
                  </IonText>
                  <IonText>
                    {(i18n as any).t(
                      'account_withdrawal_paypal.how_it_works_para2'
                    )}
                  </IonText>
                  <IonText color="danger">
                    <h2 style={{marginTop: 20}}>
                      {(i18n as any).t(
                        'account_withdrawal_paypal.how_it_works_para3'
                      )}
                    </h2>
                  </IonText>
                </IonCardContent>
              </IonCard>
              
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    {(i18n as any).t('account_withdrawal_paypal.title_my_paypal_address')}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel position="floating">{(i18n as any).t('account_withdrawal_paypal.title_paypal_address')}</IonLabel>
                    <IonInput
                      value={paypalAddress}
                      type="email"
                      placeholder={(i18n as any).t('profile.please_supply')}
                      autofocus
                      clearInput
                      readonly={isBusy}
                      onIonChange={e => {
                        setPaypalAddress(e.detail.value!);
                      }} />
                  </IonItem>
                </IonCardContent>
              </IonCard>

              { paypalAddress &&
                paypalAddress.length > 0 &&
                userObj.validateEmail(paypalAddress) && (
                
                <>
                  {balance && (
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {(i18n as any).t('account_withdrawal_paypal.current_balance')}
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonText color="primary">
                          <h1>{ledgerObj.formatNumber(balance.toFixed(2))}</h1>
                        </IonText>
                      </IonCardContent>
                    </IonCard>
                  )}
                  
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        {(i18n as any).t('account_withdrawal_paypal.title_credits_to_withdraw')}
                      </IonCardTitle>
                      {exchangeRate && (
                        <IonCardSubtitle>
                          {(i18n as any).t('account_withdrawal_paypal.exchange_rate', {
                            exchangeRate
                          })}
                        </IonCardSubtitle>
                      )}
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem>
                        <IonInput
                          value={withdrawalAmount}
                          placeholder={(i18n as any).t('profile.please_supply')}
                          autofocus
                          clearInput
                          onIonChange={e => {
                            setWithdrawalAmount(e.detail.value!);
                          }} />
                      </IonItem>
                      <IonButton onClick={withdrawFunds} size="large" expand="block" style={{marginTop: 20}}>
                        {(i18n as any).t('account_withdrawal_paypal.button_withdraw_funds')}
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      
      <IonAlert
        isOpen={showInvalidAddress}
        onDidDismiss={() => {
          setShowInvalidAddress(false);
        }}
        header={(i18n as any).t('account_withdrawal_paypal.title_invalid_address')}
        message={(i18n as any).t('account_withdrawal_paypal.title_invalid_address_body')}
        buttons={[{
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowInvalidAddress(false);
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showWithdrawalReceived}
        onDidDismiss={() => {
          setShowWithdrawalReceived(false);
          history.push(`/${patientDoctor}/account`);
        }}
        header={(i18n as any).t('common.success')}
        message={(i18n as any).t('account_withdrawal_paypal.withdrawal_received_successfully')}
        buttons={[{
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowWithdrawalReceived(false);
              history.push(`/${patientDoctor}/account`);
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={showProblemEncountered}
        onDidDismiss={() => {
          setShowProblemEncountered(false);
          history.push(`/${patientDoctor}/account`);
        }}
        header={(i18n as any).t('common.a_problem_occurred')}
        message={(i18n as any).t('common.a_problem_occurred_body')}
        buttons={[{
            text: (i18n as any).t('common.OK'),
            handler: () => {
              setShowProblemEncountered(false);
              history.push(`/${patientDoctor}/account`);
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default WithdrawalPayPal;
