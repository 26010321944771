import React, { useContext, useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonInput, IonToast, IonDatetime, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonAvatar, IonLoading, IonText, IonBadge, IonSkeletonText, IonTextarea, IonToggle, IonAlert } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';
import { isNull, isNullThenElse, isNullOrEmpty } from '../../Utils';

import Header from '../../components/Header';
import User from '../../entity/User';
import Doctor from '../../entity/Doctor';
import ListData from '../../entity/ListData';
import Ledger from '../../entity/Ledger';
import Settings from '../../entity/Settings';
import ConsultationRequest from '../../entity/ConsultationRequest';

import { fingerPrintOutline, happyOutline, calendarOutline, maleOutline, femaleOutline, globeOutline, languageOutline, walletOutline, checkmarkOutline, checkmarkCircle, medkitOutline, cashOutline, speedometerOutline } from 'ionicons/icons';

import './IssueDetail.css';

const moment = require('moment');

const IssueDetail: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  const [issue, setIssue] = useState<string>('');
  const [consultationTerms, setConsultationTerms] = useState<string>('');
  const [agree, setAgree] = useState<boolean>(false);
  const [specialty, setSpecialty] = useState<string>('General Practitioner');
  const [specialities, setSpecialities] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [balance, setBalance] = useState<number>(0);
  const [availableDoctorCount, setAvailableDoctorCount] = useState<number>(-1);
  const [randomGPCount, setRandomGPCount] = useState<number>(Math.floor(Math.random() * 10) + 5);
  const [minChars, setMinChars] = useState<number>(50);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showNoDoctorsAlert, setShowNoDoctorsAlert] = useState<boolean>(false);
  const [alertMessageTitle, setAlertMessageTitle] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertMessageUrl, setAlertMessageUrl] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const listDataObj = new ListData();
  const userObj = new User();
  const doctorObj = new Doctor();
  const ledgerObj = new Ledger();
  const settingsObj = new Settings();
  const consultationRequestObj = new ConsultationRequest();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/

    // Retrieve available GPs
    onChangeSpecialty('General Practitioner'); // Matches default specialty

    //  Retrieve consultation terms
    settingsObj.getSetting('consultation_terms').then((terms: any) => {
      setConsultationTerms(terms);
    });

    ledgerObj.getLastLedgerEntry().then((entry: any) => {
      if (entry.balance) {
        setBalance(entry.balance);
      }
    });

    //  Populate specialities
    listDataObj.getSpecialities().then((data: any) => {
      const _specialities: any = [];
      const _specialityOptions: any = [];

      data.docs.forEach((spec: any) => {
        const speciality = spec.data();

        _specialities.push({key: spec.id, ...speciality});
        _specialityOptions.push({key: spec.id, label: speciality.name});
      });
      setSpecialities(_specialities);
      setSpecialityOptions(_specialityOptions);
    });

  },[]);
  
  const getSpecialty = (key: string) => {
    let specialty = {fee: 0};
    
    specialities.forEach((spec: any) => {
      if (spec.key === key) {
        specialty = spec;
      }
    });

    return specialty;
  };
  
  const requestConsultation = () => {

    // Check available doctors
    if (availableDoctorCount === 0) {
      setShowNoDoctorsAlert(true);
    } else {
      continueRequestConsultation();
    }
  };
  
  const continueRequestConsultation = () => {

    if (!isBusy) {
      if (balance >= getSpecialty(specialty).fee) {
        if (issue.trim().length >= minChars) {
          if (agree) {
            setIsBusy(true);
            const consultationFee = getSpecialty(specialty).fee;

            consultationRequestObj
              .createRequest(issue, specialty, consultationFee)
              .then((requestId) => {
                setIsBusy(false);
                history.push('/patient/home');
              });
          } else {
            setAlertMessageTitle((i18n as any).t('issue_detail.please_agree'));
            setAlertMessage((i18n as any).t('issue_detail.please_agree_body'));
          }
        } else {
          setAlertMessageTitle((i18n as any).t('issue_detail.description_required'));
          setAlertMessage((i18n as any).t('issue_detail.description_required_body', {minChars}));          
        }
      } else {
        setAlertMessageTitle((i18n as any).t('issue_detail.insufficient_funds'));
        setAlertMessage((i18n as any).t('issue_detail.insufficient_funds_body'));
        setAlertMessageUrl('/patient/deposit');
      }
    }
  };
  
  const onChangeSpecialty = (specialty: string) => {
    setSpecialty(specialty);

    doctorObj.getAvailableDoctorsForSpecialty(specialty).then((count) => {
      if (specialty.toLowerCase() === 'general practitioner') {
        // If a GP is requested return a random number between 5 and 15
        // This is due to our GP channel not always being online
        count = randomGPCount;
      }
      setAvailableDoctorCount(count as number);
    });
  };
  
  const consultationFee = getSpecialty(specialty).fee;
  
  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">
                <IonToast
                  isOpen={errorMessage.length > 0}
                  onDidDismiss={() => setErrorMessage('')}
                  message={errorMessage}
                  position="top"
                  color="tertiary"
                  duration={1300}
                  translucent
                  keyboardClose
                />
                <IonLoading
                  isOpen={isBusy}
                  message={(i18n as any).t('common.please_wait')}
                />

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{(i18n as any).t('issue_detail.title_i_need_a_doctor')}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={medkitOutline} />
                        <IonLabel position="floating">{(i18n as any).t('issue_detail.specialty_type')}</IonLabel>
                        {specialities && specialities.length > 0 && (
                          <IonSelect
                            value={specialty}
                            okText={(i18n as any).t('common.OK')}
                            cancelText={(i18n as any).t('common.cancel')}
                            placeholder={(i18n as any).t('issue_detail.specialty_type')}
                            onIonChange={e => onChangeSpecialty(e.detail.value)}>
                            
                            {specialities.map((item:any) => (
                              <IonSelectOption key={item.key} value={item.key}>{item.name}</IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                        
                        {availableDoctorCount > 0 && (
                          <IonText color="success">
                            {(i18n as any).t('issue_detail.available_doctors', {
                              availableDoctorCount
                            })}
                          </IonText>
                        )}
                        {availableDoctorCount === 0 && (
                          <IonText color="warning">
                            {(i18n as any).t('issue_detail.available_doctors', {
                              availableDoctorCount
                            })}
                          </IonText>
                        )}
                      </IonItem>
                    
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={cashOutline} />
                        <IonText>
                          {availableDoctorCount === -1 && (      
                            <IonSkeletonText animated style={{ width: '20%' }} />
                          )}
                          {availableDoctorCount !== -1 && (
                            <div>
                              {`${(i18n as any).t('issue_detail.fee')}: ${ledgerObj.formatNumber(
                                consultationFee
                              )} ${ledgerObj.formatCreditText(consultationFee)}`}
                            </div>
                          )}
                        </IonText>
                      </IonItem>
                        
                      <IonItem>
                        <IonIcon slot="start" size="large" icon={walletOutline} />
                        {availableDoctorCount === -1 && (      
                          <IonSkeletonText animated style={{ width: '20%' }} />
                        )}
                        {availableDoctorCount !== -1 && (
                          <IonText>
                            {`${(i18n as any).t(
                              'issue_detail.balance'
                            )}: ${ledgerObj.formatNumber(
                              balance
                            )} ${ledgerObj.formatCreditText(balance)}`}
                          </IonText>
                        )}
                      </IonItem>
                      
                      {balance < consultationFee && (
                        <IonItem>
                          <IonIcon slot="start" size="large" icon={speedometerOutline} />
                          <IonText color="warning">
                            <p>{(i18n as any).t('issue_detail.title_insufficient_balance')}</p>
                            <a href="#" onClick={(e) => {
                              e.preventDefault();
                              history.push('/patient/deposit');
                            }}>{(i18n as any).t('issue_detail.title_add_funds')}</a>
                          </IonText>
                        </IonItem>  
                      )}

                    </IonList>
                  </IonCardContent>
                </IonCard>
                
                <IonCard>
                  <IonCardHeader>
                    <IonText><h4>{(i18n as any).t('issue_detail.enter_problem_description')}</h4></IonText>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel position="floating">
                        {(i18n as any).t('issue_detail.min_length', {
                          count: issue.length,
                          minChars
                        })}
                      </IonLabel>
                      <IonTextarea
                        value={issue}
                        placeholder={(i18n as any).t('profile.please_supply')}
                        onIonChange={e => setIssue(e.detail.value!)}>
                      </IonTextarea>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
                
                <IonCard>
                  <IonCardContent>
                    <IonItem>
                      <IonText>
                        {`${(i18n as any).t(
                          'issue_detail.the_fee_is'
                        )}: ${ledgerObj.formatNumber(
                          consultationFee
                        )} ${ledgerObj.formatCreditText(consultationFee)}.`}
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonText>
                        {consultationTerms}
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonToggle mode="ios" checked={agree} onIonChange={e => setAgree(e.detail.checked)} />
                      <IonText style={{marginLeft: 20, cursor: 'pointer'}} onClick={e => setAgree(!agree)}>{(i18n as any).t('issue_detail.i_agree')}</IonText>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
                
                <IonButton onClick={requestConsultation} size="large" expand="block">
                  {(i18n as any).t('issue_detail.button_continue')}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      
      <IonAlert
        isOpen={showNoDoctorsAlert}
        onDidDismiss={() => setShowNoDoctorsAlert(false)}
        header={(i18n as any).t('issue_detail.no_doctors_available')}
        message={(i18n as any).t('issue_detail.no_specialists_online__wait', {specialty})}
        buttons={[
          {
            text: (i18n as any).t('common.no'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // Do nothing
            }
          },
          {
            text: (i18n as any).t('common.yes'),
            handler: () => {
              continueRequestConsultation();
            }
          }
        ]}
      />
      
      <IonAlert
        isOpen={alertMessage.length > 0}
        onDidDismiss={() => {
          setAlertMessageTitle('');
          setAlertMessage('');
          setAlertMessageUrl('');
        }}
        header={alertMessageTitle}
        message={alertMessage}
        buttons={[
          {
            text: (i18n as any).t('common.OK'),
            handler: () => {
              if (alertMessageUrl.length > 0) {
                history.push(alertMessageUrl);
              }
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default IssueDetail;
