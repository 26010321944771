import React, { useContext, useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonInput, IonToast, IonDatetime, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonAvatar, IonLoading, IonText, IonBadge, IonSkeletonText, IonTextarea, IonToggle, IonAlert, IonImg } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../State';
import { useHistory } from "react-router-dom";
import i18n from '../i18n';
import { isNull, isNullThenElse, isNullOrEmpty } from '../Utils';

import Header from '../components/Header';

import { fingerPrintOutline, happyOutline, calendarOutline, maleOutline, femaleOutline, globeOutline, languageOutline, walletOutline, checkmarkOutline, checkmarkCircle, medkitOutline, cashOutline, speedometerOutline } from 'ionicons/icons';

import './FirstStep.css';

const FirstStep: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/

  },[]);
  
  const nextStep = () => {
    history.push('/patient/profile');
  };
  
  const imgWelcome = require('../assets/welcome.png');
  
  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{(i18n as any).t('first_step.title_lets_set_you_up')}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>

                <IonCard>
                  <IonCardContent>
                    <IonItem onClick={nextStep}>
                      <IonImg src={imgWelcome} style={{width:'60%', height: 'auto', margin: 'auto'}} />
                    </IonItem>
                    <IonItem>
                      <IonText>{(i18n as any).t('first_step.lets_set_you_up_para1')}</IonText>
                    </IonItem>
                    <IonItem>
                      <IonText>{(i18n as any).t('first_step.lets_set_you_up_para2')}</IonText>
                    </IonItem>
                    <IonItem>
                      <IonText>{(i18n as any).t('first_step.lets_set_you_up_para3')}</IonText>
                    </IonItem>
                  </IonCardContent>
                </IonCard>

                <IonButton onClick={nextStep} size="large" expand="block">
                  {(i18n as any).t('first_step.button_go_to_profile')}
                </IonButton>

              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default FirstStep;
