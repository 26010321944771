import * as firebase from 'firebase';

class ListData {
  private db: any;
  private funcs: any;

  constructor() {
    this.db = (window as any).db;
    this.funcs = (window as any).funcs;
  }

  getListData = (collectionName: string) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection(collectionName)
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

  getCountries = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection('countries')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

  getLanguages = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection('languages')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

  getSpecialities = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection('specialities')
        .orderBy('sortIndex')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

  getDrugs = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection('drugs')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

  getTests = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection('tests')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

  getMeasurements = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('public')
        .doc('list_data')
        .collection('measurements')
        .get()
        .then((snapshot: any) => {
          resolve(snapshot);
        })
        .catch((err: any) => reject(err));
    });
  };

}

export default ListData;
