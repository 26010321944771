import { IonContent, IonPage, IonRouterLink, IonGrid, IonRow, IonCol, IonButton, IonText, IonHeader, IonImg, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonIcon, IonLabel, IonInput, IonToast, IonLoading, IonFooter } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import * as firebase from 'firebase';
import i18n from '../i18n';
import User from '../entity/User';
import { happyOutline, documentTextOutline } from 'ionicons/icons';

import Header from '../components/Header';
import './Login.css';

const Login: React.FC = (routerProps) => {
  const history = useHistory();
  
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  
  const userObj = new User();
  
  useEffect(() => {
    // Check if we have a stored email address
    const storedEmail = (window as any).localStorage.getItem("auth_email");
    if (storedEmail && storedEmail.length > 0) {
      setEmail(storedEmail);
    }
  }, []);
  
  const handleOAuth = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
        // This gives you a Google Access Token.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        //var user = result.user;
        
        if (result.user && result.user.uid && result.user.uid.length > 0) {
          // **** No need to do anything, FirebaseState component will handle it ****
          //history.push('/');
        }
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      
      console.log(error);
    });
  }
  
  const handleFBOAuth = () => {
    var provider = new firebase.auth.FacebookAuthProvider();

    firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
        // This gives you a Facebook Access Token.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        //var user = result.user;
        
        if (result.user && result.user.uid && result.user.uid.length > 0) {
          // **** No need to do anything, FirebaseState component will handle it ****
          //history.push('/');
        }
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      
      console.log(error);
    });
  }
  
  const handleLogin = () => {

    if (email.length > 0 && userObj.validateEmail(email)) {
      if (password && password.length > 0) {

        setIsBusy(true);

        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            setIsBusy(false);

            // Store email address for next time
            (window as any).localStorage.setItem("auth_email", email);
            
            // Reset password
            setPassword('');
            
          })
          .catch((error: any) => {
            setIsBusy(false);
            setToastMessage(error.message);
          });
      } else {
        setToastMessage(i18n.t('login.error_password'));
      }
    } else {
      setToastMessage(i18n.t('login.error_email'));
    }
  }
  
  const handleForgotPassword = () => {

    if (email && email.length > 0 && userObj.validateEmail(email)) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setToastMessage(i18n.t('login.alert_thanks'));
        })
        .catch((err) => console.warn(err));
    } else {
      setToastMessage(i18n.t('login.alert_forgot'));
    }
  }
   
  return (
    <IonPage>
      <Header showBack={false} />
      <IonHeader style={{backgroundColor: '#015cfb'}}>
        <IonGrid>
          <IonRow>
            <IonCol style={{textAlign: 'center'}}>
              <IonText style={{color: '#ffffff'}}>
                {(i18n as any).t('common.healthcare_for_the_masses')}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={toastMessage.length > 0}
          onDidDismiss={() => setToastMessage('')}
          message={toastMessage}
          position="middle"
          color="tertiary"
          duration={3000}
          translucent
          keyboardClose
        />
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        
        <IonGrid>
          <IonRow>
            <IonCol className="login-panel">
              <IonImg
                src={require('../assets/promo.png')}
                className="login-promo"
                onClick={(e) => {
                  e.preventDefault();
                  handleOAuth();
              }}/>
              
              {!showLogin && (
                <>
                  <IonText>
                    <h1 style={{marginBottom: 20}}>
                      {(i18n as any).t('login.simply')}
                    </h1>
                  </IonText>
                  <img
                    src={require('../assets/google-signin.png')}
                    style={{width: 'auto', height: 60, cursor: 'pointer'}}
                    alt={(i18n as any).t('login.button_signin_google')}
                    onClick={(e) => {
                        e.preventDefault();
                        handleOAuth();
                    }}
                  />
    
                  <br/>
                  <img
                    src={require('../assets/facebook-signin.png')}
                    style={{width: 'auto', height: 60, marginTop: 15, cursor: 'pointer'}}
                    alt={(i18n as any).t('login.button_signin_facebook')}
                    onClick={(e) => {
                        e.preventDefault();
                        handleFBOAuth();
                    }}
                  />
                  
                  <div style={{maxWidth: 200, marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>
                    <a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
                      e.preventDefault();
                      setShowLogin(true);
                    }}>{(i18n as any).t('login.have_an_existing_user_name')}</a>
                  </div>
                </>
              )}
              {showLogin && (
                <div style={{maxWidth: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>{(i18n as any).t('login.please_login')}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonList>
                        <IonItem>
                          <IonLabel position="floating">{(i18n as any).t('login.placeholder_email')}</IonLabel>
                          <IonInput
                            value={email}
                            placeholder={(i18n as any).t('profile.please_supply')}
                            clearInput
                            autofocus
                            onIonChange={e => setEmail(e.detail.value!)}>
                          </IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">{(i18n as any).t('login.placeholder_password')}</IonLabel>
                          <IonInput
                            type="password"
                            value={password}
                            placeholder={(i18n as any).t('profile.please_supply')}
                            clearInput
                            onIonChange={e => setPassword(e.detail.value!)}>
                          </IonInput>
                        </IonItem>
                      </IonList>
                      <IonButton onClick={handleLogin} size="large" expand="full" style={{marginTop: 20}}>
                        {(i18n as any).t('login.button_login')}
                      </IonButton>
                      
                      <div style={{maxWidth: 200, marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>
                        <a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
                          e.preventDefault();
                          handleForgotPassword();
                        }}>{(i18n as any).t('login.link_i_forgot_my_password')}</a>
                      </div>
                      <div style={{maxWidth: 200, marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>
                        <a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
                          e.preventDefault();
                          setShowLogin(false);
                        }}>{(i18n as any).t('common.back')}</a>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              )}
              <div style={{height: 30}} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
                <Link
                  style={{textDecoration: 'none', color: '#888888'}}
                  to={{
                  pathname: '/terms',
                  state: {
                    pageTitle: (i18n as any).t('terms.title_terms_and_conditions'),
                    assetName: (i18n as any).t('terms.terms_cms_asset_name')
                  }
                }}>
                  {(i18n as any).t('doctor_main_phone.terms_and_conditions')}
                </Link>
              </IonCol>
              <IonCol style={{textAlign: 'right'}}>
                <Link
                  style={{textDecoration: 'none', color: '#888888'}}
                  to={{
                  pathname: '/privacy',
                  state: {
                    pageTitle: (i18n as any).t('terms.title_privacy_policy'),
                    assetName: (i18n as any).t('terms.privacy_cms_asset_name')
                  }
                }}>
                  {(i18n as any).t('doctor_main_phone.privacy_policy')}
                </Link>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonFooter>
    </IonPage>
  );
};

export default Login;
