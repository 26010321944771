import React, { useContext, useReducer, useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonCard, IonCardHeader, IonCardTitle, IonText, IonCardContent, IonItem, IonSegment, IonSegmentButton, IonIcon, IonLabel, IonLoading, IonCardSubtitle, IonAvatar, IonGrid, IonRow, IonCol, IonRippleEffect, IonBadge } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import {Link, Redirect} from 'react-router-dom';
import { AppContext } from '../../State';
import i18n from '../../i18n';

import User from '../../entity/User';
import Doctor from '../../entity/Doctor';
import ConsultationRequest from '../../entity/ConsultationRequest';
import Consultation from '../../entity/Consultation';
import { SESSION } from '../../entity/Consultation';

import Header from '../../components/Header';
import './Home.css';
import { fitnessOutline, fitness, documentTextOutline } from 'ionicons/icons';
import { useInterval } from '../../hooks/UseInterval';

const moment = require('moment');

const Home: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = reactRouterDom.useHistory();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0); // Used to force update/render
  
  const [requestUnsubscribe, setRequestUnsubscribe] = useState<any>();
  const [consultationUnsubscribe, setConsultationUnsubscribe] = useState<any>();
  const [consultationRequests, setConsultationRequests] = useState<any>([]);
  const [consultations, setConsultations] = useState<any>([]);
  const [otherSpecializationsWaiting, setOtherSpecializationsWating] = useState<any>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  useInterval(() => {
    // console.log('Updating last seen');
    doctorObj.setLastSeenDate();
  }, 2000 * 60);
  
  useInterval(() => {
    // Force screen refresh to show updated waiting times
    forceUpdate();
  }, 1000 * 10);
  
  const userObj = new User();
  const doctorObj = new Doctor();
  const consultationRequestObj = new ConsultationRequest();
  const consultationObj = new Consultation();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    
    //  Retrieve doctor profile (if it exists)
    if (state.doctorProfile) {
      /*
        Retrieve consultation requests
      */
      const requestRef = consultationRequestObj.ref
        .orderBy('requestDate', 'asc')
        .limit(200)
        .onSnapshot((snapshot: any) => {
          // Ensure the collection is not empty
          if (snapshot && snapshot.docChanges().length > 0) {
            snapshot.docChanges().forEach((change: any) => {
              const request = change.doc;
              const requestItem = parse(request);

              // Ensure request matches one of the doctors specialities
              if (
                state.doctorProfile.specialities &&
                state.doctorProfile.specialities.indexOf(requestItem.specialty) > -1
              ) {
                const requests = consultationRequests;
  
                if (change.type === 'added') {
                  /*
                    Request added
                  */
                  requests.push(requestItem);

                } else if (change.type === 'modified') {
                  /*
                    Request changed
                  */
                  requests.forEach((req: any, i: number) => {
                    if (req._id === requestItem._id) {
                      requests[i] = requestItem;
                    }
                  });
                } else if (change.type === 'removed') {
                  /*
                    Request removed
                  */
                  let foundIdx = -1;
                  requests.forEach((req: any, i: number) => {
                    if (req._id === requestItem._id) {
                      foundIdx = i;
                    }
                  });
  
                  if (foundIdx > -1) {
                    requests.splice(foundIdx, 1);
                  }
                }
  
                // We have to update the array this way
                // otherwise useState thinks that the arrays are the same
                // i.e. if setConsultationRequests(requests) is used
                setConsultationRequests([...requests]);
                
              } else {
                // Not one of this doctors specializations
                // Keep a list of other specializations waiting
                let other = otherSpecializationsWaiting;
                
                if (change.type === 'added' && other.indexOf(requestItem.specialty) === -1) {
                  other.push(requestItem.specialty);
                  
                } else if (change.type === 'removed') {
                  other = other.filter((item: string) => item !== requestItem.specialty);
                }
                
                other.sort();
                setOtherSpecializationsWating([...other]);
              }
            });
          }
        });
      
        setRequestUnsubscribe({ref: requestRef});

        /*
          Retrieve active consultations where
          this user is the doctor
        */
        const consultationRef = consultationObj.ref
          .where(
            'doctorAccessKey',
            '==',
            `active_${userObj.getCurrentUID}`
          )
          .limit(100)
          .orderBy('consultationDate', 'desc')
          .onSnapshot((snapshot: any) => {
            // Ensure the collection is not empty
            if (snapshot && snapshot.docChanges().length > 0) {
              snapshot.docChanges().forEach((change: any) => {
                const consultation = change.doc;
                const consultationItem = parseConsultation(consultation);
                
                let consults = consultations;
  
                if (change.type === 'added') {
                  /*
                    Request added
                  */
                  consults.push(consultationItem);
                } else if (change.type === 'modified') {
                  /*
                    Request changed
                  */
                  consults.forEach((con: any, i: number) => {
                    if (con._id === consultationItem._id) {
                      consults[i] = consultationItem;
                    }
                  });
                } else if (change.type === 'removed') {
                  /*
                    Request removed
                  */
                  let foundIdx = -1;
                  consults.forEach((con: any, i: number) => {
                    if (con._id === consultationItem._id) {
                      foundIdx = i;
                    }
                  });
  
                  if (foundIdx > -1) {
                    consults.splice(foundIdx, 1);
                  }
                }
  
                // We have to update the array this way
                // otherwise useState thinks that the arrays are the same
                // i.e. if setConsultationRequests(consults) is used
                setConsultations([...consults]);
              });
            }
          });

      setConsultationUnsubscribe({ref: consultationRef});

    } else {
      history.push('/patient/home');
    }            
  }, []);
  
  useEffect(() => {
       return () => {
      /*********************************************
      * ComponentDidUnMount
      *********************************************/
      // console.log("Unsubscribing from listeners");

      //@ts-ignore
      try {
        if (requestUnsubscribe && requestUnsubscribe.ref) {
          requestUnsubscribe.ref();
        }
        //@ts-ignore
        if (consultationUnsubscribe && consultationUnsubscribe.ref) {
          consultationUnsubscribe.ref();
        }
      } catch( ex ) {
        //Probably due to a log out event
      }
    };
  }, [
    requestUnsubscribe, 
    consultationUnsubscribe
  ]);
  
  const parse = (consultationRequest: any) => {
    const request = consultationRequest.data();
    const {id: _id} = consultationRequest;

    const requestDate = new Date(request.requestDate);

    const waitingFor = moment(request.requestDate).fromNow(true);

    const requestItem = {
      _id,
      ...request,
      waitingFor,
      createdAt: requestDate
    };

    return requestItem;
  };

  const parseConsultation = (c: any) => {
    const consultation = c.data();
    const {id: _id} = c;

    const consultationDate = new Date(consultation.consultationDate);

    const duration = moment(consultation.consultationDate).fromNow(true);

    const consultationItem = {
      _id,
      ...consultation,
      duration,
      createdAt: consultationDate
    };

    return consultationItem;
  };
  
  const updateDoctorAvailability = (selectedIndex: string) => {

    if (!isBusy && state.isDoctor) {
      const available = selectedIndex === '1';

      const profile = state.doctorProfile;
      profile.available = available;
      dispatch({type: 'setDoctorProfile', value: profile});
      
      setIsBusy(true);

      doctorObj
        .setAvailability(available)
        .then(() => {
          setIsBusy(false);
        })
        .catch((err) => {
          setIsBusy(false);
          console.warn(err);
        });
    }
  };

  const goToConsultation = (consultation: any) => {
    // Retrieve patient record
    userObj.getProfile(consultation.patientId).then((patient: any) => {
      if (patient) {
        const patientProfile = patient;
        patientProfile._id = consultation.patientId;

        // Add consultation session to global state
        const session: SESSION = {
          sessionid: consultation._id,
          consultation,
          doctorProfile: state.doctorProfile,
          patientProfile
        };

        history.push('/doctor/consultation/chat', {
          session
        });
      }
    });
  };
  
  const goToConsultationRequest = (consultationRequest: any) => {
    history.push('/doctor/request/detail', {
      consultationRequest
    });
  }
  
  const getSubtitle = (consultation: any) => {
    let subTitle: any;

    if (consultation) {
      if (consultation.lastMsg) {
        let {lastMsg} = consultation;
        if (lastMsg.length > 250) lastMsg = `${lastMsg.substring(0, 250)}...`;
        subTitle = (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText color="primary">
                  <h1>{consultation.patientName}</h1>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="medium">
                  {(i18n as any).t('doctor_main_phone.active_for', {
                    consultationDate: moment(
                      consultation.consultationDate.toDate()
                    ).fromNow(true)
                  })}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="primary">
                  {lastMsg}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRippleEffect></IonRippleEffect>
          </IonGrid>
        );
      } else {
        subTitle = (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText color="primary">
                  <h1>{consultation.patientName}</h1>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="medium">
                  {consultation.consultationDate && (i18n as any).t('doctor_main_phone.active_for', {
                    consultationDate: moment(
                      consultation.consultationDate.toDate()
                    ).fromNow(true)
                  })}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRippleEffect></IonRippleEffect>
          </IonGrid>
        );
      }
    }

    return subTitle;
  };

  return (
    <IonPage>
      <Header showBack={false} />
      <IonContent>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />

        <div className="container">
          <IonCard>
            <IonCardContent>
              <IonItem>
                <IonSegment
                  value={(state.doctorProfile && state.doctorProfile.available) ? '1' : '0'}
                  onIonChange={e => updateDoctorAvailability(e.detail.value as string)}>
                  
                  <IonSegmentButton value="0">
                    <IonIcon icon={fitnessOutline} />
                    <IonLabel>{(i18n as any).t('doctor_main_phone.not_available')}</IonLabel>
                  </IonSegmentButton>
                  
                  <IonSegmentButton value="1">
                    <IonIcon icon={fitness} />
                    <IonLabel>{(i18n as any).t('doctor_main_phone.available')}</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonItem>
            </IonCardContent>
          </IonCard>
          
          {consultations.length > 0 && (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    {(i18n as any).t('doctor_main_phone.my_active_consultations')}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCard>
            
              {consultations.map((item:any) => (
                <IonCard
                  key={item._id}
                  onClick={() => goToConsultation(item)}
                  style={{cursor: 'pointer'}}>
                  
                  <IonCardContent>
                    <IonItem text-wrap className="ion-activatable ripple-parent">
                      {item.patientAvatar && item.patientAvatar.length > 0 && (
                        <div className="doctor-home-avatar-panel">
                          <IonAvatar slot="start" className="doctor-home-avatar">
                            {item.msgCount && item.msgCount > 0 && (
                              <IonBadge style={{position: 'absolute'}}>{item.msgCount}</IonBadge>
                            )}
                            <img src={item.patientAvatar} />
                          </IonAvatar>
                        </div>
                      )}
                      {(!item.patientAvatar || item.patientAvatar.length === 0) && (
                        <div className="doctor-home-avatar-panel">
                          <IonAvatar slot="start" className="doctor-home-avatar">
                            <img src={require('../../assets/avatar_M.png')} />
                          </IonAvatar>
                        </div>
                      )}
                      {getSubtitle(item)}
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              ))}
            </>
          )}

          {consultations.length === 0 && consultationRequests.length === 0 && (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    {(i18n as any).t('doctor_main_phone.no_requests_found')}
                  </IonCardTitle>
                  <IonCardSubtitle>
                    {(i18n as any).t('doctor_main_phone.list_will_automatically_refresh')}
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
              
              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>
                    {(i18n as any).t('doctor_main_phone.your_specializations')}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText color="primary">
                    { state.doctorProfile.specialities && 
                      state.doctorProfile.specialities.map((specialty: string) => (
                        <h2 key={specialty}>
                          {specialty}
                        </h2>
                      ))
                    }
                  </IonText>
                </IonCardContent>
              </IonCard>
              
              {otherSpecializationsWaiting.length > 0 && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>
                      {(i18n as any).t('doctor_main_phone.other_specialities_waiting')}
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonText color="primary">
                      {otherSpecializationsWaiting.map((specialty: string) => (
                          <h2 key={specialty}>
                            {specialty}
                          </h2>
                        ))
                      }
                    </IonText>
                  </IonCardContent>
                </IonCard>
              )}              
            </>
          )}

          {consultationRequests.length > 0 && consultationRequests.map((item:any) => (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    {(i18n as any).t('doctor_main_phone.available_consultations')}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCard>
              
              <IonCard
                key={item._id}
                onClick={() => goToConsultationRequest(item)}
                style={{cursor: 'pointer'}}>
                
                <IonCardContent>
                  <IonItem text-wrap className="ion-activatable ripple-parent">
                    {item.patientAvatar && item.patientAvatar.length > 0 && (
                      <div className="doctor-home-avatar-panel">
                        <IonAvatar slot="start" className="doctor-home-avatar">
                          <img src={item.patientAvatar} />
                        </IonAvatar>
                      </div>
                    )}
                    {(!item.patientAvatar || item.patientAvatar.length === 0) && (
                      <div className="doctor-home-avatar-panel">
                        <IonAvatar slot="start" className="doctor-home-avatar">
                          <img src={require('../../assets/avatar_M.png')} />
                        </IonAvatar>
                      </div>
                    )}
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonText color="primary">
                            <h1>{item.patientName}</h1>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonText color="medium">
                            {(i18n as any).t(
                              'doctor_main_phone.doctor_requested'
                            )}
                            <br/>
                            <strong>{item.specialty}</strong>
                            <br/>
                            {(i18n as any).t('doctor_main_phone.waiting_for', {
                              waitTime: moment(
                                item.requestDate.toDate()
                              ).fromNow(true)
                            })}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRippleEffect></IonRippleEffect>
                    </IonGrid>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </>
          ))}
          
          <IonCard>
            <IonCardContent>
              <IonItem>
                <IonIcon slot="start" size="medium" icon={documentTextOutline} />
                <Link
                    to={{
                    pathname: '/doctor/code',
                    state: {
                      pageTitle: (i18n as any).t('code_of_conduct.title_code_of_conduct'),
                      assetName: (i18n as any).t('terms.code_of_conduct_cms_asset_name')
                    }
                  }}>
                {(i18n as any).t('doctor_main_phone.code_of_conduct')}
              </Link>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" size="medium" icon={documentTextOutline} />
                <Link
                    to={{
                    pathname: '/doctor/terms',
                    state: {
                      pageTitle: (i18n as any).t('terms.title_terms_and_conditions'),
                      assetName: (i18n as any).t('terms.terms_cms_asset_name')
                    }
                  }}>
                {(i18n as any).t('doctor_main_phone.terms_and_conditions')}
              </Link>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" size="medium" icon={documentTextOutline} />
                <Link
                    to={{
                    pathname: '/doctor/privacy',
                    state: {
                      pageTitle: (i18n as any).t('terms.title_privacy_policy'),
                      assetName: (i18n as any).t('terms.privacy_cms_asset_name')
                    }
                  }}>
                  {(i18n as any).t('doctor_main_phone.privacy_policy')}
                </Link>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
