import React, { useContext, useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonInput, IonToast, IonDatetime, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonAvatar, IonLoading, IonText, IonBadge, IonSkeletonText, IonTextarea, IonToggle, IonAlert, IonImg } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../State';
import { useHistory } from "react-router-dom";
import i18n from '../i18n';

import Header from '../components/Header';

import './CMSPage.css';
import CMS from '../entity/CMS';

const CMSPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = reactRouterDom.useLocation() as any;
  
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [assetName, setAssetName] = useState<string>('');
  const [assetBody, setAssetBody] = useState<string>('');

  const CMSObj = new CMS();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setPageTitle(location.state.pageTitle);
    setAssetName(location.state.assetName);

    if (location.state &&
        location.state.assetName &&
        location.state.assetName.length > 0) {
          
      setIsBusy(true);
      
      // Retrieve CMS Asset
      CMSObj
        .getCMSAsset(location.state.assetName)
        .then((asset: any) => {
          setIsBusy(false);
          
          if (asset && asset.body && asset.body.length > 0) {
            setAssetBody(asset.body);
          }
        })
        .catch((err) => {
          setIsBusy(false);
          console.log(err);
        });
    }
    
  },[location.state]);

  return (
    <IonPage className="cmsPage">
      <Header showBack={true} />
      <IonContent>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{pageTitle}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>

                <IonCard>
                  <IonCardContent>
                    <IonItem>
                      <IonText>

                        {assetBody.split('\n').map((item: any, i: number) => {
                          return (
                            <div key={i}>{item}</div>
                          )
                        })}

                      </IonText>
                    </IonItem>
                  </IonCardContent>
                </IonCard>

              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CMSPage;
