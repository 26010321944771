import React, { useContext, useState, useEffect } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonText, IonCol, IonRow, IonGrid, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonLoading } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../../State';
import { useHistory } from "react-router-dom";
import i18n from '../../i18n';

import Header from '../../components/Header';
import User from '../../entity/User';

import { phonePortraitOutline, cardOutline, arrowRedoOutline } from 'ionicons/icons';

import './Withdrawal.css';

const moment = require('moment');

const Withdrawal: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  
  const [MSISDN, setMSISDN] = useState<string>('');
  const [carrierName, setCarrierName] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [patientDoctor, setPatientDoctor] = useState<string>('patient');
  
  const userObj = new User();
  
  useEffect(() => {
    /*********************************************
    * ComponentDidMount
    *********************************************/
    setPatientDoctor(state.isDoctor ? 'doctor' : 'patient');

    //  Retrieve ledger items
    setIsBusy(true);

    userObj
      .getCurrentProfile()
      .then((profile: any) => {
        if (profile && profile.MSISDN && profile.MSISDN.length > 0) {
          setMSISDN(profile.MSISDN);
          setCarrierName(profile.carrierName);
        }
        setIsBusy(false);
      });
      
  },[]);
  
  const gotoProfile = () => {
    history.push(`/${patientDoctor}/profile`);
  }

  return (
    <IonPage>
      <Header showBack={true} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="container">
                <div style={{textAlign: 'center', marginBottom: 20}}>
                  <h1>{(i18n as any).t('account_withdrawal.title')}</h1>
                </div>
                <IonLoading
                  isOpen={isBusy}
                  message={(i18n as any).t('common.please_wait')}
                />
                
                {(!MSISDN || MSISDN.length === 0) && !isBusy && (
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        {(i18n as any).t(
                          'account_withdrawal.title_do_you_have_mobile_money'
                        )}
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonText>
                        {(i18n as any).t(
                          'account_withdrawal.body_do_you_have_mobile_money'
                        )}
                      </IonText>
                      <div style={{marginTop: 15}}>
                        <IonButton onClick={() => history.push(`/${patientDoctor}/profile`)} size="large" expand="block">
                          {(i18n as any).t('account_withdrawal.button_update_profile')}
                        </IonButton>
                      </div>
                    </IonCardContent>
                  </IonCard>
                )}
                
                {!isBusy && (
                  <>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardSubtitle>
                          {(i18n as any).t(
                            'account_withdrawal.title_withdrawal_method'
                          )}
                        </IonCardSubtitle>
                      </IonCardHeader>
                    </IonCard>
                    
                    {MSISDN !== null && (MSISDN.startsWith('+256') || MSISDN.startsWith('256')) && (
                      <IonCard>
                        <IonCardContent>
                          <IonItem button detail onClick={() => {
                            history.push(`/${patientDoctor}/withdrawal/uganda`, {
                              MSISDN,
                              carrierName
                            });
                          }}>
                            <IonIcon color="primary" slot="start" icon={phonePortraitOutline} />
                            <IonText color="primary">
                              {(i18n as any).t(
                                'account_withdrawal.option_mobile_money_uganda'
                              )}
                            </IonText>
                          </IonItem>
                        </IonCardContent>
                      </IonCard>
                    )}
                    
                    {false && MSISDN !== null && (MSISDN.startsWith('+242') || MSISDN.startsWith('242')) && (
                      <IonCard>
                        <IonCardContent>
                          <IonItem button detail onClick={() => {
                            history.push(`/${patientDoctor}/withdrawal/congo`, {
                              MSISDN,
                              carrierName
                            });
                          }}>
                            <IonIcon color="primary" slot="start" icon={phonePortraitOutline} />
                            <IonText color="primary">
                              {(i18n as any).t(
                                'account_withdrawal.option_mobile_money_congo'
                              )}
                            </IonText>
                          </IonItem>
                        </IonCardContent>
                      </IonCard>
                    )}
                    
                    {MSISDN !== null && (MSISDN.startsWith('+255') || MSISDN.startsWith('255')) && (
                      <IonCard>
                        <IonCardContent>
                          <IonItem button detail onClick={() => {
                            history.push(`/${patientDoctor}/withdrawal/tanzania`, {
                              MSISDN,
                              carrierName
                            });
                          }}>
                            <IonIcon color="primary" slot="start" icon={phonePortraitOutline} />
                            <IonText color="primary">
                              {(i18n as any).t(
                                'account_withdrawal.option_mobile_money_tanzania'
                              )}
                            </IonText>
                          </IonItem>
                        </IonCardContent>
                      </IonCard>
                    )}
                    
                    <IonCard>
                      <IonCardContent>
                        <IonItem button detail onClick={() => history.push(`/${patientDoctor}/withdrawal/paypal`)}>
                          <IonIcon color="primary" slot="start" icon={arrowRedoOutline} />
                          <IonText color="primary">
                            {(i18n as any).t(
                              'account_withdrawal.option_paypal'
                            )}
                          </IonText>
                        </IonItem>
                      </IonCardContent>
                    </IonCard>
                  </>
                )}        
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Withdrawal;
