import React, { useContext, useEffect, useState, useReducer } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonCard, IonItem, IonIcon, IonLabel, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText, IonLoading, IonGrid, IonRow, IonCol } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { useHistory, Redirect, Link } from "react-router-dom";
import { pin, medkitOutline, alarmOutline, documentTextOutline, gitNetworkOutline, colorFillOutline, schoolOutline } from 'ionicons/icons';
import { AppContext } from '../../State';
import i18n from '../../i18n';

import Header from '../../components/Header';
import Ledger from '../../entity/Ledger';
import User from '../../entity/User';
import Doctor from '../../entity/Doctor';
import Settings from '../../entity/Settings';
import ConsultationRequest from '../../entity/ConsultationRequest';
import Consultation, { SESSION } from '../../entity/Consultation';

import './Home.css';

const moment = require('moment');

const Home: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0); // Used to force update/render
  
  const ledgerObj = new Ledger();
  const userObj = new User();
  const doctorObj = new Doctor();
  const settingsObj = new Settings();
  const consultationRequestObj = new ConsultationRequest();
  const consultationObj = new Consultation();
  
  const [consultationFee, setConsultationFee] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [requestUnsubscribe, setRequestUnsubscribe] = useState<any>();
  const [consultationUnsubscribe, setConsultationUnsubscribe] = useState<any>();
  const [consultationRequest, setConsultationRequest] = useState<any>(null);
  const [waitingForConsultationUpdate, setWaitingForConsultationUpdate] = useState<any>();
  const [consultation, setConsultation] = useState<any>(null);
  const [renderTimer, setRenderTimer] = useState<any>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
    
  useEffect(() => {
    //  Retrieve consultation requests

    const requestRef = consultationRequestObj.ref
      .where('patientId', '==', userObj.getCurrentUID)
      .limit(1)
      .onSnapshot((snapshot: any) => {
        if (snapshot && snapshot.docChanges().length > 0) {
          const change = snapshot.docChanges()[0];
          const request = change.doc;
          const requestItem = parseRequest(request);

          if (change.type === 'removed') {
            setConsultationRequest(null);
            setWaitingForConsultationUpdate(false);
          } else if (requestItem.requestDate) {
            setConsultationRequest(requestItem);
            setWaitingForConsultationUpdate(false);  
          }
        } else {
          setWaitingForConsultationUpdate(false);
        }
      });

    //  Retrieve active consultation where this user is the patient
    const consultationRef = consultationObj.ref
      .where(
        'patientAccessKey',
        '==',
        `active_${userObj.getCurrentUID}`
      )
      .limit(1)
      .onSnapshot((snapshot) => {
        if (snapshot && snapshot.docs.length > 0) {
          //  Automatically redirect to consultation
          const c = snapshot.docs[0];
          const consult = parseConsultation(c);
          setConsultation(consult);

          goToConsultation(consult);
        }
      });

    // Store listener subscription
    // NOTE: Setter side effect
    // If we store the unsubscribe function directly
    // it will be invoked by the setter and will automatically
    // unsubscribe and onSnapshot will not be called!!!
    setRequestUnsubscribe({ref: requestRef});
    setConsultationUnsubscribe({ref: consultationRef});
    
    
    //  Retrieve consultation fee
    settingsObj.getSetting('consultation_fee').then((fee: any) => {
      setConsultationFee(fee);
    });

    // Retrieve patient ledger balance
    ledgerObj.getLastLedgerEntry().then((entry: any) => {
      if (entry.balance) {
        setBalance(entry.balance);
      }
    });
    
    //  Force a render every 30 seconds
    const timer = setInterval(() => {
      forceUpdate();
    }, 30000);

    setRenderTimer(timer);

  },[]);
  
  useEffect(() => {
    /*********************************************
    * ComponentDidUnMount
    *********************************************/
    return () => {
      //console.log("Unsubscribing from listeners");
      
      //@ts-ignore
      if (requestUnsubscribe && requestUnsubscribe.ref) {
        requestUnsubscribe.ref();
      }
      //@ts-ignore
      if (consultationUnsubscribe && consultationUnsubscribe.ref) {
        consultationUnsubscribe.ref();
      }
      
      if (renderTimer) {
        const timer = renderTimer;
        clearInterval(timer);
      }
    }
  },[requestUnsubscribe, consultationUnsubscribe, renderTimer]);
  
  const parseRequest = (consultationRequest: any) => {
    const request = consultationRequest.data();
    const {id: _id} = consultationRequest;

    const requestDate = new Date(request.requestDate);

    const waitingFor = moment(request.requestDate).fromNow(true);

    const requestItem = {
      _id,
      ...request,
      waitingFor,
      createdAt: requestDate
    };

    return requestItem;
  };

  const parseConsultation = (c: any) => {
    const consultation = c.data();
    const {id: _id} = c;

    const consultationDate = new Date(consultation.consultationDate);

    const duration = moment(consultation.consultationDate).fromNow(true);

    const consultationItem = {
      _id,
      ...consultation,
      duration,
      createdAt: consultationDate
    };

    return consultationItem;
  };

  const goToAccountDeposit = () => {
    history.push('/patient/deposit');
  };

  const goToDoctorRegistration = () => {
    window.location.href='https://gogpplus.com/contact';
    //history.push('/patient/doctor/registration');
  };

  const goToConsultation = (consultation: any) => {
    // Retrieve patient record
    userObj.getProfile(consultation.patientId).then((patient: any) => {
      if (patient) {
        const patientProfile = patient;
        patientProfile._id = consultation.patientId;
            
        // Retrieve doctor record
        doctorObj.getProfile(consultation.doctorId).then((doctor: any) => {
          if (doctor) {
            const doctorProfile = doctor;
            doctorProfile._id = consultation.doctorId;
    
            // Add consultation session to global state
            const session: SESSION = {
              sessionid: consultation._id,
              consultation,
              doctorProfile,
              patientProfile
            };
    
            history.push('/patient/consultation/chat', {
              session
            });
          }
        });
      }
    });
  };  

  /*
  const requestDoctor = () => {

    userObj.getCurrentProfile().then((patient: any) => {
      const profile = patient;
      if (!profile || (!profile.nickName || profile.nickName.length === 0)) {
        history.push('/patient/profile');
      } else {
        alert('TODO');
        //navigation.navigate('ConsultationRequest');
      }
    });
  };
  */

  // Create Quick Queue Consultation Request
  const quickQueue = () => {

    if (!isBusy && !waitingForConsultationUpdate) {
      setIsBusy(true);

      // Check users profile is set up
      userObj.getCurrentProfile().then((patient: any) => {
        const profile = patient;
        if (!profile || (!profile.nickName || profile.nickName.length === 0)) {
          // Set up profile
          setIsBusy(false);
          history.push('/patient/firststep');
        } else {
          // Ensure user has sufficient funds in their account
          ledgerObj.getLastLedgerEntry().then((entry: any) => {
            if (entry.balance && entry.balance >= consultationFee) {
              // Generate consultation request
              consultationRequestObj
                .createRequest(
                  'COVID-19 Consultation (Quick Queue)',
                  'General Practitioner',
                  consultationFee
                )
                .then((requestId) => {
                  // We set the waitingForConsultationUpdate because
                  // we want to avoid patient from tapping "Quick Queue"
                  // and creating a duplicate consultation request
                  // if there is a lag updating the interface
                  setIsBusy(false);
                  setWaitingForConsultationUpdate(true);
                });
            } else {
              setIsBusy(false);
              goToAccountDeposit();
            }
          });
        }
      });
    }
  };
  
  const cardImage = require('../../assets/covid19.jpg');
  const imgAirtelMtn = require('../../assets/airtelmtn.jpg');
  const imgDoctorReg = require('../../assets/doctorreg.jpg');
  
  return (
    <IonPage>
      <Header showBack={false} />
      <IonContent>
        <IonLoading
          isOpen={isBusy}
          message={(i18n as any).t('common.please_wait')}
        />
        <div className="container">

          {consultationRequest !== null && (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>{`${(i18n as any).t('main.title_waiting')}...`}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  
                  <IonItem>
                    <IonIcon slot="start" size="large" icon={alarmOutline} style={{color: '#16E424'}} />
                    <IonText>
                      <h1>
                        {(i18n as any).t('main.body_waiting', {
                          waitTime: moment(
                            consultationRequest.requestDate.toDate()
                          ).fromNow(true)
                        })}
                      </h1>
                    </IonText>
                  </IonItem>
                  <IonItem>
                    {(i18n as any).t('main.body_para1')}
                  </IonItem>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>{`${(i18n as any).t('main.title_important')}...`}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonText color="warning">{(i18n as any).t('main.body_if_emergency')}</IonText>
                  </IonItem>

                  <Link
                    to={{
                    pathname: '/patient/consultation/request/edit',
                    state: {
                      request: consultationRequest
                    }
                  }}>
                    <IonButton
                      expand="block"
                      fill="solid"
                      size="large"
                      color="primary"
                      style={{marginTop: 15}}>    
                      {(i18n as any).t('main.button_update_details')}
                    </IonButton>
                  </Link>
                </IonCardContent>
              </IonCard>
            </>
          )}
          
          {consultation !== null && (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{(i18n as any).t('main.title_active_consultation')}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonItem>
                  <IonIcon slot="start" size="large" color="primary" icon={medkitOutline} />
                  <IonText>
                    <h1>
                     {consultation.consultationDate && (i18n as any).t('main.body_active', {
                        activeTime: moment(consultation.consultationDate.toDate()).fromNow(true)
                      })}
                      <br/>
                      {consultation.doctorCountry}
                    </h1>
                  </IonText>
                </IonItem>
                <IonButton
                  expand="block"
                  fill="solid"
                  size="large"
                  color="primary"
                  onClick={() => goToConsultation(consultation)}
                  style={{marginTop: 15}}
                >
                  {(i18n as any).t('common.continue')}
                </IonButton>
              </IonCardContent>
            </IonCard>
          )}
        
          {consultation === null && consultationRequest === null && (
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonCard>
                    <IonButton
                      expand="block"
                      fill="solid"
                      size="large"
                      color="primary"
                      onClick={() => history.push('/patient/consultation/request')}
                    >
                      {(i18n as any).t('main.button_i_need_a_doctor')}
                    </IonButton>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonCard className="patient-home-card">
                    <div className="header-img" style={{backgroundImage: "url(" + cardImage + ")"}} />
                    <IonCardHeader>
                      <IonCardTitle color="medium" style={{textAlign: 'center'}}>
                        {(i18n as any).t('main.title_covid19')}
                      </IonCardTitle>
                    </IonCardHeader>
                    
                    <IonCardContent>
                      <IonItem>
                        {(i18n as any).t('main.body_covid19', {
                          consultationFee: ledgerObj.formatNumber(consultationFee),
                          consultationFeeCredits: ledgerObj.formatCreditText(
                            consultationFee
                          ),
                          availBal: ledgerObj.formatNumber(balance),
                          availBalCredits: ledgerObj.formatCreditText(balance)
                        })}
                      </IonItem>
                      
                      <IonButton
                        expand="block"
                        fill="solid"
                        size="large"
                        color="primary"
                        onClick={quickQueue}
                        style={{marginTop: 15}}
                      >
                        <IonIcon src={gitNetworkOutline} style={{marginRight: 10}} />
                        <IonText>
                          {(i18n as any).t('main.button_quick_queue')}
                        </IonText>
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                  
                  <IonCard className="patient-home-card">
                    <div className="header-img" style={{backgroundImage: "url(" + imgAirtelMtn + ")"}} />
                    <IonCardHeader>
                      <IonCardTitle color="medium" style={{textAlign: 'center'}}>
                        {(i18n as any).t('main.title_need_to_top_up')}
                      </IonCardTitle>
                    </IonCardHeader>
                    
                    <IonCardContent>
                      <IonItem>
                        <IonText>
                          {(i18n as any).t('main.body_minimum_balance', {
                            consultationFee: ledgerObj.formatNumber(consultationFee),
                            creditText: ledgerObj.formatCreditText(consultationFee)
                          })}
                        </IonText>
                      </IonItem>
                      
                      <IonButton
                        expand="block"
                        fill="solid"
                        size="large"
                        color="primary"
                        onClick={goToAccountDeposit}
                        style={{marginTop: 15}}
                      >
                        <IonIcon src={colorFillOutline} style={{marginRight: 10}} />
                        <IonText>
                          {(i18n as any).t('main.button_top_up_now')}
                        </IonText>
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                
                <IonCol className="hidden-sm-down">
                  {true && (
                    <IonCard className="patient-home-card">
                      <div className="header-img" style={{backgroundImage: "url(" + imgDoctorReg + ")"}} />
                      <IonCardHeader>
                        <IonCardTitle color="medium" style={{textAlign: 'center'}}>
                          {(i18n as any).t('main.title_are_you_a_doctor')}
                        </IonCardTitle>
                      </IonCardHeader>
                      
                      <IonCardContent>
                        <IonItem>
                          <IonText>
                            {(i18n as any).t('main.body_are_you_a_doctor')}
                          </IonText>
                        </IonItem>
                        
                        <IonButton
                          expand="block"
                          fill="solid"
                          size="large"
                          color="primary"
                          onClick={goToDoctorRegistration}
                          style={{marginTop: 15}}
                        >
                          <IonIcon src={schoolOutline} style={{marginRight: 10}} />
                          <IonText>
                            {(i18n as any).t('main.button_register_now')}
                          </IonText>
                        </IonButton>
                      </IonCardContent>
                    </IonCard>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          
          <IonCard>
            <IonCardContent>
              <IonItem>
                <IonIcon slot="start" size="medium" icon={documentTextOutline} />
                <Link
                    to={{
                    pathname: '/patient/terms',
                    state: {
                      pageTitle: (i18n as any).t('terms.title_terms_and_conditions'),
                      assetName: (i18n as any).t('terms.terms_cms_asset_name')
                    }
                  }}>
                {(i18n as any).t('main.terms_and_conditions')}
              </Link>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" size="medium" icon={documentTextOutline} />
                <Link
                    to={{
                    pathname: '/patient/privacy',
                    state: {
                      pageTitle: (i18n as any).t('terms.title_privacy_policy'),
                      assetName: (i18n as any).t('terms.privacy_cms_asset_name')
                    }
                  }}>
                  {(i18n as any).t('main.privacy_policy')}
                </Link>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
