import React, { useContext } from 'react';
import { IonContent, IonPage, IonRouterLink, IonButton, IonSlides, IonSlide, IonImg, IonIcon } from '@ionic/react';
import * as reactRouterDom from 'react-router-dom';
import { AppContext } from '../State';
import { useHistory } from "react-router-dom";
import i18n from '../i18n';

import Header from '../components/Header';
import './Welcome.css';
import { informationCircleOutline } from 'ionicons/icons';

const Welcome: React.FC = (routerProps) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  return (
    <IonPage>
      <Header showBack={false} showLogin={true} />
      <IonContent>
        <IonSlides pager={true} options={{
          initialSlide: 0,
          speed: 400
        }}>
          <IonSlide>
            <img src={require('../assets/telemedicine2.png')} />
            <p>
              GoGP+ the <b>World&nbsp;Leader</b><br/>
              in virtual health.
            </p>
            <div style={{borderBottom: "solid 1px #313131", width: "50vw", marginLeft: 'auto', marginRight: 'auto'}}></div>
            <p>Video chat to a doctor now,<br/>
               virtually from your own home.</p>
            <p style={{marginTop: 40}}><a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}>Sign in now</a></p>
          </IonSlide>
          <IonSlide>
            <img
              src={require('../assets/promo.png')}
              style={{marginTop: 80}}
            />
            <p>
              GoGP+ is a <b>community service</b><br/>
            </p>
            <div style={{borderBottom: "solid 1px #313131", width: "50vw", marginLeft: 'auto', marginRight: 'auto'}}></div>
            <p>
              Designed to reach the masses,<br/>and your pocket.
            </p>
            <p style={{marginTop: 40}}><a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}>Sign in now</a></p>
          </IonSlide>
          <IonSlide>
            <img
              src={require('../assets/info.png')}
              style={{marginTop: 80}}
            />
            <p>
              Need more information?
            </p>
            <p style={{marginTop: 40}}><a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
              e.preventDefault();
              (window as any).open('https://gogpplus.com/faq');
            }}><b>Frequently Asked Questions</b></a></p>
            
            <p style={{marginTop: 40}}><a href="#" style={{textDecoration: 'none'}} onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}>Sign in now</a></p>
          </IonSlide>
        </IonSlides>
       
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
